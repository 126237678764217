import { Link } from "react-router-dom";
import Header from "./components/Header/Header";
import Button from "./components/Button/Button";
import AllIcons from "./Utility/IconLinks";
import './PageNotFound.css';

const PageNotFound = () => {
  return (
    <div className="PageNotFound">
      <Header />
      <div className="PageNotFound-Inner">
        <div className="Img404">
          <img
            src="https://cdn.upsure.io/POS/common/404_img.svg"
            alt="404"
            width="100%"
            height="100%"
          />
        </div>
        <p>The page you are looking does not exist</p>
        <div className="BtnSec">
          <Button
            label={"Go to Home"}
            status={true}
            isIcon={true}
            img={AllIcons.tickIcon}
            buttonType={"proceed-btn"}
            // onClick={handleSubmit}
          />
          {/* <Link to="/flow/dripcampaign" className="newblog">
            Back to Home
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;

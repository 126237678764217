import React from "react";
import "./Button.jsx";
import "./Button.scss";

const Button = ({ label, sublabel, status, onClick, clickFunction, disabled,isIcon=false, img, buttonType, freq }) => {
  return (
    <button
      className={!disabled ? `${buttonType} active` : `${buttonType} disabled`}
      // onClick={(e) => clickFunction(e)}
      disabled={disabled}
      onClick={(e) => onClick()}
    >
      {isIcon ? (
        <div className="btn-inc">
          <img src={img} width="100%" height="100%" alt="arrow" />
        </div>
      ) :null}
        <div className="btn-txt">{label} </div>
      {
        buttonType === "premium-btn" ? 
        <div className="btn-sub-txt">/ {freq}</div>:null
      }
    </button>
  );
};
export default Button;

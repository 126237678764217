import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import AllIcons from "../../Utility/IconLinks";
import PageLoader from "../../components/UI/PageLoader/PageLoader";
import { getPartnerByQuery } from "../../components/onboarding/serviceApi";


const TrainingChapters = () => {
  const { courseUrl } = useParams();
  const [ghostPages,setGhostPages] = useState([])
  const [graphqlUrl,]=useState("https://api.upsure.io/graphql-api/graphql?")

  const [singlePage,setSingePage] = useState([])
  
  const [chaptersData,setChaptersData] = useState("")
  const [pageLoader,setPageLoader] = useState(true)

  const [agentUuid,setAgentUuid]=useState(null)  // need to get it from session storage
  const [agentMobileNumber,setAgentMobileNumber]=useState(null)  // need to get it from session storage

  // timer code   

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0); 
  
  // 

  // course start and end time  

  const [coursestartTime,setCourseStatrtTime] = useState("")
  const [courseendTime,setCourseEndTime] = useState("")

  const [eachLessionLastTime,setEachLessionLastTime] = useState(0)

  const [counter,setCounter] = useState(0)

  const navigate = useHistory()

  const getAllGhostPages = ()=>{
    let configJson = {
      url: graphqlUrl,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: {
        query: `{
            post(filter: "tags:[training]+id:-0", limit: "all") {
              posts {
                feature_image
                slug
                html
                codeinjection_head
                url
                title
              }
            }
          }`,
      },
    };

    return axios(configJson);
  };

  const FormatDate = () => {
            const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');

        let hours = currentDate.getHours();
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        let ampm = 'am';

        if (hours > 12) {
          hours -= 12;
          ampm = 'pm';
        }

        const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
        // console.log(formattedDateTime);
        return formattedDateTime

  }

  // get data back  
  function secondsToHMS (totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds =  totalSeconds % 60;

     setSeconds(()=>seconds ? seconds : 0)
     setHours(() => hours ? hours : 0)
     setMinutes(() => minutes ? minutes : 0)
  } 


// we are checking all leassons should complete 3600sec 
const checkTotals = (payload)  => {
  // Iterate through each object in the payload  
  let total = 0
  let allcount = true
  if(payload && payload.length > 0) {
    for (let item of payload) {
      // Check if the total is > 3600 and counting total Time Count
      total+= +item.total
      if (+item.total < 3600) {
          allcount = false;
      }
  }
  }
  // If all totals are > 3600, return [true,total]
  return [allcount,total];
}

   //  getting cookie
   const getCookie = (name) => {
    var nameEQ = name + "=";
    var cookiesArray = document.cookie.split(";");
    for (var i = 0; i < cookiesArray.length; i++) {
      var cookie = cookiesArray[i];
      while (cookie.charAt(0) == " ") {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) == 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  };

   // get partner by id  
  
   const getPartnerDetails = (uuid) => {
    if (uuid) {
        getPartnerByQuery(uuid).then((response) => {
            if (response?.data?.data?.getPartnerByQuery?.hits?.total > 0 && response?.data?.data?.getPartnerByQuery?.hits?.hits?.length && response.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source) {
                // let haveSource = response.data.data.getPartnerByQuery.hits.hits[0]
                // step 1 details 
                let partnerBasicDetails = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.basicDetails
                let partnerQualificationDetails = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.qualifications && response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.qualifications[0]
                // step 2 details 
                let identityProofs = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.identityProofs
                // step 3 details 
                let partnerAccountDetails = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.accountDetails && response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.accountDetails[0]
                // verification status 
                let verificationStaus = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.verificationStatus
                if (partnerBasicDetails === null || partnerBasicDetails === undefined || partnerQualificationDetails === null || partnerQualificationDetails === undefined || partnerBasicDetails.firstName === "" || partnerBasicDetails.gender === "" || partnerBasicDetails.dob === "" || partnerQualificationDetails.qualificationName === "" || partnerBasicDetails.location === "") {
                    // setStep(1)
                    // setLoader(false)
                    navigate.push("/onboarding")

                    return
                } else if (
                    identityProofs === null ||
                    identityProofs === undefined 
                    // checkDocumentsError(identityProofs)
                ) {

                    // setStep(2)
                    // setLoader(false)
                    navigate.push("/onboarding")
                    return
                } else if (
                    partnerAccountDetails === null ||
                    partnerAccountDetails === undefined ||
                    partnerAccountDetails.bankName === "" ||
                    partnerAccountDetails.branchName === "" ||
                    partnerAccountDetails.accHolderName === "" ||
                    partnerAccountDetails.bankAccNumber === "" ||
                    partnerAccountDetails.ifscCode === ""
                ) {
                    // setStep(3)
                    // setLoader(false)
                    navigate.push("/onboarding")
                    return
                } else if (verificationStaus === "VERIFICATION_PENDING") {
                    // setLoader(false)
                    navigate.push("/verification")
                    return
                }else if(verificationStaus === "VERIFICATION_DONE" && (response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus === null || response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "TRAINING_IN_PROGRESS")){ 
                  // setLoader(false) 
                  axios.get(`https://api.insuranceinbox.in/api/agent/training/${uuid}`).then((res)=>{
                    let [isAllCount,totalCount] = checkTotals(res?.data?.payload) 
                    if(isAllCount && totalCount > 54000) {
                      updateAgentProfile()
                    }
                  }) 
              }
            //    
            }          
            else {  
                console.log("cmg here else");
                // setStep(1)
                // setLoader(false)
                navigate.push("/onboarding")
                return
            }
        }).catch((error)=>{
           console.log("error",error)
          //  setLoader(false)
          navigate.push("/onboarding")
        })
    }
    else if( counter >= 1 && (uuid === "" || uuid === null || uuid === undefined) || (uuid && uuid.length <=0 )){
        console.log("uuid",uuid)
        window.location.href = "https://angelone.upsure.io/health-insurance"
    } 
  }
   
 const getCourseWithChapters = () => {
    axios
      .get(
        "https://premiumapi.upsure.io/api/v2/premium/appContentByType/partner"
      )
      .then((res) => {
        // let coursechapterData = res?.data?.value?._embedded[16]?.trainingDetails[0]?.courseDetails?.filter(ech=>ech.courseId == 201)[0]?.lessons.filter((each)=>each.url.slice(1,-1) == courseUrl)
        let emb = res?.data?.value?._embedded.filter(each => each.pageName == "training_courses")[0]
        let coursechapterData = emb && emb?.trainingDetails[0]?.courseDetails?.filter(ech=>ech.courseId == 201)[0]?.lessons.filter((each)=>each.url.slice(1,-1) == courseUrl)
        setChaptersData(
          () => 
            coursechapterData
        );
        if(agentUuid){
            getEachChapterData().then((res)=>{ 
              let LessonData = res?.data?.data?.getScreeningDetailsByUUID?.payload?.filter(each=>each.lession_id == coursechapterData[0]?.lessionId)[0]
              let time = LessonData?.total ? LessonData?.total  : 0
              // console.log("res each lesson detail aviii",res.data.data.getScreeningDetailsByUUID.payload.filter(each=>each.lession_id == coursechapterData[0]?.lessionId)[0]?.total,LessonData,time);
              if(res?.data?.data?.getScreeningDetailsByUUID?.code == 0){
                secondsToHMS(time)
                setEachLessionLastTime(time)
                setPageLoader(false)
              }
              setPageLoader(false)
        })
        }
        // secondsToHMS(res.data.value._embedded[16].trainingDetails[0].courseDetails.filter(ech=>ech.courseId == 201)[0]?.lessons.filter((each)=>each.url.slice(1,-1) == courseUrl)[0]?.timeSpent)
      });
  };

  const getEachChapterData = () => {

    const options = {
      url: graphqlUrl,
      method: "POST",
      data: {
        query: ` query {
          getScreeningDetailsByUUID(uuid: "${agentUuid}") {
            __typename
            code
            message
            payload {
              __typename
              lession_id
              uuid
              course_id
              training_id
              training_name
              total
            }
          }
        }
  `
      }
    }
   return axios(options)
  }
   
  const setPresentPage = (pages) => {
    // console.log("called");
    // checking is the post avilable or not
    let isPage = pages?.filter((each) => {
      return each.url.slice(1, -1) === courseUrl;
    })
    // console.log("called isPage",isPage,isPage.length);

     if(isPage.length <= 0){
      navigate.push('/training')
     }else{
       setSingePage(() =>
        pages?.filter((each) => {
          console.log(
            "url",
            each.url,
            each.url.slice(1, -1) === courseUrl,
            singlePage
          );
          return each.url.slice(1, -1) === courseUrl;
        })
      );
     }

  };

  const getTodayDate = ()=>{

    var currentDate = new Date();
    var day = currentDate.getDate().toString().padStart(2, '0');
    var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var month = monthNames[currentDate.getMonth()];
    var year = currentDate.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const updateAgentProfile =  () => {
    try {
      const apiUrl = 'https://api.upsure.io/api/agent/profile';
  
      const data = {
        uuid: agentUuid,
        mobile: agentMobileNumber,
        learningStatus: [
          {
            trainingName: 'AGENT',
            courseName: 'POS_GENERAL',
            courseId: '201',
            status: 'TRAINING_COMPLETED',
            startDate: getTodayDate(),
            trainingId: 'T7689980863666549',
          },
        ],
      }; 
     axios.patch(apiUrl, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response)=>{
          // console.log('Response: updated', response.data);
          if(response.data.code == 0 && response.data.message == "success") {
              // window.location.reload()
              // updateUserSpentTime()
              // navigate.push("/training")
              setPageLoader(false)
          }
      })
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  const getCourseTimeSpent = async (uuid) => {
    let configJson = {
      url: graphqlUrl,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: {
        query: `{
            getCourseScreeningDetailsByUUID(
              uuid: "${uuid}"
            ) {
              __typename
              payload {
                __typename
                lession_id
                uuid
                course_id
                training_id
                training_name
                total
              }
            }
        }`,
      },
    };
    return axios(configJson);
  }; 


  const updateTheTimeToChapter = (step)=> {
    const options = {
      url: graphqlUrl,
      method: "POST",
      data: {
        query: `mutation {
          createTraining(
            payload: {
              uuid: ${JSON.stringify(agentUuid)}
              trainingId: 1
              trainingName: "AGENT"
              courseId: 201
              courseName: "POS_GENERAL"
              lessionId: ${JSON.stringify(chaptersData[0]?.lessionId)}
              lessionName: ${JSON.stringify(chaptersData[0]?.lessionName)}
              screeningDetails: {
                startTime: ${JSON.stringify(coursestartTime)}
                endTime: ${JSON.stringify(FormatDate())}
                screenTime: "${ ((hours*60*60 + minutes*60 + seconds*1) - eachLessionLastTime) ? JSON.stringify((hours*60*60 + minutes*60 + seconds*1) - eachLessionLastTime) : 0}"
                deviceId: "e05236fd4d5524ed"
                fingerprint: ""
                ip: "192.0.0.2"
                location: "516203"
                viewType: "ghost"
                typeId: ${JSON.stringify(chaptersData[0]?.url)}
                createdOn: "2024-01-24 10:39:30 am"
              }
            }
          ) {
            __typename
            uuid
            trainingId
            trainingName
            courseId
            courseName
            lessionId
            lessionName
            metaData
          }
        }
  `
      }
    }

    axios(options).then((res)=>{
      // console.log("res",res?.data?.data?.createTraining?.courseId)
      navigate.push("/training")
      setPageLoader(false)
   })
  }

  useEffect(() => {
    getPartnerDetails(agentUuid)
    setCounter(prev=>prev+1)
    getAllGhostPages().then((res) => {
      // console.log("posts", res?.data?.data?.post?.posts, courseUrl);
      setGhostPages(() => res?.data?.data?.post?.posts);
      res?.data?.data?.post?.posts && setPresentPage(res?.data?.data?.post?.posts);
      getCourseWithChapters()
    });
  }, [agentUuid]);

  useEffect(()=>{
    setAgentUuid(() => getCookie("AgentUUID")); 
    setAgentMobileNumber(() => getCookie("AgentMobileNumber"));

    setCourseStatrtTime(()=>FormatDate())
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);

      if (seconds === 59) {
        setSeconds(0);
        setMinutes((prevMinutes) => prevMinutes + 1);

        if (minutes === 59) {
          setMinutes(0);
          setHours((prevHours) => prevHours + 1);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  },[seconds, minutes, hours])

  // useEffect(()=>{
  //   setAgentUuid(() => getCookie("AgentUUID"));
  // },[agentUuid])


// to handle exits from the browser 

  const handleUnload = () => {
    // Phase 1: User closes the tab
    // Implement your logic here
    // alert("closing")
    if(agentUuid){

      updateTheTimeToChapter(1)
    }
  };
 
  const handleVisibilityChange = () => {
    // Phase 2: User switches to the next tab
    // Implement your logic here
    // alert("Please continue reading")
    alert("Please Countine Reading")
    // updateTheTimeToChapter(1)
  };
 
  const handleBackButtonClick = () => {
    // Phase 3: User clicks the back button
    // Implement your logic here
    // alert("clicking")
    setPageLoader(true)
    updateTheTimeToChapter(2)
    //  navigate.push("/training")
  };
 
  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('popstate', handleBackButtonClick);
 
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('popstate', handleBackButtonClick);
    };
  }, []);

  return (
    <div className="ghostpage-main">
      <Header />
      {
        pageLoader ? <PageLoader /> : 

           <div>
             <div className="gst-bk-tim-sec">
        <div className="gstbk-inner">
          <div className="bkpbutton">
            <Button
              label={"Back"}
              status={false}
              isIcon={true}
              img={AllIcons.BackIcon}
              onClick={()=> handleBackButtonClick()}
              buttonType="back-btn"
            />
          </div>
          {/* <div className="pagetitlecon"> */}
            <div className="timersec">
              <div className="pagehdtxt">
                {/* <h1>Supercharger Your Life Sales</h1> */}
                <p>{chaptersData[0]?.lessionName}</p>
              </div>
              <div className="timer-itm">
                <div className="timext">Time: </div>
                <div className="time">{String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</div>
              </div>
            </div>
          {/* </div> */}
        </div>
            </div>
            <div className="clearfix"></div>
            <div className="ghtinnercon">
              <div className="ghost-inner">
                <div
                  className="gh-body"
                  dangerouslySetInnerHTML={{
                    __html: singlePage && singlePage[0] && singlePage[0].html,
                  }}
                ></div>
                <div className="clearfix"></div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
      }
     
    </div>
  );
};

export default TrainingChapters;

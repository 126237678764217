import React from 'react';
import TextField from '@mui/material/TextField';
import "./Input.scss";


const Input = ({label, placeholder, maxLength,onChange,value,disabled,shrink}) => {
    return (
        <TextField fullWidth id="outlined-basic" label={label}
        inputProps={{maxLength, autoComplete: "off", }}
        value={value} 
        onChange={onChange}
        disabled={disabled}
        variant="outlined"        
        InputLabelProps={{
          shrink: shrink,
        }}
        sx={{
          fontWeight: "bold",
          "& .MuiInputBase-root": {
            typography: {
              fontFamily: 'Barlow, sans-serif',
            }
          }
        }}
      />
    );
}

export default Input;

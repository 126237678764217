import React from 'react'
import Header from '../../components/Header/Header'
import './Thankyou.scss'
const Thankyou = () => {
  return (
    <div>
<Header />
            <div className='thankspage'>
                <div className='thanks-content'>
                    <div className='top-section-content'>
                    <img src="https://cdn.upsure.io/POS/common/thanks_img.png" />
                    <h2 className='title-one'>Thank you for choosing<br/>
                        to join our team of dedicated insurance professionals.</h2>
                    <p className='sub-title'>Thanks for completing the onboarding process and providing us with the necessary details to proceed with the verification.</p>
                    </div>
                    <p className='side-heading'>What Happens Next:</p>
                    <div className='process-points'>
                        <div className='items'><p className='num'>1.</p>
                        <p className='text'><b>Verification Process: </b>Our team will now begin the verification process to ensure that all the 
                            information provided aligns with our requirements and industry standards.</p>
                        </div>
                        <div className='items'><p className='num'>2.</p>
                        <p className='text'><b>Confirmation Communication: </b>Once the verification process is complete, you will receive a confirmation sms/ email notifying you of the status of your application.</p>
                        </div>
                        <div className='items'><p className='num'>3.</p>
                        <p className='text'><b>Training: </b>Upon successful verification, you will gain access to our comprehensive learning material designed to empower you in your role as an insurance agent with us.</p>
                        
                        
                        </div>
                        <p className='questionary-txt'>Please feel free to reach out to our onboarding support team, if you have any questions or require further assistance during this process.</p>
                    </div>
                </div>
            </div>

    </div>
  )
}

export default Thankyou
import React from "react";

const DashboardCard = () => {
  return (
    <div className="card-flx">
      <div className="card-item">
        <div className="icon-sec">
          <i className="fa fa-tachometer"></i>
        </div>
        <div className="card-content">Dashboard</div>
      </div>
      <div className="card-item">
        <div className="icon-sec">
          <i className="fa fa-handshake-o"></i>
        </div>
        <div className="card-content">Training</div>
      </div>
      <div className="card-item">
        <div className="icon-sec">
          <i className="fa fa-money"></i>
        </div>
        <div className="card-content">Transaction</div>
      </div>
    </div>
  );
};

export default DashboardCard;

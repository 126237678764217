import React, { useEffect, useState } from "react";
import axios from "axios";
import { getPartnerByQuery, updatePartnerData } from "./serviceApi";
import Input from "../UI/Input/Input";
import Button from "../../components/Button/Button";
import ErrorMsg from "../UI/ErrorMsg/ErrorMsg";
import FileUpload from "../UI/FileUpload/FileUpload";
import ButtonLoader from "../UI/buttonLoader/ButtonLoader";
import PageHeader from "../UI/PageHeader/PageHeader";
import AllIcons from "../../Utility/IconLinks";
import PageLoader from "../UI/PageLoader/PageLoader";

const DocumentDetailsComponent = ({
  setStep,
  agentMobileNumber,
  agentUuid,
  whichStep,
  setWhichStep,
}) => {
  const [loading, setLoading] = useState(false);
  const [graphqlUrl] = useState("https://api.upsure.io/graphql-api/graphql?");
  const [pageLoading, setPageLoading] = useState(true);

  const [documentDetails, setDocumentDetails] = useState({
    pancardNumber: "",
    pancardFile: "",
    pancardFileId: "",
    adharCardNumber: "",
    adharCardFrontFile: "",
    adharCardBackFile: "",
    adharCardFrontFileId: "",
    adharCardBackFileId: "",
    chequeOrPassBookNumber: "",
    chequeOrPassBookFile: "",
    chequeOrPassBookFileId: "",
    highestQualificatinCardNumber: "",
    highestQualificatinCardFile: "",
    highestQualificatinCardFileId: "",
    // all document files names
    pancardFileName: "",
    adharCardFrontFileName: "",
    adharCardBackFileName: "",
    chequeOrPassBookFileName: "",
    highestQualificatinCardFileName: "",
  });

  const [documentDetailsErrormsg, setDocumentDetailsErrormsg] = useState({
    pancardNumberError: "",
    pancardFileError: "",
    pancardFileIdError: "",
    adharCardNumberError: "",
    adharCardFrontFileError: "",
    adharCardBackFileError: "",
    adharCardFrontFileIdError: "",
    adharCardBackFileIdError: "",
    chequeOrPassBookNumberError: "",
    chequeOrPassBookFileError: "",
    chequeOrPassBookFileIdError: "",
    highestQualificatinCardNumberError: "",
    highestQualificatinCardFileError: "",
    highestQualificatinCardFileIdError: "",
  });

  const handleChange = (name, value) => {
    // setting the erros epmty
    setError(`${name}Error`, "");
    setDocumentDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const setError = (name, errorMsg) => {
    setDocumentDetailsErrormsg((prev) => ({
      ...prev,
      [name]: errorMsg,
    }));
  };

  // viewing the document
  const viewFile = (id) => {
    return axios.get(
      "https://api.upsure.io/om-elocker-api/rest/elocker/document/" +
        id +
        "/view"
    );
  };
  // to handle the documents

  const handleDocChange = (evt, key) => {
    evt.preventDefault();
    // console.log("File Selection ==> ", evt, evt.target.files, key);
    let reader = new FileReader();
    let docFile = evt.target.files[0];
    // console.log("name-->", key, docFile);

    let extArray = docFile.name.split(".");
    let ext = extArray[extArray.length - 1];
    if (
      ext !== "png" &&
      ext !== "PNG" &&
      ext !== "jpg" &&
      ext !== "JPG" &&
      ext !== "jpeg" &&
      ext !== "JPEG"
    ) {
      setDocumentDetailsErrormsg(() => ({
        ...documentDetailsErrormsg,
        [key + "Error"]: "Please upload only .png , .jpg , .jpeg files",
      }));
      return false;
    } else if (docFile.size > 3000000) {
      setDocumentDetailsErrormsg(() => ({
        ...documentDetailsErrormsg,
        [key + "Error"]: "file size can not be greater than 3MB",
      }));
      return false;
    } else {
      setDocumentDetailsErrormsg(() => ({
        ...documentDetailsErrormsg,
        [key + "Error"]: "",
      }));
    }
    reader.onloadend = () => {
      setDocumentDetails(() => ({
        ...documentDetails,
        [key]: docFile,
        [key+"Name"]:docFile.name,
      }));
      uploadDocFile(docFile, key);

      // this.setState({
      //     [key]: docFile,
      //     enableContinue: false,
      //     enableDocsButton: true,
      // }, () => {
      //     this.uploadDocFile(docFile, key)
      // });
    };
    setDocumentDetails(() => ({
      ...documentDetails,
      [key]: docFile,
      [key+"Name"]:docFile.name,
    })); 
    reader.readAsDataURL(docFile);
  };
  // uploading the document
  const uploadDocFile = (file, key) => {
    // this.setState({ loading: true });
    // console.log(
    //   "File to Upload-------------------------------------->>>",
    //   file,
    //   key,
    //   "new FormData() ---> ",
    //   new FormData()
    // );

    var formData = new FormData();
    formData.append("document", file);
    formData.append("object", "identityDocument"); //
    formData.append("objectId", "image" + new Date().getTime());
    let directory = "agentdocument" + new Date().getTime();
    formData.append("uploadFolder", directory);

    if (!file.name) {
      setDocumentDetailsErrormsg(() => ({
        ...documentDetailsErrormsg,
        [key + "Error"]: "Please select file",
      }));
      return false;
    } else {
      setDocumentDetailsErrormsg(() => ({
        ...documentDetailsErrormsg,
        [key + "Error"]: "",
      }));
    }

    axios
      .post(
        "https://api.upsure.io/om-elocker-api/rest/elocker/document",
        formData
      )
      .then((data) => {
        // console.log("File Upload DAta =--->>>", data);
        setDocumentDetails(() => ({
          ...documentDetails,
          [key + "Id"]: data.data._id,
        }));
      })
      .catch((err) => {
        setDocumentDetailsErrormsg(() => ({
          ...documentDetailsErrormsg,
          [key + "IdError"]: "Please select file",
        }));
      });
  };

  useEffect(() => {
    // console.log("second");
    if(whichStep < 2){
      setWhichStep(2);
    }
    getPartnerData(agentUuid);
  }, []);

  // getting partner Data from getPartnerByQuery api

  const getPartnerData = (uuid) => {
    if (uuid) {
      getPartnerByQuery(uuid)
        .then((response) => {
          if (
            response?.data?.data?.getPartnerByQuery?.hits?.total > 0 &&
            response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source
          ) {
            let identityProofs =
              response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source
                ?.identityProofs;

            identityProofs &&
              identityProofs.map((each) => {
                if (each.idType === "pan_card") {
                  setDocumentDetails((prev) => ({
                    ...prev,
                    pancardNumber: each.idNo,
                    pancardFileId: each.idDocument1,
                  }));
                  viewFile(each.idDocument1).then((res) => {
                    setDocumentDetails((prev) => ({
                      ...prev,
                      pancardFile: {
                        fileLink: res.data,
                        name: res.data.split("/")[
                          res.data.split("/").length - 1
                        ],
                      },
                    }));
                  });
                } else if (each.idType === "aadhar_card") {
                  setDocumentDetails((prev) => ({
                    ...prev,
                    adharCardNumber: each.idNo,
                    adharCardFrontFileId: each.idDocument1,
                    adharCardBackFileId: each.idDocument2,
                  }));
                  viewFile(each.idDocument1).then((res) => {
                    // console.log("document res", res);
                    setDocumentDetails((prev) => ({
                      ...prev,
                      adharCardFrontFile: {
                        fileLink: res.data,
                        name: res.data.split("/")[
                          res.data.split("/").length - 1
                        ],
                      },
                    }));
                  });
                  viewFile(each.idDocument2).then((res) => {
                    // console.log("document res", res);
                    setDocumentDetails((prev) => ({
                      ...prev,
                      adharCardBackFile: {
                        fileLink: res.data,
                        name: res.data.split("/")[
                          res.data.split("/").length - 1
                        ],
                      },
                    }));
                  });
                } else if (
                  each.idType === "Highest_Qualification_Certificate"
                ) {
                  setDocumentDetails((prev) => ({
                    ...prev,
                    highestQualificatinCardNumber: each.idNo,
                    highestQualificatinCardFileId: each.idDocument1,
                  }));
                  viewFile(each.idDocument1).then((res) => {
                    // console.log("document res", res);
                    setDocumentDetails((prev) => ({
                      ...prev,
                      highestQualificatinCardFile: {
                        fileLink: res.data,
                        name: res.data.split("/")[
                          res.data.split("/").length - 1
                        ],
                      },
                    }));
                  });
                } else if (each.idType === "Cancelled_Cheque_Passbook") {
                  setDocumentDetails((prev) => ({
                    ...prev,
                    chequeOrPassBookNumber: each.idNo,
                    chequeOrPassBookFileId: each.idDocument1,
                  }));
                  viewFile(each.idDocument1).then((res) => {
                    // console.log("document res", res);
                    setDocumentDetails((prev) => ({
                      ...prev,
                      chequeOrPassBookFile: {
                        fileLink: res.data,
                        name: res.data.split("/")[
                          res.data.split("/").length - 1
                        ],
                      },
                    }));
                  });
                }
              });
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        })
        .catch(function (error) {
          console.error(error);
          setPageLoading(false);
        });
    }
  };

  // update partne api

  const updateAgentData = (payload) => {
    //  here we are calling the api updateAgent
    updatePartnerData(payload)
      .then((response) => {
        if (
          response.data.data.updatePartner.code === 0 &&
          response.data.data.updatePartner.message === "success"
        ) {
          setLoading(false);
          setStep(3);
        }
      })
      .catch(function (error) {
        console.error(error);
        setLoading(false);
      });
  };

  const validation = () => {
    // need to add validations for fileUpload

    let result = false;
    const aadhaarRegex = /^\d{12}$/;
    const panCardRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
    // const chequeRegex = ""
    const educationCertificateRegex = /^[a-zA-Z0-9]*$/;
    const checkbookNumberRegex = /^[a-zA-Z0-9]+$/
    const emptyRex = /^\s+$/;

    if (
      documentDetails.pancardNumber === "" ||
      documentDetails.pancardNumber === null ||
      documentDetails.pancardNumber === undefined
    ) {
      setError("pancardNumberError", "Please Enter PAN Number");
      result = true;
    } else if (!panCardRegex.test(documentDetails.pancardNumber)) {
      setError("pancardNumberError", "Please Enter Correct PAN Number");
      result = true;
    }

    if (
      documentDetails.adharCardNumber === "" ||
      documentDetails.adharCardNumber === null ||
      documentDetails.adharCardNumber === undefined
    ) {
      setError("adharCardNumberError", "Please Enter Aadhaar Number");
      result = true;
    } else if (!aadhaarRegex.test(documentDetails.adharCardNumber)) {
      setError("adharCardNumberError", "Please Enter Correct Aadhaar Number");
      result = true;
    }

    if (
      documentDetails.chequeOrPassBookNumber === "" ||
      documentDetails.chequeOrPassBookNumber === null ||
      documentDetails.chequeOrPassBookNumber === undefined ||
      emptyRex.test(documentDetails.chequeOrPassBookNumber)
    ) {
      setError(
        "chequeOrPassBookNumberError",
        "Please Enter Cancelled Cheque/ Passbook  Number"
      );
      result = true;
    }
    else if(!checkbookNumberRegex.test(documentDetails.chequeOrPassBookNumber)){
        setError("chequeOrPassBookNumberError","Please Enter Correct Cancelled Cheque/ Passbook Number")
        result = true;
    }
    
    // highest qalification validation 

    // if (
    //   documentDetails.highestQualificatinCardNumber === "" ||
    //   documentDetails.highestQualificatinCardNumber === null ||
    //   documentDetails.highestQualificatinCardNumber === undefined
    // ) {
    //   setError(
    //     "highestQualificatinCardNumberError",
    //     "Please Enter Certificate Number"
    //   );
    //   result = true;
    // } else if (
    //   !educationCertificateRegex.test(
    //     documentDetails.highestQualificatinCardNumber
    //   )
    // ) {
    //   setError(
    //     "highestQualificatinCardNumberError",
    //     "Please Enter Correct Certificate Number"
    //   );
    //   result = true;
    // }

    if (documentDetails.pancardFileId === "") {
      setError("pancardFileError", "Please Upload pan Card.");
      result = true;
    }

    if (documentDetails.adharCardFrontFileId === "") {
      setError(
        "adharCardFrontFileError",
        "Please Upload Aadhaar Card Front File"
      );
      result = true;
    }

    if (documentDetails.adharCardBackFileId === "") {
      setError(
        "adharCardBackFileError",
        "Please Upload Aadhaar Card Back File"
      );
      result = true;
    }

    if (documentDetails.chequeOrPassBookFileId === "") {
      setError(
        "chequeOrPassBookFileError",
        "Please Upload Cancelled Cheque/ Passbook File"
      );
      result = true;
    }

    if (documentDetails.highestQualificatinCardFileId === "") {
      setError(
        "highestQualificatinCardFileError",
        "Please Upload Highest Qualification Certificate File"
      );
      result = true;
    }
    return result;
  };

  const handleSubmit = () => {
    setLoading(true);

    if (!validation()) {
      const payloadData = {
        uuid: agentUuid,

        mobile: agentMobileNumber,

        identityProofs: [
          {
            idType: "pan_card",

            idNo: documentDetails.pancardNumber,

            idDocument1: documentDetails.pancardFileId,

            idDocument2: "",

            idStatus: "NOT_VERIFIED",

            validUpTo: "",
          },

          {
            idType: "aadhar_card",

            idNo: documentDetails.adharCardNumber,

            idDocument1: documentDetails.adharCardFrontFileId,

            idDocument2: documentDetails.adharCardBackFileId,

            idStatus: "NOT_VERIFIED",

            validUpTo: "",
          },

          {
            idType: "Highest_Qualification_Certificate",

            idNo: documentDetails.highestQualificatinCardNumber,

            idDocument1: documentDetails.highestQualificatinCardFileId,

            idDocument2: "",

            idStatus: "NOT_VERIFIED",

            validUpTo: "",
          },
          {
            idType: "Cancelled_Cheque_Passbook",

            idNo:
              documentDetails.chequeOrPassBookNumber &&
              documentDetails.chequeOrPassBookNumber.trim(),

            idDocument1: documentDetails.chequeOrPassBookFileId,

            idDocument2: "",

            idStatus: "NOT_VERIFIED",

            validUpTo: "",
          },
        ],

        metaData: "source",
      };

      const source = {
        createdSource: "WEB",
        updatedBy: "Partner",
        updatedByUserId: agentUuid,
      };

      let payload = JSON.stringify(payloadData)
        .replace(/\"([^(\")"]+)\":/g, "$1:")
        .replace(
          "source",
          JSON.stringify(source).replace(new RegExp('"', "g"), '\\"')
        );

      updateAgentData(payload);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="onboard-pr-details">
      {pageLoading ? <PageLoader /> : null}
      <PageHeader step={2} whichStep={whichStep} setStep={setStep} />
      <div className="onboard-card">
        <div className="document-details">
          <div className="card-title">Upload Pan Card</div>
          <div className="upload-card">
            <div className="up-int-itm">
              <Input
                label={"Pan Card Number"}
                value={documentDetails.pancardNumber}
                onChange={(e) =>
                  handleChange("pancardNumber", e.target.value.toUpperCase())
                }
              />
              {documentDetailsErrormsg.pancardNumberError ? (
                <ErrorMsg error={documentDetailsErrormsg.pancardNumberError} />
              ) : null}
            </div>
            <div className="up-brw-itm">
              <div className="inpt-item">
                <Input
                  type="text"
                  value={documentDetails.pancardFile.name}
                  shrink={true}
                  label={"Pan Card (Please upload front )"}
                  disabled={
                    documentDetails.pancardFile.name !== "" ? true : false
                  }
                />
              </div>
              <div className="inpt-btn-item">
                <FileUpload
                  onChange={(e) => handleDocChange(e, "pancardFile")}
                />
              </div>
              {/* {console.log("avinash pan card details ===>",documentDetails)}
              {
                documentDetails.pancardFileName ? <p>{documentDetails.pancardFileName}</p> : null
              } */}
              {documentDetails.pancardFileId === "" &&
              documentDetailsErrormsg.pancardFileError !== "" ? (
                <ErrorMsg error={documentDetailsErrormsg.pancardFileError} />
              ) : null} 
               
            </div>
          </div>
          <div className="card-title">Upload Aadhaar Card</div>

          <div className="upload-card">
            <div className="up-int-itm">
              <Input
                label={"Aadhaar Number"}
                value={documentDetails.adharCardNumber}
                onChange={(e) =>
                  handleChange("adharCardNumber", e.target.value)
                }
              />
              {documentDetailsErrormsg.adharCardNumberError ? (
                <ErrorMsg
                  error={documentDetailsErrormsg.adharCardNumberError}
                />
              ) : null}
            </div>
            <div className="up-brw-itm">
              <div className="inpt-item">
                <Input
                  shrink={true}
                  label={"Aadhaar Front View"}
                  disabled={
                    documentDetails.adharCardFrontFile.name !== ""
                      ? true
                      : false
                  }
                  value={documentDetails.adharCardFrontFile.name}
                  type={"text"}
                />
              </div>
              <div className="inpt-btn-item">
                <FileUpload
                  onChange={(e) => handleDocChange(e, "adharCardFrontFile")}
                />
              </div>
              {documentDetails.adharCardFrontFileId === "" &&
              documentDetailsErrormsg.adharCardFrontFileError !== "" ? (
                <ErrorMsg
                  error={documentDetailsErrormsg.adharCardFrontFileError}
                />
              ) : null}
            </div>
            <div className="up-brw-itm">
              <div className="inpt-item">
                <Input
                  shrink={true}
                  label={"Aadhaar Back View"}
                  type={"text"}
                  disabled={
                    documentDetails.adharCardBackFile.name !== "" ? true : false
                  }
                  value={documentDetails.adharCardBackFile.name}
                />
              </div>
              <div className="inpt-btn-item">
                <FileUpload
                  onChange={(e) => handleDocChange(e, "adharCardBackFile")}
                />
              </div>
              {documentDetails.adharCardBackFileId === "" &&
              documentDetailsErrormsg.adharCardBackFileError !== "" ? (
                <ErrorMsg
                  error={documentDetailsErrormsg.adharCardBackFileError}
                />
              ) : null}
            </div>
          </div>

          <div className="card-title">Upload Cancelled Cheque/Passbook</div>

          <div className="upload-card">
            <div className="up-int-itm">
              <Input
                label={"Cancelled Cheque/ Passbook Number"}
                value={documentDetails.chequeOrPassBookNumber}
                onChange={(e) =>
                  handleChange("chequeOrPassBookNumber", e.target.value)
                }
              />

              {documentDetailsErrormsg.chequeOrPassBookNumberError ? (
                <ErrorMsg
                  error={documentDetailsErrormsg.chequeOrPassBookNumberError}
                />
              ) : null}
            </div>
            <div className="up-brw-itm">
              <div className="inpt-item">
                <Input
                  shrink={true}
                  label={"Cancelled Cheque/ Passbook Number"}
                  disabled={
                    documentDetails.chequeOrPassBookFile.name !== ""
                      ? true
                      : false
                  }
                  type={"text"}
                  value={documentDetails.chequeOrPassBookFile.name}
                />
              </div>
              <div className="inpt-btn-item">
                <FileUpload
                  onChange={(e) => handleDocChange(e, "chequeOrPassBookFile")}
                />
              </div>
              {documentDetails.chequeOrPassBookFileId === "" &&
              documentDetailsErrormsg.chequeOrPassBookFileError !== "" ? (
                <ErrorMsg
                  error={documentDetailsErrormsg.chequeOrPassBookFileError}
                />
              ) : null}
            </div>
          </div>

          <div className="card-title">Upload Highest Qualification</div>

          <div className="upload-card">
            {/* <div className="up-int-itm">
              <Input
                label={"Certificate Number"}
                value={documentDetails.highestQualificatinCardNumber}
                onChange={(e) =>
                  handleChange("highestQualificatinCardNumber", e.target.value)
                }
              />
              {documentDetailsErrormsg.highestQualificatinCardNumberError ? (
                <ErrorMsg
                  error={
                    documentDetailsErrormsg.highestQualificatinCardNumberError
                  }
                />
              ) : null}
            </div> */}
            <div className="up-brw-itm">
              <div className="inpt-item">
                <Input
                  shrink={true}
                  label={"Browse to upload document"}
                  type={"text"}
                  disabled={
                    documentDetails.highestQualificatinCardFile.name !== ""
                      ? true
                      : false
                  }
                  value={documentDetails.highestQualificatinCardFile.name}
                />
              </div>
              <div className="inpt-btn-item">
                <FileUpload
                  onChange={(e) =>
                    handleDocChange(e, "highestQualificatinCardFile")
                  }
                />
              </div>

              {documentDetails.highestQualificatinCardFileId === "" &&
              documentDetailsErrormsg.highestQualificatinCardFileError !==
                "" ? (
                <ErrorMsg
                  error={
                    documentDetailsErrormsg.highestQualificatinCardFileError
                  }
                />
              ) : null}
            </div>
          </div>

          <div className="btn-section">
            {loading ? (
              <ButtonLoader />
            ) : (
              <Button
                label={"Continue"}
                status={true}
                isIcon={true}
                img={AllIcons.tickIcon}
                buttonType={"proceed-btn"}
                onClick={() => handleSubmit()}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentDetailsComponent;

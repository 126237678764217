import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import './Select.scss'; 


const SelectComp = ({placeholder,options,value,onChange}) => {
 
  // console.log("options",options);
  return (
    <FormControl sx={{ m: 1, minWidth: '100%' }}>
      <InputLabel id="demo-simple-select-helper-label">{placeholder}</InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        label={placeholder}
        onChange={(e) => onChange(e)}
        value={value ? value : ""}
        // options={options}
        sx={{ fontWeight: "bold" }}
       
        >
          <MenuItem>
           10
          </MenuItem>
          <MenuItem>
           20
          </MenuItem>
          {options && options.length >0 ? options.map((option, id) =>
          <MenuItem 
            sx={{typography: {
                fontFamily: 'Mukta, sans-serif',
                }
              }} 
           key={id} value={option.value}>{option.text}</MenuItem>):null }
        </Select>
        {/* {errorMsg ? <Error />: null} */}
    </FormControl>
    );
}
export default SelectComp;


import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import Header from '../../components/Header/Header';
import Button from "../../components/Button/Button";
import AllIcons from '../../Utility/IconLinks';
import QuestionComponent from './QuestionComponent';
import PageLoader from "../../components/UI/PageLoader/PageLoader";
import ConfirmSumitModal from "../../components/Modals/ConfirmSubmitModal/ConfirmSubmitModal";
import { getPartnerByQuery } from '../../components/onboarding/serviceApi'


import './Exam.scss';


const FailResult = ({ examResult, goToTraining}) => (
  <div className='resultflx'>
    <div className='fail-img-item'>
      <img src='https://cdn.upsure.io/POS/common/exam_fail_img.svg' alt='fail' width="100%" height="100%" />
    </div>
    <div className='fail-title'>
      Oops!
    </div>
    <div className='fail-status'>
      <div className='ststxt'>Status:</div>
      <div className='failtxt'>{examResult.status}</div>
    </div>
    <div className='rest-con'>
    <div className='total-que'>
      <div className='rest-lft'>Total Questions</div>
      <div className='rest-rgt'>: {examResult.totalQuestion}</div>
    </div>
    <div className='total-que'>
      <div className='rest-lft'>Total Marks</div>
      <div className='rest-rgt'>: {examResult.totalMarks}</div>
    </div>
    <div className='total-que'>
      <div className='rest-lft'>Passing Score</div>
      <div className='rest-rgt'>: {examResult.passingScore}</div>
    </div>
    <div className='total-que'>
      <div className='rest-lft'>Obtained Marks</div>
      <div className='rest-rgt'>: <span className='failtxt'>{examResult.obtMarks}</span></div>
    </div>
    </div>
    <div className='failbtn'>
      <Button
              label={"Go to Training"}
              status={true}
              isIcon={true}
              img={AllIcons.tickIcon}
              buttonType={"proceed-btn"}
             onClick={goToTraining}
        />
    </div>
  </div>
);

const PassResult = ({ examResult, goToVerification }) => (
  <div className='resultflx'>
    <div className='fail-img-item'>
      <img src='https://cdn.upsure.io/POS/common/exam_pass_img.svg' alt='pass' width="100%" height="100%" />
    </div>
    <div className='pass-title'>Congratulations!
    </div>
    <div className='pass-status'>
      <div className='ststxt'>Status:</div>
      <div className='passtxt'>{examResult.status}</div>
    </div>
    <div className='rest-con'>
    <div className='total-que'>
      <div className='rest-lft'>Total Questions</div>
      <div className='rest-rgt'>: {examResult.totalQuestion}</div>
    </div>
    <div className='total-que'>
      <div className='rest-lft'>Total Marks</div>
      <div className='rest-rgt'>: {examResult.totalMarks}</div>
    </div>
    <div className='total-que'>
      <div className='rest-lft'>Passing Score</div>
      <div className='rest-rgt'>: {examResult.passingScore}</div>
    </div>
    <div className='total-que'>
      <div className='rest-lft'>Obtained Marks</div>
      <div className='rest-rgt'>: <span className='pastxt'>{examResult.obtMarks}</span></div>
    </div>
    </div>
    <div className='failbtn'>
      <Button
              label={"Continue"}
              status={true}
              isIcon={true}
              img={AllIcons.tickIcon}
              buttonType={"proceed-btn"}
             onClick={goToVerification}
        />
    </div>
  </div>
);


const Exam = () => {

  const navigate = useHistory()

const goToTraining = ()=>{
  localStorage.setItem("isChapter", false);
  navigate.push("/training")


}

const goToVerification = ()=>{
  navigate.push("/verification")


}

  // to get uuid from cookie
  const getCookie = (name) => {
    var nameEQ = name + "=";
    var cookiesArray = document.cookie.split(';');
    for (var i = 0; i < cookiesArray.length; i++) {
        var cookie = cookiesArray[i];
        while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(nameEQ) == 0) {
            return cookie.substring(nameEQ.length, cookie.length);
        }
    }
    return null;
}



const handleTryAgain = () => {
  // Reload the page
  window.location.reload();
};


  const [examQuestions, setExamQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [examResult, setExamResult] = useState(null);
  const [timer, setTimer] = useState(1200); // 20 minutes in seconds
  const [examId, setExamId] = useState(null);
  const [timerRunning, setTimerRunning] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [agentUuid,setAgentUuid]=useState(null)
  const [resultloader, setResultLoader] = useState(false);
  const [errorMsgLoader, setErrorMsgLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [sureModalOpen, setsureModalOpen] = useState(false);
  const [obtMarks, setObtMarks] = useState('');
  const [agentMobileNumber, setAgentMobileNumber] = useState("");
  const [todayDate, setTodayDate] = useState("");
 
  


  useEffect(() => {
    // const currentDate = new Date();
    // const formattedDate = `${currentDate.getDate()}-${currentDate.toLocaleString('default', { month: 'short' })}-${currentDate.getFullYear()}`;
    setTodayDate(formattedDate())
    const agentUUID = getCookie("AgentUUID");
    setAgentMobileNumber(()=> getCookie("AgentMobileNumber"))
    setAgentUuid(agentUUID);
  }, []); 

  function formattedDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const monthIndex = today.getMonth();
    const year = today.getFullYear();
    // Define an array of month abbreviations
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = months[monthIndex];    
    return `${day}-${month}-${year}`;
}

  const fetchQuestions = async () => {
    try {
      // Ensure agentUuid is not null before making the API call
      if (agentUuid) {
        const response = await axios.post(
          'https://api.upsure.io/graphql-api/graphql',
          {
            query: `
              query {
                getExamQuestion(uuid: "${agentUuid}", courseId: 201) {
                  examId
                  date
                  startTime
                  endTime
                  tMarks
                  eachQueMarks
                  uuid
                  examQue {
                    questionId
                    question
                    opt1
                    opt2
                    opt3
                    opt4
                    examName
                  }
                }
              }
            `,
          }
        );


        if (
          response.data &&
          response.data.data &&
          response.data.data.getExamQuestion &&
          response.data.data.getExamQuestion.examQue
        ) {
          setExamQuestions(response.data.data.getExamQuestion.examQue);
          setExamId(response.data.data.getExamQuestion.examId);
          setLoading(false); 
          setTimerRunning(true)
          // Move this line here to set loading to false only if questions are fetched

        } else {
          console.error('Invalid response format:', response);
          setLoading(false); // If questions are not fetched successfully, set loading to false
          setErrorMsgLoader(true)
          
        }
      }
    } catch (error) {
      console.error('Error fetching exam data:', error);
      setLoading(false); // If there's an error fetching questions, set loading to false
      setErrorMsgLoader(true)
    }
  };

  useEffect(() => {
    if (agentUuid){ 
    const fetchData = async () => {
      try {


        getPartnerByQuery(agentUuid).then((response) => {
          // setLoader(false) 
          let sourceData = response?.data?.data?.getPartnerByQuery?.hits?.total > 0 && response?.data?.data?.getPartnerByQuery?.hits?.hits?.length && response.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source
          let verificationStaus = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.verificationStatus
         if (sourceData) { 
              let trainngDetails = (verificationStaus === "VERIFICATION_DONE" && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0]) 
              let examDetails = (verificationStaus === "VERIFICATION_DONE" && (response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails == null || response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0]) )
              if(trainngDetails.status == "TRAINING_IN_PROGRESS" || trainngDetails.status == "TRAINING_Inprogress"){
                navigate.push("/training")
              }else if(trainngDetails.status == "TRAINING_COMPLETED" ){
                fetchQuestions();
              }else if(examDetails.status == "PASS"){
                navigate.push("/verification")
              }else {
                fetchQuestions();
              }
               
         } else {
           console.error('Invalid response format:', response);
         }
        })
          
      //  view partner api 

        // const response = await axios.post(
        //   'https://api.upsure.io/graphql-api/graphql',
        //   {
        //     query: `
        //       query {
        //         viewPartner(uuid: "${agentUuid}") {
        //           uuid
        //           mobile
        //           role
        //           basicDetails {
        //             firstName
        //             middleName
        //             lastName
        //             businessUserName
        //             dob
        //             email
        //             gender
        //             location
        //             profileImage
        //             term
        //             reasonOfInActive
        //             inActiveComment
        //           }
        //           address {
        //             addressType
        //             addressLine1
        //             addressLine2
        //             state
        //             area
        //             city
        //             pincode
        //             stateCode
        //             cityCode
        //           }
        //           channels
        //           stores {
        //             storeId
        //             storeName
        //           }
        //           partners {
        //             role
        //             partnerId
        //             partnerMobile
        //             partnerName
        //             partnerStatus
        //           }
        //           qualifications {
        //             qualificationName
        //             proofDocumentId
        //           }
        //           identityProofs {
        //             idType
        //             idNo
        //             idDocument1
        //             idDocument2
        //             idStatus
        //             validUpTo
        //           }
        //           partnerStatus
        //           verificationStatus
        //           validUpTo
        //           status
        //           learningStatus {
        //             trainingName
        //             courseName
        //             courseId
        //             status
        //             certificateLink
        //           }
        //           accountDetails {
        //             bankName
        //             branchName
        //             accHolderName
        //             bankAccNumber
        //             ifscCode
        //           }
        //           eventMetaData {
        //             createdBy
        //             createdByUserId
        //             addedDate
        //             updatedBy
        //             updatedByUserId
        //             updatedDate
        //           }
        //           examDetails {
        //             examId
        //             totalMarks
        //             obtMarks
        //             status
        //             passMark
        //             completedDate
        //             courseId
        //             courseName
        //             certificateLink
        //           }
        //           activities {
        //             channel
        //             levels
        //           }
        //           licenseStatus {
        //             uuid
        //             trainingCheck
        //             examinationCheck
        //             posSystemCheck
        //             reasonOfLicenseRejection
        //             renewed
        //             licenseStartDate
        //             licenseEndDate
        //             licenseStatus
        //             licenseNo
        //             licenseId
        //             licenseType
        //             courseId
        //             courseName
        //             licenseLink
        //           }
        //         }
        //       }
        //     `,
        //   }
        // );
         
        // if (response.data && response.data.data && response.data.data.viewPartner) {
        //  if (response.data.data.viewPartner.learningStatus == null || (response.data.data.viewPartner?.learningStatus?.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "TRAINING_Inprogress") ||(response.data.data.viewPartner?.learningStatus?.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "TRAINING_IN_PROGRESS")){
        //   navigate.push("/training")         
        //  }
        //  else if (response.data.data.viewPartner.examDetails == null && (response.data.data.viewPartner?.learningStatus?.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "TRAINING_COMPLETED") ){
        //    fetchQuestions();
        //  }
        //  else if(response.data.data.viewPartner?.examDetails?.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "PASS"){
        //     navigate.push("/verification")
        //   }
        //   else{ 
        //     fetchQuestions();
        //    }
        // } else {
        //   console.error('Invalid response format:', response);
        // }

        //  view partner api end


      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();

  }
  }, [agentUuid]);
  
  
  function getCurrentFormattedDate() {
    const date = new Date();
  
    const pad = (num) => num.toString().padStart(2, '0');
    const padMilliseconds = (num) => num.toString().padStart(3, '0');
  
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Month is 0-indexed
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const milliseconds = padMilliseconds(date.getMilliseconds());
  
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  
  const updateAgentProfile = async (agentUuid, examId, obtMarks, agentMobileNumber, examStatus) => {
    try {
      const response = await axios.patch(
        'https://api.insuranceinbox.in/api/agent/profile',
        {
          uuid: agentUuid,
          mobile: agentMobileNumber,
          examDetails: [
            {
              certificateLink: null,
              completedDate: todayDate,
              courseId: '201',
              courseName: 'POS_GENERAL',
              examId: examId,
              obtMarks: obtMarks,
              passMark: '40',
              status: examStatus,
              totalMarks: '100',
            },
          ],
          "licenseStatus": examStatus == "PASS" ? [
            {
            "uuid":agentUuid,
            "licenseType":"POSP_GENERAL",
            "updatedDate":getCurrentFormattedDate(),
            "licenseStatus":"PENDING"
            }
           ]:undefined
        }
      );
  
  
    } catch (error) {
      console.error('Error updating agent profile:', error);
      // Handle error as needed
    }
    finally{
      setResultLoader(false);
    }
  };
  

  useEffect(() => {
    if (!loading && timerRunning) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            setTimerRunning(false);
            clearInterval(intervalId);
            handleSubmit(); 
          }
          return prevTimer - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [loading, timerRunning]);

 

  const handleFinishExam = () => {
    setsureModalOpen(true)  
    //setTimerRunning(false)
     
  };
  
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} min ${remainingSeconds} Sec`;
  };




  const handleRadioChange = (questionId, selectedOption) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [questionId]: selectedOption,
    }));
  };

  const handleSubmit = async () => {

    setResultLoader(true);
    const payload = Object.keys(selectedOptions).map((questionId) => ({
      questionId: parseInt(questionId),
      answer: selectedOptions[questionId],
    }));

    try {
      const response = await axios.post(
        'https://api.upsure.io/graphql-api/graphql',
        {
          query: `
            mutation {
              calculateExamResult(
                uuid: "${agentUuid}"
                examId: "${examId}"
                payload: ${JSON.stringify(payload).replace(/"([^"]+)":/g, '$1:')} 
              ) {
                __typename
                uuid
                totalMarks
                obtMarks
                passingScore
                status
                totalQuestion
                examQueAns {
                  __typename
                  questionId
                  question
                  correct
                  answer
                  isCorrect
                }
              }
            }
          `,
        }
      );
     setExamResult(response.data.data.calculateExamResult);
     setShowResults(true);
     
     const ObtainedMarks = response.data.data.calculateExamResult.obtMarks;
     setObtMarks(ObtainedMarks);

     updateAgentProfile(agentUuid, examId, response.data.data.calculateExamResult.obtMarks,agentMobileNumber,response.data.data.calculateExamResult.status)
    } catch (error) {
      console.error('Error submitting exam:', error);
    }

  };



  return (
    <div className="exam-main">
      <Header />
       {sureModalOpen ? (
          <ConfirmSumitModal
          setModalOpen={setModalOpen}
          setTimerRunning={setTimerRunning}
          setsureModalOpen={setsureModalOpen}
          handleSubmit={handleSubmit}
          />
        ) : null}
      {!showResults && !errorMsgLoader ?
      <div className="pagehedsec">
        <div className="pagehead-main">
          <div className="exam-timer">
            <div className="clock-item">
              <div className="timefill-cir"></div>
            </div>
            <div className="time-running">
              <div className="timer-itm">{formatTime(timer)}</div>
              <div className="time-rem-itm">Time Remaining</div>
            </div>
          </div>
          
          <div className="pagebtn-item">
            <Button
              label={'Finish Exam'}
              status={true}
              isIcon={true}
              img={AllIcons.tickIcon}
              buttonType="proceed-btn"
              onClick={handleFinishExam}
            />
          </div>
        </div>
      </div>:errorMsgLoader ? <div ></div> : 
      examResult.status === "FAIL" ?
      <FailResult examResult={examResult} goToTraining={goToTraining}/>
      :
      <PassResult examResult={examResult} goToVerification={goToVerification} />
          } 
      {loading || resultloader ? (
        <div><PageLoader/></div>
      )  : (!showResults) ? errorMsgLoader ? <div className='something-wrong'>
      <div className='something-wrong-img'>
      <img src='https://cdn.upsure.io/POS/common/somthing_went_wrong_img.svg' alt='Something Went Wrong' width="100%" height="100%" />
    </div>
    <p className='opps-text'>Oops! </p>
    <p className='please-try-again'>Something went wrong. Please try again</p>
    <div className='something-wrong-btn'>
    <Button 
      label={'Please Try Again'}
      status={true}
      isIcon={false}
      buttonType="proceed-btn"
      onClick={handleTryAgain} />
      </div>
    </div>:(
        <div className="exambdy-main">
          
          <div className="exambdy-sec">
            {examQuestions.length > 0 && (
              <QuestionComponent
                examQuestions={examQuestions}
                examId={examId}
                selectedOptions={selectedOptions}
                handleRadioChange={handleRadioChange}  
                handleSubmitCallback={handleFinishExam}
              />
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Exam;

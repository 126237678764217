import React, { useEffect, useState } from "react";
import "./Header.scss";
import axios from "axios";

const Header = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [isLoginPopup, setIslogInPopup] = useState(false);
  const [flag, setFlag] = useState(true);
  const [uuid, setUuid] = useState("");
  const [agentMobileNumber, setAgentMobileNumber] = useState("");

  const getCookie = (name) => {
    var nameEQ = name + "=";
    var cookiesArray = document.cookie.split(";");
    for (var i = 0; i < cookiesArray.length; i++) {
      var cookie = cookiesArray[i];
      while (cookie.charAt(0) == " ") {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) == 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  };

  const [userDetails, setUserDetails] = useState({
    userName: "",
    userUUID: "",
    mobileNumber: "",
    email: "",
  });

  const getUseDetails = (uuid) => {
    if (uuid) {
      axios
        .get(
          `https://api.upsure.io/api/authentication/v1/user/get?uuid=${uuid}`
        )
        .then(function (response) {
          // console.log("user res", response.data.value);
          if (response.data.code === 0 && response.data.value) {
            setUserDetails((prev) => ({
              ...prev,
              userName: response.data.value.fullName,
              mobileNumber: response.data.value.mobile,
              email: response.data.value.email,
            }));
          } else {
            console.log("user not found");
          }
        });
    } else {
      console.log("dont have uuid");
    }
  };


  const signOut = () => {
    // Get all cookies
    const cookies = document.cookie.split(";");

    // Loop through cookies and clear each one
    cookies.forEach((cookie) => {
      const cookieName = cookie.split("=")[0].trim();
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });

    window.location.href = "https://www.angelone.in/insurance/";
  };

  useEffect(() => {
    //    setIdToken(()=> getCookie())
    setUuid(() => getCookie("AgentUUID"));
    setAgentMobileNumber(() => getCookie("AgentMobileNumber"));

    getUseDetails(uuid);
  }, [uuid,agentMobileNumber]);

  return (
    <header className="header">
      <div className="innerheader">
        <div className="logo-sec">
          <a href="https://www.angelone.in/insurance/" className="logo">
            <img
              src="https://cdn.upsure.io/POS/common/logo.png"
              alt="Angle"
              width="100%"
              height="100%"
            />
          </a>
        </div>
        {
        isLogin ? (
          <div className="profile-section">
            <div
              className="profile-pic"
              onClick={() => setIslogInPopup((prev) => !prev)}
            >
                <img src="https://cdn.upsure.io/POS/common/header_user_inc.svg" alt="User" width="100%" height="100%" />
              {/* <i className="fa fa-user"></i> */}
            </div>

            {isLoginPopup ? (
              <div className="profile-div">
                <div className="pro-head">
                  <h4>{userDetails.name}</h4>
                  <h4>{userDetails.email}</h4>
                  <h4>
                    {userDetails.mobileNumber
                      ? userDetails.mobileNumber
                      : agentMobileNumber
                      ? agentMobileNumber
                      : ""}
                  </h4>
                </div>
                <div className="prof-footer">
                  {/* <span className="p-content" >My Account</span> */}
                  <div className="snt-btnsec">
                    <button className="md-bt " onClick={() => signOut()}>
                      Sign out
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </header>
  );
};

export default Header;

import React from 'react';
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import "./ErrorMsg.scss";

const ErrorMsg = ({error}) => {
    return (
        <div className='errorcont'>
            <div className='error-inc'>
            <ErrorIcon />
            </div>
            <div className='error-msg'>{error}</div>
        </div>
    );
}

export default ErrorMsg;

import React, { MouseEvent as ReactMouseEvent, CSSProperties, useCallback, useRef, useState, useEffect  } from 'react';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  Node,
  Viewport,
  SnapGrid,
  Connection,
  Edge,
  ReactFlowInstance,
  useNodesState,
  useEdgesState,
  OnSelectionChangeParams,
} from 'react-flow-renderer';

import {TextUpdaterNode, DelayNode, EndNode, TriggerNode} from './TextUpdaterNode.js';

import "./scss/index.scss";

const onNodeDragStart = (_, node, nodes) => console.log('drag start', node, nodes);
const onNodeDrag = (_, node, nodes) => console.log('drag', node, nodes);
const onNodeDragStop = (_, node, nodes) => console.log('drag stop', node, nodes);
const onNodeDoubleClick = (_, node) => console.log('node double click', node);
const onPaneClick = (event) => console.log('pane click', event);
const onPaneScroll = (event) => console.log('pane scroll', event);
const onPaneContextMenu = (event) => console.log('pane context menu', event);
const onSelectionDrag = (_, nodes) => console.log('selection drag', nodes);
const onSelectionDragStart = (_, nodes) => console.log('selection drag start', nodes);
const onSelectionDragStop = (_, nodes) => console.log('selection drag stop', nodes);
const onSelectionContextMenu = (event, nodes) => {
  event.preventDefault();
  console.log('selection context menu', nodes);
};
const onNodeClick = (_, node) => { return node }; // console.log('node click:', node);

const onSelectionChange = ({ nodes, edges }) => console.log('selection change', nodes, edges);
const onInit = (reactFlowInstance) => {
  console.log('pane ready:', reactFlowInstance);
};

const onMoveStart = (_, viewport) => console.log('zoom/move start', viewport);
const onMoveEnd = (_, viewport) => console.log('zoom/move end', viewport);
const onEdgeContextMenu = (_, edge) => console.log('edge context menu', edge);
const onEdgeMouseEnter = (_, edge) => console.log('edge mouse enter', edge);
const onEdgeMouseMove = (_, edge) => console.log('edge mouse move', edge);
const onEdgeMouseLeave = (_, edge) => console.log('edge mouse leave', edge);
const onEdgeDoubleClick = (_, edge) => console.log('edge double click', edge);
const onNodesDelete = (nodes) => console.log('nodes delete', nodes);
const onEdgesDelete = (edges) => console.log('edges delete', edges);

const initialNodes = [
  {
    id: '1',
    type: 'input',
    data: {
      label: (
        <>
        {/* Mode: <strong>Segment Name Here</strong> <br/>
        Name: <strong>Segment Name Here</strong> <br/>
        Segment: {
            // this.props.selectedSegments
            [{segment_name:"Abc"},{segment_name:"Def"},{segment_name:"Ghi"},{segment_name:"Jkl"}].map(segment => <a className="rd-rm-chip">
                <span>{segment.segment_name + " "
                // this.props.selectedSegments && this.props.selectedSegments.length > 1 ? ", " : ""
                // [{segment_name:"Abc"},{segment_name:"Def"},{segment_name:"Ghi"},{segment_name:"Jkl"}].length > 1 ? ", " : ""
                }</span>
                {/* <i className="fa fa-times-circle" onClick={() => {
                    let newSegments = this.props.selectedSegments.filter(e => e.segment_id !== segment.segment_id);
                    this.props.onChange("selectedSegments", newSegments);
                }}/> //
            </a>)
        } */}

        Mode: <strong></strong> <br/>
        Name: <strong></strong> <br/>
        Segment: 
        </>
      ),
    },
    position: { x: -300, y: 0 },
    sourcePosition: 'right',
  },
  {
    id: '2',
    type: 'textUpdater', 
    // position: { x: 0, y: 0 }, 
    // data: { value: 123 }, 
    // data: {
    //   label: (
    //     <>
    //       This is a <strong>default node</strong>
    //     </>
    //   ),
    // },
    position: { x: 0, y: 50 },
    sourcePosition:"down"
  }
  // {
  //   id: '3',
  //   data: {
  //     label: (
  //       <>
  //         This one has a <strong>custom style</strong>
  //       </>
  //     ),
  //   },
  //   position: { x: 400, y: 100 },
  //   style: { background: '#D6D5E6', color: '#333', border: '1px solid #222138', width: 180 },
  // },
  // {
  //   id: '4',
  //   position: { x: 250, y: 200 },
  //   data: {
  //     label: (
  //       <>
  //         You can find the docs on{' '}
  //         <a href="https://github.com/wbkd/react-flow" target="_blank" rel="noopener noreferrer">
  //           Github
  //         </a>
  //       </>
  //     ),
  //   },
  // },
  // {
  //   id: '5',
  //   data: {
  //     label: (
  //       <>
  //         Or check out the other <strong>examples</strong>
  //       </>
  //     ),
  //   },
  //   position: { x: 250, y: 325 },
  // },
  // {
  //   id: '6',
  //   type: 'output',
  //   data: {
  //     label: (
  //       <>
  //         An <strong>output node</strong>
  //       </>
  //     ),
  //   },
  //   position: { x: 100, y: 480 },
  // },
  // { id: '7', type: 'output', data: { label: 'Another output node' }, position: { x: 400, y: 450 } },
];

const initialEdges = [
  { id: '1', source: '1', target: '2', style: { stroke: '#f6ab6c' }, animated: true, 
  // label: 'this is an edge label'
 },
  // { id: 'e1-3', source: '1', target: '3' },
  // { id: 'e3-4', source: '3', target: '4', animated: true, label: 'animated edge' },
  // { id: 'e4-5', source: '4', target: '5', label: 'edge with arrow head' },
  // { id: 'e5-6', source: '5', target: '6', type: 'smoothstep', label: 'smooth step edge' },
  // {
  //   id: '2',
  //   source: '1',
  //   target: '3',
  //   type: 'step',
  //   style: { stroke: '#f6ab6c' },
  //   label: 'a step edge',
  //   animated: true,
  //   labelStyle: { fill: '#f6ab6c', fontWeight: 700 },
  //   sourceHandle: 'b',
  // },
];

const connectionLineStyle = { stroke: '#ddd' };
const snapGrid = [25, 25];

const nodeStrokeColor = (n) => {
  if (n.style?.background) return n.style.background;
  if (n.type === 'input') return '#0041d0';
  if (n.type === 'output') return '#ff0072';
  if (n.type === 'default') return '#1a192b';

  return '#eee';
};

const nodeColor = (n) => {
  if (n.style?.background) return n.style.background;

  return '#fff';
};

// we define the nodeTypes outside of the component to prevent re-renderings
// you could also use useMemo inside the component
const nodeTypes = { textUpdater: TextUpdaterNode, trigger: TriggerNode, delay: DelayNode, end : EndNode };

const OverviewFlow = () => {
  // const [nodes, setNodes, onNodesChange ] = useState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useState(initialEdges);
  const [activeNode, setActiveNode] = useState({id:'1'})
  const [currentNode, setActiveNodeCurrent] = useState()
  const [btnDisabled, setBtnDisabled] = useState(false)
  const data = new URL(window.location.href).searchParams.get('data') ? JSON.parse(new URL(window.location.href).searchParams.get('data')) : '';

  const [nodes, setNodes, onNodesChange ] = useState([
                                                      {
                                                        id: '1',
                                                        type: 'input',
                                                        data: {
                                                          label: (
                                                            <>
                                                            <p>Mode: <strong>{data.campaignMode}</strong> </p>
                                                            <p>Name: <strong>{data.campaignName}</strong> </p>
                                                            <p>Segment: {
                                                                [{segment_name:"Abc"},{segment_name:"Def"},{segment_name:"Ghi"},{segment_name:"Jkl"}]
                                                                // data.selectedSegments
                                                                .map(segment => <a className="rd-rm-chip">
                                                                    <span>{
                                                                    segment.segment_name 
                                                                    // data.selectedSegments && data.selectedSegments.length > 1 ? `${segment.segment_name} , `: `${segment.segment_name}`
                                                                    }</span>
                                                                </a>)
                                                            } </p>
                                                            </>
                                                          ),
                                                        },
                                                        position: { x: -300, y: 0 },
                                                        sourcePosition: 'right',
                                                      },
                                                      {
                                                        id: '2',
                                                        type: 'textUpdater', 
                                                        position: { x: 0, y: 50 },
                                                        sourcePosition:"down"
                                                      }
                                                    ])

  // const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);
  console.log("Window location Parms ========> ", window.location);
  const yPos = useRef(50);
  const xPos = useRef(0);

  const onNodeClick = (_, node) => {
    console.log("========> Active Node Clicked", node);
    setActiveNode(node)
  }

  // const addNode = useCallback(() => {
  //   // let details = onNodeClick()
  //   yPos.current += 50;
  //   console.log("Nodes Add Node fun============> ",nodes, activeNode, "currentNode ==========>", currentNode);
  //   setNodes((nodes) => {
  //     return [
  //       ...nodes,
  //       {
  //         id: nodes.length + 1 + "" ,
  //         type: 'textUpdater', 
  //         position: { x: 0, y: yPos.current },
  //         // data: { label: "Edit Node" },
  //         sourcePosition: parseInt(activeNode.id) == 1 ? 'left' : "top",
  //         sourceHandle: parseInt(activeNode.id) == 1 ? 'a' : 'b'
  //       }
  //     ];
  //   });
  // }, []);

  useEffect(()=>{
    console.log("After Nodes Chanxge ------------------> ", currentNode, nodes);
    nodes && nodes.length > 2 && addEdge({source: currentNode ? currentNode.id : "1", target: nodes.length})
  }, [nodes])


  useEffect(()=>{
    console.log("===================== Active Node Chagned =======================", activeNode);
    setActiveNodeCurrent(activeNode)
  }, [activeNode])

  useEffect(() =>{
    console.log("URL DATA ===> ", data,  data.campaignMode, data.campaignName, data.campaignDesc, data.onetimeMode, data.selectedSegments, data.selectedTemplate, data.scheduleMode, data.scheduleRepeat, data.scheduleStartOn, data.scheduleEndOnDate, data.scheduleStartOnTime, "PARSED DATA ===> ", data);
   
    // {campaignMode: SMS,onetimeMode: instant,campaignName: React Flow,campaignDesc: React flow DEsc,selectedSegments: [object Object],[object Object],[object Object],[object Object],[object Object],selectedTemplate: [object Object], scheduleMode: oneTime, scheduleRepeat: , scheduleStartOn: , scheduleStartOnTime: 09:00, scheduleEndOnDate:  }
   
    const initialNodes = [
      {
        id: '1',
        type: 'input',
        data: {
          label: (
            <>
            Mode: <strong>{data.campaignMode}</strong> <br/>
            Name: <strong>{data.campaignName}</strong> <br/>
            Segment: {
                [{segment_name:"Abc"},{segment_name:"Def"},{segment_name:"Ghi"},{segment_name:"Jkl"}]
                // data.selectedSegments
                .map(segment => <a className="rd-rm-chip">
                    <span>{segment.segment_name 
                      // + data.selectedSegments && data.selectedSegments.length > 1 ? ", " : ""
                    }</span>
                </a>)
            }
            </>
          ),
        },
        position: { x: -300, y: 0 },
        sourcePosition: 'right',
      },
      {
        id: '2',
        type: 'textUpdater', 
        position: { x: 0, y: 50 },
        sourcePosition:"down"
      }
    ];
    console.log("Initial Nodes =========>", initialNodes);
    // setNodes(initialNodes)
  },[data])

  const addNode = (type) => {
    yPos.current += 50;
    xPos.current += 150;
    console.log("Nodes Add Node fun============> ",nodes, activeNode, "currentNode ==========>", currentNode, "XPOS =====>", currentNode && currentNode.position && currentNode.position.x == -300 ? 0 : (xPos.current - (currentNode && currentNode.position && currentNode.position.x ? currentNode.position.x : 0) == 100) ? xPos.current + 150 : xPos.current,);
    setNodes((nodes) => {
      return [
        ...nodes,
        {
          id: nodes.length + 1 + "" ,
          position: { x: currentNode && currentNode.position && currentNode.position.x == -300 ? 0 : (xPos.current - (currentNode && currentNode.position && currentNode.position.x ? currentNode.position.x : 0) == 100) ? xPos.current + 150 : xPos.current, 
            y: currentNode && currentNode.position && currentNode.position.x == -300 ?  yPos.current : currentNode && currentNode.position && currentNode.position.y ? currentNode.position.y  : 0 },
          // data: { label: "Edit Node" },
          // targetPosition: 'top',
          // sourcePosition: parseInt(activeNode.id) == 1 ? 'right' : "bottom",
          // sourceHandle: parseInt(activeNode.id) == 1 ? 'a' : 'b'
          type: type == 'node' ? 'textUpdater' : type == 'trigger' ? 'trigger' : type == 'delay' ? 'delay' : 'end', 
        }
      ];
    });
    setBtnDisabled(type == "end")
  };

  const addEdge = ({ source, target }) => {
    setEdges((edges) => {
      console.log("Add edges funct ==> ", edges, source, target, activeNode, nodes, nodes.length);
      return [
        ...edges,
        {
          id: edges.length + 1 + "", 
          // source: '1', 
          // target: '2', 
          style: { stroke: '#f6ab6c' }, 
          animated: true, 
          // label: 'this is an new edge label',
         
          // id: Math.random(),
          source: source ? source + "" : activeNode.id,
          target: target ? target + "" : nodes.length + 1 + ""
        }
      ];
    });
  };

  // const addEdge = useCallback(({ source, target }) => {
  //   setEdges((edges) => {
  //     console.log("Add edges funct ==> ", edges, source, target, activeNode, nodes, nodes.length);
  //     return [
  //       ...edges,
  //       {
  //         id: edges.length + 1 + "", 
  //         // source: '1', 
  //         // target: '2', 
  //         style: { stroke: '#f6ab6c' }, 
  //         animated: true, 
  //         label: 'this is an new edge label',
         
  //         // id: Math.random(),
  //         source: source ? source + "" : activeNode.id,
  //         target: target ? target + "" : nodes.length + 1 + ""
  //       }
  //     ];
  //   });
  // }, []);

  return (
    <React.Fragment>
      {console.log("Nodes ============> IN RENDER",nodes)}
      {console.log("Add edges IN RENDER ==> ",edges, "Active Node ==>", activeNode)}
      {/* <ReactFlow elements={nodes} onConnect={addEdge} > */}
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeClick={onNodeClick}
        onConnect={onConnect}
        onPaneClick={onPaneClick}
        onPaneScroll={onPaneScroll}
        onPaneContextMenu={onPaneContextMenu}
        onNodeDragStart={onNodeDragStart}
        onNodeDrag={onNodeDrag}
        onNodeDragStop={onNodeDragStop}
        onNodeDoubleClick={onNodeDoubleClick}
        onSelectionDragStart={onSelectionDragStart}
        onSelectionDrag={onSelectionDrag}
        onSelectionDragStop={onSelectionDragStop}
        onSelectionContextMenu={onSelectionContextMenu}
        onSelectionChange={onSelectionChange}
        onMoveStart={onMoveStart}
        onMoveEnd={onMoveEnd}
        onInit={onInit}
        connectionLineStyle={connectionLineStyle}
        snapToGrid={true}
        snapGrid={snapGrid}
        onEdgeContextMenu={onEdgeContextMenu}
        onEdgeMouseEnter={onEdgeMouseEnter}
        onEdgeMouseMove={onEdgeMouseMove}
        onEdgeMouseLeave={onEdgeMouseLeave}
        onEdgeDoubleClick={onEdgeDoubleClick}
        fitView
        nodeTypes={nodeTypes}
        fitViewOptions={{ padding: 0.2 }}
        attributionPosition="top-right"
        // maxZoom={Infinity}
        onNodesDelete={onNodesDelete}
        onEdgesDelete={onEdgesDelete}
        // onConnect={addEdge}
      >
        {/* <MiniMap nodeStrokeColor={nodeStrokeColor} nodeColor={nodeColor} nodeBorderRadius={2} /> */}
        <Controls /> 
        <Background color="#aaa" gap={25} />
      </ReactFlow>
      <div className='btn-sec'>
        <button className={!btnDisabled ? 'btn-node' : 'btn-disabled'} disabled={btnDisabled} onClick={() => {
          addNode('node')
        }}>Add Node</button>
        <button className={!btnDisabled ? 'btn-trigger' : 'btn-disabled'} disabled={btnDisabled} onClick={() => {
          addNode('trigger')
        }}>Add Trigger</button>
        <button className={!btnDisabled ? 'btn-delay' : 'btn-disabled'} disabled={btnDisabled} onClick={() => {
          addNode('delay')
        }}>Add Delay</button>
        <button className={!btnDisabled ? 'btn-delay' : 'btn-disabled'} disabled={btnDisabled} onClick={() => {
          addNode('end')
        }}>End Node</button>
      </div>
      
    </React.Fragment>
  );
};

export default OverviewFlow;

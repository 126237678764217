import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import ReactDOM from 'react-dom/client';
import { render } from "react-dom"; 
import './scss/index.scss';
import App from './App';
import CampaignScheduler from './campaignScheduler';
import reportWebVitals from './reportWebVitals';
// import Drawflow from './drawflow';  // for Plain JS
// import Drawflow from './drawflow1';  // for React JS
import Drawflow from './drawflow4';  // for React JS
// import Drawflow from './drawflow2';  // for React JS with VJS clicks
import EmailTemplate from "./unlayer/index";
import PageNotFound from './PageNotFound';
import Onboarding from './pages/Onboarding/Onboarding';

import PageTimer from './timer';
import Exam from "./pages/Exam/Exam";

// import 'semantic-ui-css/semantic.min.css'
import Verification from './pages/Verification/Verification';
import Dashboard from './pages/Dashboard/Dashboard';
import Transaction from './pages/Transaction/Transaction';
import Thankyou from './pages/thankyou/Thankyou';
import Training from './pages/training/Training';
import TrainingChapters from './pages/training/TrainingChapters';
import { ImportExport } from '@mui/icons-material';
import LogsDashboard from './pages/LogsDashboard/LogsDashboard';
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Router>
//       <div className="App">
//         <div className="content">
//         <Switch>
//             <Route exact path="/flow/dripcampaign">
//               <App />
//             </Route>
//             <Route exact path="/flow/campaignscheduler">
//               <CampaignScheduler />
//             </Route>
//             <Route exact path="/flow/drawflow">
//               <Drawflow />
//             </Route>
//             <Route exact path="/flow/emailtemplate">
//               <EmailTemplate />
//             </Route>
//             <Route exact path="*">
//               <PageNotFound />
//             </Route>
//           </Switch>
//         </div>
//       </div>
//     </Router>
//     {/* <App /> */}
    
//   </React.StrictMode>);


  const root = document.getElementById("root");
  render(<React.StrictMode>
    <Router>
      <div className="App">
        <div className="content">
        <Switch>
            <Route exact path="/">
              <Dashboard />
            </Route>
            <Route exact path="/transaction">
              <Transaction />
            </Route>     
            <Route exact path="/flow/dripcampaign">
              <App />
            </Route>
            <Route exact path="/flow/campaignscheduler">
              <CampaignScheduler />
            </Route>
            <Route exact path="/flow/drawflow">
              <Drawflow />
            </Route>
            <Route exact path="/flow/emailtemplate/">
              <EmailTemplate />
            </Route>
            <Route exact path="/flow/emailtemplate/:tempId">
              <EmailTemplate />
            </Route>
            <Route exact path="/onboarding">
              <Onboarding />
            </Route>
            <Route exact path="/verification">
              <Verification/>
            </Route>
            <Route exact path="/training">
              <Training/>
            </Route>
            <Route exact path="/training/exam">
              <Exam />
            </Route>
            <Route exact path="/training/:courseUrl">
              <TrainingChapters />
            </Route>
            <Route exact path="/dashboard">
              <LogsDashboard/>
            </Route>
            <Route exact path="/dashboard/">
              <LogsDashboard/>
            </Route>

            
            {/* <Route exact path="/flow/timer">
              <PageTimer />
            </Route>              
              <Route exact path="/onboarding/thankyou">
              <Thankyou />
            </Route> */}
            {/* <Route exact path="/onboarding/thankyou">
              <Thankyou />
            </Route> */}
            <Route exact path="*">
              <PageNotFound />
            </Route>
            

            
          </Switch>
        </div>
      </div>
    </Router>
    {/* <App /> */}
    
  </React.StrictMode>, root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

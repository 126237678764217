import React from 'react'

const PageHeader = ({step,whichStep, setStep}) => {

 const handleStepChange = (whichStep,step)=> {
        // console.log("which step ====> ",whichStep,step);
        if(whichStep >= step){
            setStep(step)
        }
        // console.log("s===============================")
 }


  return (
            <div className="onboard-steps">
                <div className='steps-main'>


                    <div className="pdbk-title">
                        <h1>
                            <span>Registration</span>

                        </h1>
                    </div>

                    <div className='pd-steps'>
                        <div className="stepper-wrapper">
                            {/* <div className="stepper-item active"> */}
                            <div className={`stepper-item ${step >=1 ? "completed" : ""} `} onClick={()=>handleStepChange(whichStep,1)}>
                                <div className="step-counter"></div>
                                <div className= {`step-name ${step >=1 ? "active" : ""} `}>Member Details</div>
                            </div>
                            <div className={`stepper-item ${step >=2 ? "completed" : ""} `} onClick={()=>handleStepChange(whichStep,2)}>
                                <div className="step-counter"></div>
                                <div className={`step-name ${step >=2 ? "active" : ""}`}>Document Details</div>
                            </div>
                            <div className={`stepper-item ${step >=3 ? "completed" : ""} `} onClick={()=>handleStepChange(whichStep,3)}>
                                <div className="step-counter"></div>
                                <div className={`step-name ${step >=3 ? "active" : ""} `}>Bank Details</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default PageHeader
import React from 'react';
import "./LeftPanel.scss";

const LeftPanel = () => {
    return (
        <div className='sidemenu-wrapper'>
           <div className='left-menu'>
           <ul className="hov-lnk-cont">
                <li>
                    <div className='par-div'>
                        <div className='box'>
                            <div className='link-cont'>
                            <i className="fa fa-tachometer" aria-hidden="true"></i>
                                <div className=''>Dashboard</div>
                            </div>
                        </div>
                        <div className='box'>
                            <div className='link-cont'>
                            <i className="fa fa-handshake-o" aria-hidden="true"></i>
                                <div className=''>Training</div>
                            </div>
                        </div>
                        <div className='box'>
                            <div className='link-cont'>
                            <i className="fa fa-money" aria-hidden="true"></i>
                                <div className=''>Transaction</div>
                            </div>
                        </div>
                    </div>
                </li>
           </ul>
           </div>
        </div>
    );
}

export default LeftPanel;

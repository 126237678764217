import React from 'react';
import Button from '../../Button/Button';

import "./ConfirmSumitModal.scss";

const ConfirmSubmitModal = ({setModalOpen, setTimerRunning, setsureModalOpen, handleSubmit}) => {
    return (
        <div className="ConfirmStartModal">
            <div className={"ConfrimStartModal-Container"}>
              <div className="ConfirmModal-CloseBtn">
                <button
                  onClick={() => {
                    setsureModalOpen(false);   
                    //setTimerRunning(true);         
                    
                  }}
                >
                  X
                </button>
              </div>
              <div className="ModalBody-Sec">
                <div className="modalinc-item">
                  <img src="https://cdn.upsure.io/POS/common/confirm_exam_submit_inc.svg" alt="confirm icon" width="100%" height="100%" />
                </div>
                <div className="modal-title">Do you want to submit ?</div>
                
                <div className="yes-no-btnsec">
                  <div className='no-btn-item'>
                  <Button
                    label={"No"}
                    status={true}
                    isIcon={false}
                    onClick={() => {
                      setsureModalOpen(false);   
                      setTimerRunning(true);                  
                    }}
                    buttonType="proceed-btn"
                  />
                  </div>
                  <div className='yes-btn-item'>
                  <Button
                    label={"Yes"}
                    status={true}
                    isIcon={false}
                    onClick={() => {
                      setsureModalOpen(false); 
                      handleSubmit();   
                                        
                    }}
                    buttonType="proceed-btn"
                  /></div>
                </div>
              </div>
            </div>
          </div>
    );
}

export default ConfirmSubmitModal;

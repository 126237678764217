import React, { useState, useEffect } from 'react';

const PageTimer = () => {
  const [timerValue, setTimerValue] = useState(parseInt(localStorage.getItem('pageTimer')) || 0);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimerValue((prevValue) => {
        const newValue = prevValue + 1;
        localStorage.setItem('pageTimer', newValue);
        return newValue;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  };

  return (
    <div>
      <h1>Welcome to the Page Timer</h1>
      <p>Time spent on this page: <span>{formatTime(timerValue)}</span></p>
    </div>
  );
};

export default PageTimer;

import React, { useEffect, useState } from 'react'
import BankDetailsComponent from '../../components/onboarding/BankDetailsComponent'
import DocumentDetailsComponent from '../../components/onboarding/DocumentDetailsComponent'
import PersonalDetailsComponent from '../../components/onboarding/PersonalDetailsComponent'
import { getPartnerByQuery } from '../../components/onboarding/serviceApi'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import './Onboarding.scss';
import Header from '../../components/Header/Header'
// import PageLoader from '../../components/UI/pageLoader/PageLoader'
import PageLoader from '../../components/UI/PageLoader/PageLoader';

const Onboarding = () => {

const getCookie = (name) => {
    var nameEQ = name + "=";
    var cookiesArray = document.cookie.split(';');
    for (var i = 0; i < cookiesArray.length; i++) {
        var cookie = cookiesArray[i];
        while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        if (cookie.indexOf(nameEQ) == 0) {
            return cookie.substring(nameEQ.length, cookie.length);
        }
    }
    return null;
}


    const [showPersonalDetails, setShowPersonalDetails] = useState(false)
    const [showshowDocumentDetails, setShowshowDocumentDetails] = useState(false)
    const [showBankDetails, setshowBankDetails] = useState(false)
    const [counter,setCounter]=useState(0)
    const [whichStep,setWhichStep]=useState(0)
    const [agentUuid,setAgentUuid]=useState(null)  // need to get it from session storage
    const [agentMobileNumber,setAgentMobileNumber]=useState(null)  // need to get it from session storage
   
    const [loader,setLoader] = useState(true)
    // here we are changing the onboarding Process Step
    const navigate = useHistory()
    const setStep = (value) => {
        if (value === 1) {
            setShowPersonalDetails(true)
            setShowshowDocumentDetails(false)
            setshowBankDetails(false)
        } else if (value === 2) {
            setShowPersonalDetails(false)
            setShowshowDocumentDetails(true)
            setshowBankDetails(false)
        } else if (value === 3) {
            setShowPersonalDetails(false)
            setShowshowDocumentDetails(false)
            setshowBankDetails(true)
        }
    }



    const checkDocumentsError = (list) => {

        let result = false;
        list.map(each => {
            if (each.idType === "pan_card") {
                if (each.idNo === "" || each.idDocument1 === "") {
                    result = true
                }
            } else if (each.idType === "aadhar_card") {
                if (each.idNo === "" || each.idDocument1 === "" || each.idDocument2 === "") {
                    result = true
                }
            } else if (each.idType === "Highest_Qualification_Certificate") {
                if ( each.idDocument1 === "") {
                    // each.idNo === "" ||
                    result = true
                }
            } else if (each.idType === "Upload_Cancelled_Cheque/Passbook") {

                if (each.idNo === "" || each.idDocument1 === "") {
                    result = true
                }
            }
        })
        return result
    }

    const getPartnerDetails = (uuid) => {
        if (uuid) {
            getPartnerByQuery(uuid).then((response) => {
                if (response?.data?.data?.getPartnerByQuery?.hits?.total > 0 && response?.data?.data?.getPartnerByQuery?.hits?.hits?.length && response.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source) {
                    // let haveSource = response.data.data.getPartnerByQuery.hits.hits[0]
                    // step 1 details 
                    let partnerBasicDetails = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.basicDetails
                    let partnerQualificationDetails = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.qualifications && response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.qualifications[0]
                    // step 2 details 
                    let identityProofs = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.identityProofs
                    // step 3 details 
                    let partnerAccountDetails = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.accountDetails && response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.accountDetails[0]
                    // verification status 
                    let verificationStaus = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.verificationStatus
                    if (partnerBasicDetails === null || partnerBasicDetails === undefined || partnerQualificationDetails === null || partnerQualificationDetails === undefined || partnerBasicDetails.firstName === "" || partnerBasicDetails.gender === "" || partnerBasicDetails.dob === "" || partnerQualificationDetails.qualificationName === "" || partnerBasicDetails.location === "") {
                        setStep(1)
                        setLoader(false)
                        return
                    } else if (
                        identityProofs === null ||
                        identityProofs === undefined ||
                        checkDocumentsError(identityProofs)
                    ) {
                        setStep(2)
                        setLoader(false)
                        return
                    } else if (
                        partnerAccountDetails === null ||
                        partnerAccountDetails === undefined ||
                        partnerAccountDetails.bankName === "" ||
                        partnerAccountDetails.branchName === "" ||
                        partnerAccountDetails.accHolderName === "" ||
                        partnerAccountDetails.bankAccNumber === "" ||
                        partnerAccountDetails.ifscCode === ""
                    ) {
                        setStep(3)
                        setLoader(false)
                        return
                    } else if (verificationStaus === "VERIFICATION_PENDING") {
                        setLoader(false)
                        navigate.push("/verification")
                        return
                    }else if(verificationStaus === "VERIFICATION_DONE" && (response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus === null || response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "TRAINING_IN_PROGRESS")){
                         setLoader(false)
                        navigate.push("/verification")
                        return
                    }else if(verificationStaus === "VERIFICATION_DONE" && (response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus === null || response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "TRAINING_COMPLETED")){
                        setLoader(false)
                       navigate.push("/verification")
                       return
                   }else{
                    setStep(1)
                    setLoader(false) 
                    return
                   }
                } else {
                    setStep(1)
                    setLoader(false)
                    return
                }
            }).catch((error)=>{
               console.log("error",error)
               setLoader(false)
            })
        }
        else if( counter >= 1 && (uuid === "" || uuid === null || uuid === undefined) || (uuid && uuid.length <=0 )){
               
            // console.log("uuid else if",uuid)
            window.location.href = "https://angelone.upsure.io/health-insurance"
            setLoader(false)
        } 
    }

    useEffect(()=>{
        setAgentUuid(()=> getCookie("AgentUUID"))
        setAgentMobileNumber(()=> getCookie("AgentMobileNumber"))
        setCounter((prev)=>prev+1)
        // console.log("use effect uuid",agentUuid,counter);
        getPartnerDetails(agentUuid)
       
     },[agentUuid,agentMobileNumber])

    return (
        <div>
            <Header setStep={setStep}/>
            <div className="OnboardingPage">
                {
                    loader ? <PageLoader />: null
                }
                <div className="onboard-wpr">
                    {showPersonalDetails ? <PersonalDetailsComponent setStep={setStep} agentMobileNumber={agentMobileNumber} agentUuid={agentUuid}  setWhichStep={setWhichStep} whichStep={whichStep}/> : null}
                    {showshowDocumentDetails ? <DocumentDetailsComponent setStep={setStep} agentMobileNumber={agentMobileNumber} agentUuid={agentUuid} setWhichStep={setWhichStep} whichStep={whichStep}/> : null}
                    {showBankDetails ? <BankDetailsComponent setStep={setStep} agentMobileNumber={agentMobileNumber} agentUuid={agentUuid} setWhichStep={setWhichStep} whichStep={whichStep}/> : null}
                </div>
                <div className='clearfix'></div>
            </div>
        </div>
    )
}

export default Onboarding;

import React from "react";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";

export default function BasicSelect({options,onChange,label,placeholder,value}) {
  return (
    <Select
      fullWidth
      defaultValue={value ? value : ""}
      value={value ? value : ""}
      placeholder={placeholder}
      sx={{
        height: 50,
      }}
      onChange={(e,obj)=>onChange(e,obj)}
    >
      {
        options.map((option)=>{
           return <Option value={option.value} key={option.text}>{option.text}</Option>
        })
      }
    </Select>
  );
}

import axios from "axios";


const graphqlUrl = "https://api.upsure.io/graphql-api/graphql?";


// this Request help to get the Agent Data  | here Uuid is the agent uuid

const getPartnerByQuery = async (uuid) => {

    let configJson = {
        url: graphqlUrl,
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: {
          query: `{
                          getPartnerByQuery(
                            payload: {
                              query: {
                                bool: {
                                  must: {
                                    match: {
                                      key: "uuid"
                                      value: "${uuid}"
                                    }
                                  }
                                }
                              }
                            }
                          ) {
                            took
                            hits {
                              total
                              hits {
                                _source {
                                  metaData
                                  uuid
                                  mobile
                                  role
                                  channels
                                  basicDetails {
                                    firstName
                                    middleName
                                    lastName
                                    businessUserName
                                    dob
                                    email
                                    gender
                                    location
                                    profileImage
                                    term
                                    reasonOfInActive
                                    inActiveComment
                                  }
                                  address {
                                    addressType
                                    addressLine1
                                    addressLine2
                                    state
                                    area
                                    city
                                    pincode
                                    stateCode
                                    cityCode
                                  }
                                  channels
                                  stores {
                                    storeId
                                    storeName
                                  }
                                  partners {
                                    role
                                    partnerId
                                    partnerMobile
                                    partnerName
                                    partnerStatus
                                  }
                                  qualifications {
                                    qualificationName
                                    proofDocumentId
                                  }
                                  identityProofs {
                                    idType
                                    idNo
                                    idDocument1
                                    idDocument2
                                    idStatus
                                    validUpTo
                                  }
                                  partnerStatus
                                  verificationStatus
                                  validUpTo
                                  status
                                  learningStatus {
                                    trainingName
                                    courseName
                                    courseId
                                    status
                                    certificateLink
                                  }
                                  accountDetails {
                                    bankName
                                    branchName
                                    accHolderName
                                    bankAccNumber
                                    ifscCode
                                  }
                                  eventMetaData {
                                    createdBy
                                    createdByUserId
                                    addedDate
                                    updatedBy
                                    updatedByUserId
                                    updatedDate
                                  }
                                  examDetails {
                                    examId
                                    totalMarks
                                    obtMarks
                                    status
                                    passMark
                                    completedDate
                                    courseId
                                    courseName
                                    certificateLink
                                  }
                                  activities {
                                    channel
                                    levels
                                  }
                                  licenseStatus {
                                    uuid
                                    trainingCheck
                                    examinationCheck
                                    posSystemCheck
                                    reasonOfLicenseRejection
                                    renewed
                                    licenseStartDate
                                    licenseEndDate
                                    licenseStatus
                                    licenseNo
                                    licenseId
                                    licenseType
                                    courseId
                                    courseName
                                    licenseLink
                                  }
                                }
                              }
                            }
                          }
                        }
                        }`
        }
      };

     return axios(configJson)
   
}


// this api help to update the agent Data  | here payload may differ based up the step

const updatePartnerData = async (payload) => {
    const options = {
        url: graphqlUrl,
        method: "POST",
        data: {
          query: `
          mutation {

            updatePartner(
          
              payload: ${payload}
          
            ) {
          
              __typename
          
              code
          
              message
          
            }
          
          }
          
          `
        }
      }

    return axios(options)
}

const viewPartnerByQuery = async (uuid) => {
  let configJson = {
    url: graphqlUrl,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: {
      query: `{
        viewPartner(uuid: "${uuid}") {
          uuid
          mobile
          role
          basicDetails {
            firstName
            middleName
            lastName
            businessUserName
            dob
            email
            gender
            location
            profileImage
            term
            reasonOfInActive
            inActiveComment
          }
          address {
            addressType
            addressLine1
            addressLine2
            state
            area
            city
            pincode
            stateCode
            cityCode
          }
          channels
          stores {
            storeId
            storeName
          }
          partners {
            role
            partnerId
            partnerMobile
            partnerName
            partnerStatus
          }
          qualifications {
            qualificationName
            proofDocumentId
          }
          identityProofs {
            idType
            idNo
            idDocument1
            idDocument2
            idStatus
            validUpTo
          }
          partnerStatus
          verificationStatus
          validUpTo
          status
          learningStatus {
            trainingName
            courseName
            courseId
            status
            certificateLink
          }
          accountDetails {
            bankName
            branchName
            accHolderName
            bankAccNumber
            ifscCode
          }
          eventMetaData {
            createdBy
            createdByUserId
            addedDate
            updatedBy
            updatedByUserId
            updatedDate
          }
          examDetails {
            examId
            totalMarks
            obtMarks
            status
            passMark
            completedDate
            courseId
            courseName
            certificateLink
          }
          activities {
            channel
            levels
          }
          licenseStatus {
            uuid
            trainingCheck
            examinationCheck
            posSystemCheck
            reasonOfLicenseRejection
            renewed
            licenseStartDate
            licenseEndDate
            licenseStatus
            licenseNo
            licenseId
            licenseType
            courseId
            courseName
            licenseLink
          }
        }
      }
      `
    }
  };

  axios(configJson)
}



export  {getPartnerByQuery , updatePartnerData, viewPartnerByQuery}
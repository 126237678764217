import React, { useState } from 'react';
import './LogsDashboard.scss';
import Loader from './Loader.jsx';

const LogsDashboard = () => {
  const [orderId, setOrderId] = useState('');
  const [proposalNumber, setProposalNumber] = useState('');
  const [customText, setcustomText] = useState('');
  const [request, setRequest] = useState('');
  const [response, setResponse] = useState('');
  const [showLogs, setShowLogs] = useState(true);
  const [responseData, setResponseData] = useState(null); 
  const [loader, setLoader] = useState(false)
  const [erro, setErro] = useState(false)
  const [invaliderror , setinvaliderror] = useState(false)

  const getData = async(requestBody)=>{
    setLoader(true)
    const apiResponse = await fetch('https://channelpartner.insuranceinbox.in/v1/channelPartner/getSaveProposalJson', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await apiResponse.json();
      setRequest(requestBody);
      setResponse(responseData);
      if (responseData.message == "invalid referenceId/ProposalNumber"){
        //console.log("goinig in error",responseData.message)
        setinvaliderror(true)
      }
      else{
        setinvaliderror(false)
      }
    //  setRequest(JSON.stringify(requestBody, null, 2));
      //setResponse(JSON.stringify(responseData, null, 2));
      setShowLogs(true); // Set showLogs to true after API response
    

      console.log('API Response:', responseData);
      setResponseData(responseData) // Log the response to the console
      setLoader(false)

  }

  const handleGetLogs = async () => {
    
    try {
      let requestBody;

      if (orderId) {
        // If orderId is provided, send it as referenceId and set proposalNumber to an empty string
        requestBody = {
          referenceId: orderId.trim(),
          proposalNumber: '',
          "channel":["idfchealth","idfchealthprod","idfcsachet","idfcsachetprod","lifesavingprod","lifesaving"]
        };
        setErro(false)
        getData(requestBody)
      } else if (proposalNumber) {
        // If proposalNumber is provided, send it as proposalNumber and set referenceId to an empty string
        requestBody = {
          referenceId: '',
          proposalNumber: proposalNumber.trim(),
          "channel":["idfchealth","idfchealthprod","idfcsachet","idfcsachetprod","lifesavingprod","lifesaving"]
        };
        setErro(false)
        getData(requestBody)
      } else if (customText) {
        // If proposalNumber is provided, send it as proposalNumber and set referenceId to an empty string
        requestBody = {
          referenceId: '',
          proposalNumber: "",
          customText:customText.trim(),
          "channel":["idfchealth","idfchealthprod","idfcsachet","idfcsachetprod","lifesavingprod","lifesaving"]
        };
        setErro(false)
        getData(requestBody)
      } else {
        // If neither orderId nor proposalNumber is provided, handle it as needed
        console.error('Please enter either ReferenceId or Proposal Number');
        setErro(true)
        return;
      }

    //   const apiResponse = await fetch('https://channelpartner.insuranceinbox.in/v1/channelPartner/getSaveProposalJson', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(requestBody),
    //   });

    //   const responseData = await apiResponse.json();
    //   setRequest(requestBody);
    //   setResponse(responseData);
    // //  setRequest(JSON.stringify(requestBody, null, 2));
    //   //setResponse(JSON.stringify(responseData, null, 2));
    //   setShowLogs(true); // Set showLogs to true after API response

    //   console.log('API Response:', responseData);
    //   setResponseData(responseData) // Log the response to the console
    //   setLoader(false)
    } catch (error) {
      console.error('Error fetching logs:', error);
      setLoader(false)
    }
  };

  const objectIdToTimestamp = (objectId) => {
    // Convert hex string to 24-character hexadecimal number
    const hexTimestamp = objectId.substring(0, 8);
  
    // Convert hexadecimal to decimal
    const timestamp = parseInt(hexTimestamp, 16);
  
    // Create a Date object using the timestamp (multiply by 1000 to convert seconds to milliseconds)
    const dateObject = new Date(timestamp * 1000);
  
    // Format the date
    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const year = dateObject.getFullYear();
  
    // Format the time
    const hours = String(dateObject.getUTCHours()).padStart(2, '0');
    const minutes = String(dateObject.getUTCMinutes()).padStart(2, '0');
    const seconds = String(dateObject.getUTCSeconds()).padStart(2, '0');
  
    // Return the formatted date and time with '-' as the separator
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  


  const handleOrderIdChange = (e) => {
    setOrderId(e);
  };

  const handleProposalNumberChange = (e) => {
    setProposalNumber(e);
  };
  

  const handleCopy = (text) => {
    //navigator.clipboard.writeText(text);
    navigator.clipboard.writeText(JSON.stringify(JSON.parse(text), null, 2));

  };

  function replaceDoubleQuotes(inputString) {
    if (inputString){
        const replacedString = inputString.replace(/\\"/g, '"').replace(/\&lt;/g, '<').replace(/\&gt;/g, '>');
        return replacedString;

    }
    // Replace \" with "
   
  }

//   const SearchComponent = ({handleOrderIdChange,handleProposalNumberChange,orderId,proposalNumber}) => {

//     return   (
//         <>
//             <div > 
//           <label>
//             ReferenceId ID:
//             <input
//               type="text"
//               value={orderId}
//               onChange={(e)=>handleOrderIdChange(e.target.value)}
//             />
//           </label>
//           </div>
//           <p>or</p>
//           <div >
//           <label>
//             Proposal Number:
//             <input
//               type="text"
//               value={proposalNumber}
//               onChange={(e)=>handleProposalNumberChange(e.target.value)}
//             />
//           </label>
//           </div>
//           <div className='buttonsec'>
//           <button onClick={handleGetLogs}>Get Logs</button>
//           </div>
//         </>
//       );
//   }


  const LogsDisplayComponent = () => (
    <>
    {/* {console.log("responseData",responseData)} */}
    
      {responseData && responseData.payload &&  responseData.payload.map((entry, index) => (
       
        
        // <div key={index} className='Request-Flex'>
        //      {console.log("entry",entry)}
        //   <h3>Request Type: {entry.requestType}</h3>

        //   <div className='RequestItem'>
        //     <h4>Request:</h4>
        //     <textarea rows="15" value={replaceDoubleQuotes(entry.requestJson)} readOnly />
        //     {console.log("entry.requestJson",JSON.stringify(entry.requestJson))}
        //     <button onClick={() => handleCopy(replaceDoubleQuotes(entry.requestJson))}>Copy Request</button>
        //   </div>

        //   <div className='RequestItem'>
        //     <h4>Response:</h4>
        //     <textarea rows="15" value={replaceDoubleQuotes(entry.responseJson)} readOnly />
        //     {console.log("entry.requestJson",JSON.stringify(entry.requestJson))}
        //     <button onClick={() => handleCopy(replaceDoubleQuotes(entry.responseJson))}>
        //       Copy Response
        //     </button>
        //   </div>
        // </div>


        //console.log("entry",entry._id.$oid)


      

        (entry.requestJson || entry.responseJson )? <div key={index} className='LogsDisplayFlex'>
            <div className='LogsDisplayTitle'>Request Type: {entry.requestType} <span className='timeformat'>( Date and Time:- {objectIdToTimestamp(entry._id.$oid)} )</span></div>
           { entry.requestJson ? <div className='LogsDisplayItem'>
            <div className='form-field-items'>
            <div className='form-label'> Request</div>
            <div className='form-btn'>
                  {/* <button onClick={() => handleCopy(replaceDoubleQuotes(entry.requestJson))}>Copy Request</button> */}
                
                <img src='https://cdn.upsure.io/dashboard_log/copy.svg' onClick={() => handleCopy(replaceDoubleQuotes(entry.requestJson))}/>
                </div>
            </div>
            
           
            
                <div className='InputArea'>
                  {/* <textarea rows="15" value={replaceDoubleQuotes(entry.requestJson)} readOnly></textarea> */}
                  <input type="text"   value={replaceDoubleQuotes(entry.requestJson)} readOnly />
                </div>
                
              </div> : null}
            {entry.responseJson ?  <div className='LogsDisplayItem'>
            <div className='form-field-items'>
            <div className='form-label'>Response</div>
            <div className='form-btn'>
                  {/* <button onClick={() => handleCopy(replaceDoubleQuotes(entry.responseJson))}>Copy Response</button> */}
                
                  <img src='https://cdn.upsure.io/dashboard_log/copy.svg' onClick={() => handleCopy(replaceDoubleQuotes(entry.responseJson))} />
                
                </div>
            </div>
            
                <div className='InputArea'>
                  {/* <textarea rows="1" value={replaceDoubleQuotes(entry.responseJson)} readOnly></textarea> */}
                  <input rows="1" type="text" value={replaceDoubleQuotes(entry.responseJson)} readOnly />
                </div>
                
                </div>: null}
        </div> : null




      ))}
    </>
  );



  return (
    <div className="wrapper">
      <div className="DahsboardMain">
        <h2>Dashboard</h2>
        <div className='FeildItem'> 
          <label>
            ReferenceId ID:
          </label>
            <input
              type="text"
              value={orderId}
              onChange={(e)=>setOrderId(e.target.value)}
            />
            {erro ? <div className='errormsg'>Cannot be empty</div>:""}
          </div>
          <div className='ortxt'>OR</div>
          <div className='FeildItem'> 
          <label>
            Proposal Number:
          </label>
            <input
              type="text"
              value={proposalNumber}
              onChange={(e)=>setProposalNumber(e.target.value)}
            />
            {erro ? <div className='errormsg'>Cannot be empty</div>:""}
          </div>
          <div className='ortxt'>OR</div>
          <div className='FeildItem'> 
          <label>
          Bankcustomer Id/ mobile/ email
          </label>
            <input
              type="text"
              value={customText}
              onChange={(e)=>setcustomText(e.target.value)}
            />
            {erro ? <div className='errormsg'>Cannot be empty</div>:""}
          </div>
          <div className='buttonsec'>
            { loader ? "loading":  <button onClick={handleGetLogs}>Get Logs</button>

            }
         
          </div>
        
      </div>
      {loader?<Loader/>: invaliderror ?  <div className='display-error'>
          TryAgain invalid referenceId/ProposalNumber
          </div> :  showLogs && <LogsDisplayComponent />}
    </div>
  );
};

export default LogsDashboard;

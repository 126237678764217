import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Header from "../../components/Header/Header";
import AllIcons from "../../Utility/IconLinks";
import Button from "../../components/Button/Button";
import "./Verification.scss";
import axios from "axios";


const VerificationPending = ({Status}) => {
  const [graphqlUrl] = useState("https://api.upsure.io/graphql-api/graphql?");
  const navigate = useHistory();
  const [courseStart, setCourseStart] = useState(false)
  const [agentUuid,setAgentUuid]=useState(null)

  const [showButton,setShowButton] = useState(false)
  const [agentMobileNumber, setAgentMobileNumber] = useState("");



  const getCookie = (name) => {
    var nameEQ = name + "=";
    var cookiesArray = document.cookie.split(";");
    for (var i = 0; i < cookiesArray.length; i++) {
      var cookie = cookiesArray[i];
      while (cookie.charAt(0) == " ") {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) == 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  };


  const getCourseTimeSpent = async (uuid) => {
    let configJson = {
      url: graphqlUrl,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: {
        query: `{
            getCourseScreeningDetailsByUUID(
              uuid: "${uuid}"
            ) {
              __typename
              payload {
                __typename
                lession_id
                uuid
                course_id
                training_id
                training_name
                total
              }
            }
        }`,
      },
    };
    return axios(configJson);
  };

  const redirectionUrl = () => {
     if(Status == "Training" || Status == "Examination"){
         navigate.push("/training")
         setShowButton(true)
     }else if(Status === "licience_issued"){
       window.location.href = `https://angelone.upsure.io/health-insurance?channel=angelhealthuat&mobile=${agentMobileNumber}`
     }else {
         console.log("need to decide where to redirect")
     }
  }  

  useEffect(()=>{
    setAgentMobileNumber(()=>getCookie("AgentMobileNumber")) 
    setAgentUuid(()=> getCookie("AgentUUID"))
    if(agentUuid){
      // console.log("goin in ",agentUuid)
      getCourseTimeSpent(agentUuid).then((res) => {
        if (res.data.data.getCourseScreeningDetailsByUUID.payload[0].total > 0){
          // console.log("goin in time")
          setCourseStart(true)

        }
        
      })
    }
  },[agentUuid])

  return (
    <div className="verification-main">
    <Header />
    <div className="verifction-inner">
      <div className="status-img">
        <img
          src="https://cdn.upsure.io/POS/common/verification_status_img.svg"
          alt="Status Image"
          width="100%"
          height="100%"
        />
      </div> {
        Status == "licience_issued" ?
      <h1 className="status-msg">Status : License Issued</h1>
        : 
      <h1 className="status-msg">Status : {(courseStart && Status == "Training") ? "Training in Progress": (`${Status}  Pending`) }</h1>
      

      }
      <h2 className="thanksmsg">
        Thank you for choosing
        <br />
        to join our team of dedicated insurance professionals.
      </h2>
      {
        Status == "Verification"  ? 
        <div className="process-con">
        <h3 className="process-title">What is the process of onboarding?</h3>
        <ol className="listitm">
          <li>
            <strong>Verification Process:</strong> Our team will now begin the
            verification process to ensure that all the information provided
            aligns with our requirements and industry standards.
          </li>
          <li>
            <strong>Confirmation Communication:</strong> Once the verification
            process is complete, you will receive a confirmation sms/ email
            notifying you of the status of your application.
          </li>
          <li>
            <strong>Training:</strong> Upon successful verification, you will
            gain access to our comprehensive learning material designed to
            empower you in your role as an insurance agent with us.
          </li>
        </ol>
        <h4 className="needassist-title">Need Assistance?</h4>
        <p className="ifqust-text">
          If you have any questions or encounter any challenges during your
          training, our support team is ready to assist you.
        </p>
         </div>
        : null
      } 
      {
          (Status == "Training" || Status == "Examination" ) ? 
          <div className="process-con">
          <h3 className="process-title">What are the upcoming procedures?</h3>
          <ol className="listitm">
            <li>
              <strong>Training Process:</strong> Begin your learning journey by completing the required 15 hours of mandatory training using our learning tools.
            </li>
            <li>
              <strong>Examination:</strong>Following the completion of the learning process, you will be required to take an online exam and successfully qualify.
            </li>
            <li>
              <strong>Licensing:</strong> Upon successful qualification in the exam, you will proceed to the licensing phase.
            </li>
          </ol>
          <h4 className="needassist-title">Need Assistance?</h4>
          <p className="ifqust-text">
          Require Help? If you have any inquiries or face any challenges during your training, our support team is prepared to assist you.
          </p>
           </div>
          : null
      }
      {
          Status == "License"  ? 
          <div className="process-con">
          <h3 className="process-title">What are the next steps in the process?</h3>
          <ol className="listitm">
            <li>
              <strong>Licensing Procedure:</strong>Our team will initiate the licensing procedure to verify that the information submitted complies with regulatory mandates and industry benchmarks.
            </li>
            <li>
              <strong>Confirmation Notification:</strong>Following the completion of the licensing process, you will be sent a confirmation SMS/email informing you of your application status.
            </li>
            <li>
              <strong>Commence Sales:</strong> Upon successful verification, you will be granted access to the sales platform, allowing you to embark on your sales endeavors.
            </li>
          </ol>
          <h4 className="needassist-title">Need Assistance?</h4>
          <p className="ifqust-text">
          Require Help? If you have any inquiries or face any difficulties throughout the process, our support team is prepared to aid you.
          </p>
           </div>
          : null
      }


{
          Status == "licience_issued"  ? 
          <div className="process-con">
          <h3 className="process-title">License got issued, please proceed with sales journey.</h3>
          {/* <ol className="listitm">
            <li>
              <strong>Licensing Procedure:</strong>Our team will initiate the licensing procedure to verify that the information submitted complies with regulatory mandates and industry benchmarks.
            </li>
            <li>
              <strong>Confirmation Notification:</strong>Following the completion of the licensing process, you will be sent a confirmation SMS/email informing you of your application status.
            </li>
            <li>
              <strong>Commence Sales:</strong> Upon successful verification, you will be granted access to the sales platform, allowing you to embark on your sales endeavors.
            </li>
          </ol> */}
          <h4 className="needassist-title">Need Assistance?</h4>
          <p className="ifqust-text">
          Require Help? If you have any inquiries or face any difficulties throughout the process, our support team is prepared to aid you.
          </p>
           </div>
          : null
      }
      
      <div className="btnsec">
        {
          (Status == "Training" || Status == "Examination" || Status == "licience_issued") ? 
          <Button
          label={Status == "licience_issued" ? "Go to Sales " : Status == "Examination" ? "Start Examination": "Go to Training"}
          status={true}
          isIcon={true}
          img={AllIcons.tickIcon}
          onClick={()=> 
            redirectionUrl()
            // console.log("need to redirect to training page")
          }
          buttonType="proceed-btn"
        /> : null
        }
        
      </div>
    </div>
    </div>
  )
}

export default VerificationPending
import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const FileUpload = ({onChange}) => {
    return (
        <Button component="label" variant="contained" >
      Browse
      <input type="file" onChange={onChange} hidden/>
    </Button>
    );
}

export default FileUpload;

import React from "react";
import "./CheckBox.scss";

const CheckBox = ({checked,onChange}) => {
  return (
    <div className="chk-container">
      <label className="container">
        <span className="chk-txt">Yes</span>
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};

export default CheckBox;

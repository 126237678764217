import React from "react";
import Header from "../../components/Header/Header";
import LeftPanel from "../../components/LeftPanel/LeftPanel";
import PageHeading from "../../components/PageHeading/PageHeading";
import CustomTabPanel from "../../components/CustomTabPanel/CustomTabPanel";


import "./Transaction.scss";

const Transaction = () => {
  return (
    <div className="page-main">
      <Header />
      <LeftPanel />
      <div className="innerbody">
        <div className="body-con">
            <PageHeading IconName={`fa fa-tachometer`} PageName={"My Transaction"}
              SubText={"Searchable log of all your transactions where you can search for real-time data and status of all quotes generated for follow-up and sales pitch."}
            />
            <div className="pagecont">
                <CustomTabPanel />
            </div>
        </div>
      </div>
    </div>
  );
};

export default Transaction;

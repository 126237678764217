import React from "react";
// import Button from "../UI/Button/Button";
import Button from "../../components/Button/Button";
import AllIcons from "../../Utility/IconLinks";

import "./PageHeading.scss";

const PageHeading = ({ IconName, IconImage, PageName, SubText }) => {
  return (
    <div className="pagehead-flx">
      <div className="pagehead-lft-item">
        <div className="pageheadinc-itm">
          <i className={IconName}></i>
          <img src={IconImage} alt="Icon" />
        </div>
        <div className="pageheadtxt-item">
          <h1 className="hdtxt">{PageName}</h1>
          <p className="sub-hdtxt">{SubText}</p>
        </div>
      </div>
      <div className="back-btn-item">
        <Button
          label={"Back"}
          status={false}
          isIcon={true}
          img={AllIcons.BackIcon}
          // clickFunction={backButtonHandler}
          buttonType="back-btn"
        />
      </div>
    </div>
  );
};

export default PageHeading;

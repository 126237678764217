import { useCallback, useEffect, useState, React } from 'react';
import ReactDOM from 'react-dom'
import axios from 'axios';

import "./Modal.css";

function Modal({ setOpenModal, type }) {
  const [productName, setProductName] = useState("");
  const [isNew, setIsNew] = useState(true); // campaignId.startsWith("new") check weather campaign Id starting with new or not

  // static COMPONENT_MAIN = 0;
  // static COMPONENT_NEW_SEGMENT = 1;

const [template, setTemplate] = useState("");
const [aName, setAName] = useState('');
const [edit, setEdit] = useState(false);
const [showCreateFeedTemplate, setShowCreateFeedTemplate] = useState(false);
const [aId, setAid] = useState('');
const [feedList, setFeedList] = useState([]);
const [totalRuleData, setTotalRuleData] = useState([]);
const [imageName, setImageName] = useState('');
const [imgErr, setImgErr] = useState('');
const [imgUrl, setImgUrl] = useState('');
const [file, setFile] = useState('');
const [imagePreviewUrl, setImagePreviewUrl] = useState('');
const [customerUrl, setCustomerUrl] = useState('');
const [segmentRules, setSegmentRules] = useState([]);
const [role, setRole] = useState('');
const [allUsers, setAllUsers] = useState('');
const [rulesData, setRulesData] = useState([]);
const [segmentNameValue, setSegmentNameValue] = useState('');
const [classNameValue, setClassNameValue] = useState('');
const [feedType, setFeedType] = useState('');
const [feedNameValue, setFeedNameValue] = useState('');
const [tab, setTab] = useState("template");   // currentTab,  "template", need to take this from the URL
const [roleType, setRoleType] = useState('customer');
const [loading, setLoading] = useState(false);
const [deviceType, setDeviceType] = useState('');
const [deviceTypeList, setDeviceTypeList] = useState([]);
const [campaignMode, setCampaignMode] = useState('');
const [startDate, setStartDate] = useState('');
const [updateDate, setUpdateDate] = useState('');
const [campaignName, setCampaignName] = useState('');
const [campaignChannel, setCampaignChannel] = useState('');
const [campaignDesc, setCampaignDesc] = useState('');
const [campaignMarketingHeadline, setCampaignMarketingHeadline] = useState('');
const [campaignUploadFile, setCampaignUploadFile] = useState('');
const [campaignMarketingDescription, setCampaignMarketingDescription] = useState('');
const [scheduleMode, setScheduleMode] = useState('');
const [onetimeMode, setOnetimeMode] = useState('');
const [scheduleRepeat, setScheduleRepeat] = useState('');
const [scheduleRepeatEvery, setScheduleRepeatEvery] = useState('');
const [scheduleStartOn, setScheduleStartOn] = useState('');
const [scheduleStartOnTime, setScheduleStartOnTime] = useState("09:00");
const [scheduleEndOn, setScheduleEndOn] = useState('');
const [scheduleEndOnDate, setScheduleEndOnDate] = useState('');
const [channelList, setChannelList] = useState([]);
const [allTemplatesList, setAllTemplatesList] = useState([]);
const [ameyoCampaignsList, setAmeyoCampaignsList] = useState([]);
const [selectedTemplate, setSelectedTemplate] = useState('');
const [selectedFeed, setSelectedFeed] = useState('');
const [templateVariablesList, setTemplateVariablesList] = useState([]);
const [segmentsList, setSegmentsList] = useState([]);
const [filteredSegmentList, setFilteredSegmentList] = useState([]);
const [segmentFilterQuery, setSegmentFilterQuery] = useState('');
const [ameyoCampaignId, setAmeyoCampaignId] = useState('');
const [ameyoCampaignName, setAmeyoCampaignName] = useState('');
const [ameyoCampaignLeadId, setAmeyoCampaignLeadId] = useState('');
const [segmentName, setSegmentName] = useState('');
const [segmentId, setSegmentId] = useState('');
const [NoOfUsers, setNoOfUsers] = useState('');
const [lastRefreshedAt, setLastRefreshedAt] = useState('');
const [selectedSegments, setSelectedSegments] = useState([]);
const [showBasicDetailsErrors, setShowBasicDetailsErrors] = useState(false);
const [showTemplateError, setShowTemplateError] = useState(false);
const [showSegmentError, setShowSegmentError] = useState(false);
const [showModeError, setShowModeError] = useState(false);
const [currentComponent, setCurrentComponent] = useState(0); // CampaignConfigParent.COMPONENT_MAIN
const [added_by_userId, setAdded_by_userId] = useState('');
const [updated_by_userId, setUpdated_by_userId] = useState('');
const [rolesOptions, setRolesOptions] = useState([]);
const [rolesType, setRolesType] = useState('');
const [uploadimages, setUploadimages] = useState('');
const [previewShow, setPreviewShow] = useState(false);
const [uploadNote, setUploadNote] = useState('');
const [totalFeedList, setTotalFeedList] = useState([]);
const [showErrors, setShowErrors] = useState(false);
const [modalOpen, setModalOpen] = useState(false);

const onChange = useCallback((evt) => {
  console.log(evt.target.value);
}, []);

const handleChange = (val) => {
  console.log("Value selected ===>>", val);
  setTemplate(val)
}

function gql(query) {
  return axios({
      url: window.links.insuranceApi + '/graphql-api/graphql?',
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: {
          query: query
      }
  });
}

function getDeviceType(payload) {
return gql(` {  
          getChannelValueLists(channel:"default",listName:"${payload}")
          }`
)
}

const handleChangeSchedule = (key, value) => {
  // console.log([key],value,'changeggegeg')
   if(key == 'roleType'){
       setRoleType(value)
       setSelectedSegments([])

      //  setState({[key]:value})
      //  setState({selectedSegments:[]})
   }else if(key === "ameyoCampaignId") {
      setAmeyoCampaignId(value)
      setAmeyoCampaignLeadId("")
      setAmeyoCampaignName("")

      //  setState({[key]: value, ameyoCampaignName: "", ameyoCampaignLeadId: ""});
   }
   else if(key === 'deviceType'){
       if(value == 'CustomerApp')
       {
          setUploadNote("Resolution should be : 1080 X 790")
          //  setState({uploadNote:"Resolution should be : 1080 X 790"})
       } else {
          setUploadNote("Resolution should be : 1080 X 619")
          //  setState({uploadNote:"Resolution should be : 1080 X 619"})
       }
      //  "set"+`${[key]}(${value})`
      //  setState({[key]:value}) ==================================================
       getDeviceType(value).then(res=>{
  //         console.log(res,'response----------->response')
           let dataList=[{text:'Select',value:''}];
           let data=res.data.data.getChannelValueLists[0].listItems
           data.map(index=>{
               dataList.push({text:index,value:index})
           })
           setDeviceTypeList(dataList)
          //  setState({deviceTypeList:dataList})
       })
   }
   else {
      // "set"+`${[key]}(${value})`
      //  setState({[key]: value}); ================================================
       if(scheduleMode == 'recurring'){
          setOnetimeMode("")
          // setState({onetimeMode:''});
       }
   }
};

const setTabSwitch = (tab) => {
  //currentTab = tab;
  if (window.history.replaceState) {
      let url = window.location.protocol + "//" +
          window.location.host +
          window.location.pathname.substr(0, window.location.pathname.lastIndexOf("/")) +
          '/' + tab;
      window.history.replaceState({path: url}, '', url);
  }
  setTab(tab)
  // this.setState({tab: tab});
};

const createCampaign = (data) => {
  console.log("Clicked here ====>", data);
  // if(!this.state.campaignMode) {
  //     this.setState({showModeError:true});
  //     this.setTab('mode');
  //     return false;
  // }else{
  //     this.setState({showModeError:false});
  // }

  // if(this.state.campaignMode != 'feed'){

  //     if(!this.state.scheduleMode) {
  //         this.setState({showErrors:true})
  //         return false
  //     } else {
  //         if(this.state.scheduleMode === "oneTime") {
  //             if(!this.state.scheduleMode){
  //                 this.setState({showErrors:true})
  //                 return false
  //             }
  //             if(this.state.onetimeMode == ''){
  //                 this.setState({showErrors:true})
  //                 return false
  //             }

  //             if(this.state.onetimeMode == 'scheduledelivery') {
  //                 if (this.state.scheduleStartOnTime == '' || this.state.scheduleStartOn == '' || this.state.scheduleEndOnDate == '') {
  //                     this.setState({showErrors: true});
  //                     return false;
  //                 }
  //             }
  //         } else {   // recurring
  //             if(!this.state.scheduleRepeat) {
  //                 this.setState({showErrors:true})
  //                 return false
  //             } else {
  //                 if(this.state.scheduleRepeat !== "daily"  && this.state.scheduleRepeat !== "monthly" && !this.state.scheduleRepeatEvery) {
  //                     this.setState({showErrors:true})
  //                     return false
  //                 } // else no error

  //                 if(this.state.scheduleStartOn == '' || this.state.scheduleEndOnDate == '' || this.state.scheduleStartOnTime == ''){
  //                     this.setState({showErrors:true})
  //                     return false
  //                 }
  //             }

  //         }
  //     }
  // }else{
  //     if(!this.state.scheduleMode) {
  //         this.setState({showErrors:true})
  //         return false
  //     } else {
  //         if(this.state.scheduleMode == "oneTime") {
  //             if(!this.state.onetimeMode){
  //                 this.setState({showErrors:true})
  //                 return false
  //             }

  //             if(this.state.onetimeMode == ''){
  //                 this.setState({showErrors:true})
  //                 return false
  //             }

  //             if(this.state.onetimeMode == 'scheduledelivery') {
  //                 if (this.state.scheduleStartOnTime == '' || this.state.scheduleStartOn == '' || this.state.scheduleEndOnDate == '') {
  //                     this.setState({showErrors: true});
  //                     return false;
  //                 }
  //             }


  //         } else {   // recurring
  //             if(!this.state.scheduleRepeat) {
  //                 this.setState({showErrors:true})
  //                 return false
  //             } else {
  //                 if(this.state.scheduleRepeat !== "daily" && this.state.scheduleRepeat !== "monthly" && !this.state.scheduleRepeatEvery) {
  //                     this.setState({showErrors:true})
  //                     return false
  //                 }

  //                 if(this.state.scheduleStartOn == '' || this.state.scheduleEndOnDate == '' || this.state.scheduleStartOnTime == ''){
  //                     this.setState({showErrors:true})
  //                     return false
  //                 }
  //             }
  //         }
  //     }
  // }
  // this.setState({showErrors:false});




  // if(this.state.campaignMode !== "feed"){
  //     if(!this.state.campaignName ||
  //         !this.state.campaignDesc
  //         /*!this.state.campaignMarketingHeadline ||
  //         !this.state.campaignMarketingDescription ||
  //         !this.state.campaignChannel ||*/
  //     ) {
  //         this.setState({showBasicDetailsErrors: true});
  //         this.setTab('basic');
  //         return false
  //     }
  //     if(!this.state.selectedSegments.length) {
  //         this.setState({showSegmentError:true});
  //         this.setTab('segment');
  //         return false;
  //     }

  //     if(this.state.campaignMode === "Call") {
  //         if( !this.state.ameyoCampaignName || !this.state.ameyoCampaignLeadId){
  //             this.setState({showTemplateError: true});
  //             this.setTab('template');
  //             return false
  //         }
  //     } else {
  //         if(!this.state.selectedTemplate){
  //             this.setState({showTemplateError: true});
  //             this.setTab('template');
  //             return false
  //         }
  //     }
  // }else{
  //     if(!this.state.campaignName ||
  //         !this.state.campaignDesc
  //     ) {
  //         this.setState({showBasicDetailsErrors: true});
  //         this.setTab('basic');
  //         return false
  //     }
  //     if(!this.state.selectedSegments.length) {
  //         this.setState({showSegmentError:true});
  //         this.setTab('segment');
  //         return false;
  //     }
  //     /*if(this.state.campaignMode === "feed") {
  //         if( !this.state.selectedFeed){
  //             this.setState({showTemplateError: true});
  //             this.setTab('template');
  //             return false
  //         }
  //     }*/
  // }

  // /*this.setState({showSegmentError:true})*/


  // let totalRuleData=[]
  // this.state.selectedSegments.map(index=> {
  //     getActivitySegment(index.segment_id).then(res => {

  //         let response = res.data.data.getActivitySegment.payload
  //         //let rulesData = [];
  //         let userId = '';
  //         let user = '';

  //         response.rules.map(indexRes => {
  //             /*let rules = {
  //                 "parameter": indexRes.parameter,
  //                 "range": indexRes.range,
  //                 "range_value": indexRes.range_value
  //             }*/
  //             //rulesData.push(rules);
  //             userId = indexRes.added_by_userId;
  //             user = indexRes.added_by;
  //         })
  //         let a = {
  //             "segment_id": response.segment_id,
  //             "segment_name": response.segment_name,
  //             /* "rules":rulesData,*/
  //             "added_by": user,
  //             "added_by_userId": userId
  //         }
  //         totalRuleData.push(a);

  //         if(this.state.selectedSegments.length == totalRuleData.length){
  //             this.setState({totalRuleData: totalRuleData},()=>{


  //                 if(this.state.campaignMode !== 'feed'){
  //                     let input = {
  //                         "campaign_name": this.state.campaignName,
  //                         "campaign_description": this.state.campaignDesc,
  //                         "marketing_headline": this.state.campaignMarketingHeadline,
  //                         "marketing_description": this.state.campaignMarketingDescription,
  //                         "marketing_image": this.state.campaignUploadFile,
  //                         "mode": this.state.campaignMode,
  //                         "channel":[this.state.campaignChannel],
  //                         "segments":this.state.totalRuleData,
  //                         "schedule_templates":[{
  //                             "schedule_type": (this.state.scheduleMode == "oneTime")? "once": this.state.scheduleRepeat,
  //                             "start_date": this.state.onetimeMode == 'instant'? moment(new Date()).format('DD-MM-YYYY'):this.state.scheduleStartOn,
  //                             "end_date":this.state.onetimeMode == 'instant' ?moment(new Date()).format('DD-MM-YYYY'):this.state.scheduleEndOnDate,
  //                             "time": this.state.onetimeMode != 'instant' ?this.state.scheduleStartOnTime:moment(new Date()).format("hh:mm").toString(),
  //                             "template_name": "template_name",
  //                             "template_id":this.state.selectedTemplate,
  //                             "dates_list": this.getDatesArray()
  //                         }],
  //                         "metadata":{
  //                             "leadId":this.state.ameyoCampaignLeadId,
  //                             "campaignId":this.state.ameyoCampaignId,
  //                             "campaignName":this.state.ameyoCampaignName,
  //                             "schedule_templates": [{
  //                                 "schedule_repeat": this.state.scheduleRepeat,
  //                                 "schedule_repeat_every": this.state.scheduleRepeatEvery,
  //                                 "schedule_mode": this.state.scheduleMode
  //                             }]
  //                         },
  //                         "added_by":"Admin",
  //                         "added_by_userId": this.state.added_by_userId,
  //                     };

  //                     // console.log(input,'input******');




  //                     if(!this.isNew) {
  //                         this.getUuidFromJwt().then((responseUuid)=> {
  //                             if (responseUuid.data.code === 0) {
  //                                 input.updated_by = "Admin";
  //                                 input.updated_by_userId = responseUuid.data.uuid;

  //                                 updateCampaign(campaignId, input).then(responseCamp => {
  //                                     if(responseCamp.code === 0) {
  //                                         window.location = "/adminportal/campaigns/list";
  //                                     } else {
  //                                         console.error(responseCamp.message);
  //                                     }
  //                                 }).catch(e => {
  //                                     console.error(e);
  //                                 });
  //                             } else {
  //                                 console.error(responseUuid.data);
  //                             }
  //                         }).catch(error => {
  //                             console.error(error);
  //                         });
  //                     } else {
  //                         this.getUuidFromJwt().then((responseUuid2)=> {
  //                             if (responseUuid2.data.code === 0) {
  //                                 input.added_by_userId = responseUuid2.data.uuid;

  //                                 createCampaign(input).then(responseCampCreate => {
  //                                     if(responseCampCreate.code === 0) {
  //                                         window.location = "/adminportal/campaigns/list";
  //                                     } else {
  //                                         console.error(responseCampCreate.message);
  //                                     }
  //                                 }).catch(e => {
  //                                     console.error(e);
  //                                 });
  //                             } else {
  //                                 console.error(responseUuid2.data);
  //                             }
  //                         }).catch(error => {
  //                             console.error(error);
  //                         });
  //                     }
  //                 }
  //                 if(this.state.campaignMode === 'feed') {


  //                     //console.log(this.state.onetimeMode,'onetimeMode---->onetimeMode')
  //                     //return false;

  //                     let input={
  //                         "campaign_name": this.state.campaignName,
  //                         "campaign_description": this.state.campaignDesc,
  //                         "mode": this.state.campaignMode,
  //                         "channel":[],
  //                         "segments":this.state.totalRuleData,
  //                         "schedule_templates":[{
  //                             "schedule_type": this.state.scheduleMode  == 'oneTime' ? this.state.onetimeMode : this.state.scheduleRepeat,
  //                             "start_date":  this.state.scheduleMode  == 'oneTime' ? this.state.onetimeMode != 'instant'? this.state.scheduleStartOn : moment(new Date()).format('DD-MM-YYYY') : this.state.scheduleStartOn ,
  //                             "end_date":this.state.scheduleMode  == 'oneTime' ? this.state.onetimeMode != 'instant' ? this.state.scheduleEndOnDate : moment(new Date()).format('DD-MM-YYYY') : this.state.scheduleEndOnDate,
  //                             "time": this.state.scheduleMode  == 'oneTime' ? this.state.onetimeMode != 'instant' ?this.state.scheduleStartOnTime:moment(new Date()).format('hh:mm').toString() : this.state.scheduleStartOnTime ,
  //                             "template_name": this.state.aName,
  //                             "template_id":this.state.aId,
  //                             "dates_list":this.getDatesArray()
  //                         }],
  //                         "added_by":"ADMIN",
  //                         "metadata":{
  //                             "schedule_templates": [{
  //                                 "schedule_repeat": this.state.scheduleRepeat,
  //                                 "schedule_repeat_every": this.state.scheduleRepeatEvery,
  //                                 "schedule_mode": this.state.scheduleMode
  //                             }]
  //                         },

  //                     }


  //                     if(!this.isNew) {
  //                         this.getUuidFromJwt().then((responseEdit)=> {
  //                             if (responseEdit.data.code === 0) {
  //                                 input.updated_by = "Admin";
  //                                 input.updated_by_userId = responseEdit.data.uuid;


  //                                 updateCampaign(campaignId, input).then(responseUpdateCamp => {
  //                                     if(responseUpdateCamp.code === 0) {
  //                                         window.location = "/adminportal/campaigns/list";
  //                                     } else {
  //                                         console.error(responseUpdateCamp.message);
  //                                     }
  //                                 }).catch(e => {
  //                                     console.error(e);
  //                                 });
  //                             } else {
  //                                 console.error(responseEdit.data);
  //                             }
  //                         }).catch(error => {
  //                             console.error(error);
  //                         });
  //                     } else {
  //                         this.getUuidFromJwt().then((responseJwt)=> {
  //                             if (responseJwt.data.code === 0) {
  //                                 input.added_by_userId = responseJwt.data.uuid;
  //                                 createCampaign(input).then(responseCreate2 => {
  //                                     if(responseCreate2.code === 0) {
  //                                         window.location = "/adminportal/campaigns/list";
  //                                     } else {
  //                                         console.error(responseCreate2.message);
  //                                     }
  //                                 }).catch(e => {
  //                                     console.error(e);
  //                                 });
  //                             } else {
  //                                 console.error(responseJwt.data);
  //                             }
  //                         }).catch(error => {
  //                             console.error(error);
  //                         });
  //                     }


  //                 }
  //             });
  //         }
  //     })
  // })
}
  return (
    <div className="modalBackground">
      <div className={"modalContainer" + " " + type}>
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        {/* <div className="title">
          <h1>Are You Sure You Want to Continue?</h1>
        </div>
        <div className="body">
          <p>The next page looks amazing. Hope you want to go there!</p>
        </div>
        <div className="footer">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn"
          >
            Cancel
          </button>
          <button>Continue</button>
        </div> */}
        {type ? null : <h5 className="jiva-sub-title  text-capitalize">Campaign Template</h5>}
        {type ? null : <div className="selectdropdown">
        <label htmlFor="text">Select Template:</label>
          <select value={template}
                  onChange={(e) => handleChange('template', e.target.value)}
                  >
              {
              // this.state.parameterList
              [
                {
                    template_id: 1,
                    template_mode: "SMS",
                    template_name: "Thank You",
                    template_body: "Dear ${FIRST_NAME} Thank you for being our valued customer. We are grateful for the pleasure of serving you and meeting your insurance needs. Warm wishes!",
                    template_subject: "Wishing",
                },
                {
                    template_id: 2,
                    template_mode: "SMS",
                    template_name: "Cross Sell",
                    template_body: "Hey ${FIRST_NAME}. Check out our wide range of health insurance plans for your family from over 20+ options. Visit www.insuranceinbox.com",
                    template_subject: "Cross Sell",
                },
                {
                    template_id: 3,
                    template_mode: "SMS",
                    template_name: "Broucher_SMS_1",
                    template_body: "Hi ${userName}, You are one step away from securing your life with term insurance of INR 10 Lakhs at just INR 1,490. Complete your application now, click here to know more https://cdn.insuranceinbox.com/sms-brochers/ICICI_PruiProtect_Smart.pdf",
                    template_subject: "Broucher Life",
                },
                {
                    template_id: 4,
                    template_mode: "SMS",
                    template_name: "Broucher_SMS_2",
                    template_body: "Hi ${userName}, You are one step away from securing your vehicle with motor insurance. Complete your application now, click here to know more https://cdn.insuranceinbox.com/sms-brochers/Two_Wheeler_Package_Broucher.pdf",
                    template_subject: "Broucher Vehicle",
                },
            ].map(template =>
                  <option value={template.template_name}>{template.template_subject}</option>
              )}
          </select>
      {/* </div> */}
      {/* <Handle type="source" position={Position.Bottom} id="a" style={handleStyle} />
      <Handle type="source" position={Position.Bottom} id="b" />
            <Handle type="source" position={Position.Bottom} id="c" style={handleStyle} /> */}
      <Schedule
                    campaignMode={campaignMode}
                    campaignName={campaignName}
                    campaignChannel={campaignChannel}
                    channelList={channelList}
                    campaignDesc={campaignDesc}
                    ameyoCampaignLeadId={ameyoCampaignLeadId}
                    ameyoCampaignId={ameyoCampaignId}
                    ameyoCampaignName={ameyoCampaignName}
                    segments={segmentsList && segmentsList.length && segmentsList.filter(segment => selectedSegments.filter(e => e.segment_id === segment.segment_id).length)}
                    NoOfUsers={NoOfUsers}
                    lastRefreshedAt={lastRefreshedAt}
                    campaignMarketingHeadline={campaignMarketingHeadline}
                    campaignUploadFile={campaignUploadFile}
                    campaignMarketingDescription={campaignMarketingDescription}
                    scheduleMode={scheduleMode}
                    scheduleRepeat={scheduleRepeat}
                    scheduleRepeatEvery={scheduleRepeatEvery}
                    scheduleStartOn={scheduleStartOn}
                    scheduleStartOnTime={scheduleStartOnTime}
                    scheduleEndOn={scheduleEndOn}
                    scheduleEndOnDate={scheduleEndOnDate}
                    selectedTemplate={allTemplatesList && allTemplatesList.length && allTemplatesList.filter(e => e.template_id === selectedTemplate)[0] || {}}
                    onSetTab={() => setTabSwitch()}
                    onetimeMode={onetimeMode}
                    onChange={() => handleChangeSchedule}
                    onBack={() => setTabSwitch("template")}
                    onSubmit={() => createCampaign()}
                    showErrors={showErrors}
                />
        </div>}
        {type ? <div>
                        <div className="jiva-col-lg-4 jiva-col-md-4 jiva-col-sm-6 jiva-col-xs-12">
                            <div className="jiva-col-lg-6 jiva-col-md-6  jiva-col-sm-6 jiva-col-xs-12 no-padding">
                                <DatePickerRange
                                    label="Start On*"
                                    params={{
                                        placeholder: "Starts On"
                                    }}
                                    value={ scheduleStartOn}
                                    minDate={new Date()}
                                    onChange={date => handleChangeSchedule("scheduleStartOn", date)}
                                />
                                {showErrors && !scheduleStartOn? <p className="errormsg">Select start date</p>: null}

                                {/* <div className="jiva-cus-input">
                            <label>Start On</label>
                            <input type="date" placeholder="enter date" value={props.scheduleStartOn} onChange={(e) => props.onChange("scheduleStartOn", e.target.value)}/>
                            </div>*/}
                            </div>
                            <div className="jiva-col-lg-6 jiva-col-md-6 jiva-col-sm-6 jiva-col-xs-12 hor-padding-4">
                                {/*<div>*/}
                                {/*<div className="jiva-cus-input">*/}
                                {/*<label style={{opacity:'0'}}>Enter time</label>*/}
                                {/*<input type="text" placeholder="time" value={props.scheduleStartOnTime} onChange={(e) => props.onChange("scheduleStartOnTime", e.target.value)}/>*/}
                                {/*</div>*/}
                                <InputTimeField
                                label ="enter time" value={scheduleStartOnTime} onChange={(e) => handleChangeSchedule("scheduleStartOnTime", e.target.value)}/>

                                {/*<TimePicker title="Enter time*"
                                            value={props.scheduleStartOnTime}
                                            onChange={time => props.onChange("scheduleStartOnTime", time)}/>*/}
                                {showErrors && !scheduleStartOnTime? <p className="errormsg">Select start Time</p>: null}
                            </div>
                        </div>
                        <div className="jiva-col-lg-3 jiva-col-md-3 jiva-col-sm-6 jiva-col-xs-12 ">



                                            {/*<div className="jiva-cus-input ">*/}
                                                {/*<label style={{fontSize:'12px', color:'#6b6b6b', width:'100%'}}>Ends On*</label>*/}
                                                <DatePickerRange
                                                    label="Ends On*"

                                                    params={{
                                                        placeholder: "Ends On"
                                                    }}
                                                    value={scheduleEndOnDate}
                                                    maxDate={'+100Y'}
                                                    minDate={"0"}

                                                    onChange={date => handleChangeSchedule("scheduleEndOnDate", date)}
                                                />
{/*
                                                <input type="text" placeholder="Ends On" disabled={true}   value={props.scheduleStartOn} style={{height:'40px'}}/>
*/}
                                            {/*</div>*/}
                                {showErrors && !scheduleEndOnDate? <p className="errormsg">Select end Time</p>: null}



                        </div> 
                        <div className="jiva-cust-divider"/>
                        <div className="jiva-cust-card ver-padding-20 text-center">
                            <button onClick={() => { setOpenModal(false); }} > Save </button>
                            {/* <button className="ji-pr-bt md-bt" onClick={props.onSubmit}>save & submit</button> */}
                        </div>
                </div>    : null}

      </div>
    </div>
  );
}

const Schedule = (props) => {
  // constructor(props) {
  //     super(props);
  //     this.state = {
  //         type: "",
  //         name: ""
  //     }
  // }
  const [type, setTime] = useState("")
  const [name, setName] = useState("")

  function gql(query) {
    return axios({
        url: window.links.insuranceApi + '/graphql-api/graphql?',
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data: {
            query: query
        }
    });
}

function getActivitySegment(segmentId) {
    return gql(`query {
              getActivitySegment(segmentId:"${segmentId}"){
                code
                message
                payload{
                  segment_id
                  segment_name
                  userType
                  rules{
                    segment_id
                    parameter
                    added_by
                    added_date
                    added_by_userId
                    range
                    ruleType
                    range_value
                  }
                  added_date
                  no_of_users
                }
              }
            }`
    )
}

  // componentDidMount(){
    useEffect(() =>
      {
        if(props.selectedSegments && props.selectedSegments.length){

          let totalRuleData=[]
          props.selectedSegments.map(index=> {
              getActivitySegment(index.segment_id).then(res => {

                  let response = res.data.data.getActivitySegment.payload
                  let userId = '';
                  let user = '';

                  response.rules.map(indexRule => {
                      let rules = {
                          "parameter": indexRule.parameter,
                          "range": indexRule.range,
                          "range_value": indexRule.range_value
                      }
                      userId = indexRule.added_by_userId;
                      user = indexRule.added_by;
                  })
                  let a = {
                      "segment_id": response.segment_id,
                      "segment_name": response.segment_name,
                      "added_by": user,
                      "added_by_userId": userId
                  }
                  totalRuleData.push(a);

                  if(props.selectedSegments.length == totalRuleData.length){
                      props.onChange('totalRuleData',totalRuleData)
                  }
              })
          })
      }
    },[])
  // }
  // render() {
      return (
          <div className="jiva-cust-card padding-30 ovr-fl-hid">
              <h5 className="jiva-sub-title  text-capitalize">Schedule { props.text ? props.text : "details"}</h5>
              {/* <label htmlFor="text">Schedule details:</label> */}
              <div className="jiva-layout jiva-wrap-start ">
                  <div className="width-100 audience-Field ver-margin-10 ">
                      <div className="jiva-col-sm-3 jiva-col-lg-3 jiva-col-md-3">
                      <div className="jiva-cust-radio-btn-block padding-left20" style={{margin:'0px auto'}}>
                          <div className="jiva-cust-radio-btn">
                              <label id="individual">
                                  <input type="radio" name="scheduleMode" checked={props.scheduleMode === "oneTime"} onChange={(e) => props.onChange("scheduleMode", "oneTime")} />
                                  <div className="dummyradio">
                                      <div className="pointer"/>
                                  </div>
                              </label>
                              <span className="text text-capitalize">one time</span>
                          </div>
                          <div className="jiva-cust-radio-btn">
                              <label id="familyfloater">
                                  <input type="radio" name="scheduleMode" checked={props.scheduleMode === "recurring"} onChange={(e) => props.onChange("scheduleMode", "recurring")} />
                                  <div className="dummyradio">
                                      <div className="pointer"/>
                                  </div>
                              </label>
                              <span className="text text-capitalize">recurring</span>
                          </div>
                          {props.showErrors && !props.scheduleMode? <p className="errormsg">Choose one to schedule</p>: null}
                      </div>
                      </div>


                      <div className="jiva-col-sm-4 jiva-col-lg-3 jiva-col-md-3">
                          {
                              props.scheduleMode === "oneTime"?
                                  <div className="jiva-cust-radio-btn-block padding-left20" style={{margin:'0px auto'}}>
                                      {/*<div className="jiva-cust-radio-btn">*/}
                                      {/*<label id="individual">*/}
                                      {/*<input type="radio" name="onetimeMode" checked={props.onetimeMode === "instant"} onChange={(e) => props.onChange("onetimeMode", "instant")} />*/}
                                      {/*<div className="dummyradio">*/}
                                      {/*<div className="pointer"/>*/}
                                      {/*</div>*/}
                                      {/*</label>*/}
                                      {/*<span className="text text-capitalize">Instant</span>*/}
                                      {/*</div>*/}


                                      {/*<div className="jiva-cust-radio-btn">*/}
                                      {/*<label id="individual">*/}
                                      {/*<input type="radio" name="onetimeMode" checked={props.onetimeMode === "scheduledelivery"} onChange={(e) => props.onChange("onetimeMode", "scheduledelivery")} />*/}
                                      {/*<div className="dummyradio">*/}
                                      {/*<div className="pointer"/>*/}
                                      {/*</div>*/}
                                      {/*</label>*/}
                                      {/*<span className="text text-capitalize">Schedule Delivery</span>*/}
                                      {/*</div>*/}



                                      <div className="jiva-cus-selectfield" style={{marginBottom:'5px'}}>
                                          <label>When do you want to send?</label>
                                          <div className="selectdropdown">
                                              <select value={props.onetimeMode} onChange={(e) => props.onChange("onetimeMode", e.target.value)}>
                                                  <option value="">Select</option>
                                                  <option value="instant">Instant</option>
                                                  <option value="scheduledelivery">Schedule to Deliver Later</option>

                                              </select>
                                          </div>
                                      </div>
                                      {props.onetimeMode == ''  && props.showErrors == true ?
                                      <p className="errormsg text-center">Please select one</p>
                                          :""}


                                  </div> : null}
                      </div>




                  </div>

                  {
                      props.onetimeMode === "scheduledelivery" || props.scheduleMode === "recurring"? <div className="audience-Field ver-margin-10  jiva-col-xs-12">
                          <div className="jiva-layout jiva-wrap-start">
                              {
                                  props.scheduleMode === "recurring"? <div className="jiva-col-lg-12 jiva-col-md-12 jiva-col-sm-12 jiva-col-xs-12 no-padding">
                                      <div className="jiva-col-lg-2 jiva-col-md-3 jiva-col-md-3 jiva-col-sm-3 jiva-col-xs-12">
                                          <div className="jiva-cus-selectfield " style={{marginBottom:'5px'}}>
                                              <label>Repeat*</label>
                                              <div className="selectdropdown">
                                                  <select value={props.scheduleRepeat} onChange={(e) => props.onChange("scheduleRepeat", e.target.value)}>
                                                      <option value="">Select</option>
                                                      <option value="daily">Daily</option>
                                                      <option value="weekly">Weekly</option>
                                                      <option value="monthly">Monthly</option>
                                                  </select>
                                              </div>
                                          </div>
                                          {props.showErrors == true && props.scheduleRepeat == ''? <p className="errormsg">Choose Repeat type</p>: null}
                                      </div>
                                      {
                                          props.scheduleRepeat === "weekly"? <div className="jiva-col-lg-3 jiva-col-md-3 jiva-col-sm-3  jiva-col-xs-12">

                                              <div className="jiva-cus-selectfield " >
                                                  <label>Repeat Every*</label>
                                                  <div className="jiva-layout flx-vr-ct">
                                                      <div className="jiva-col-lg-5 jiva-col-md-5 jiva-col-sm-6 jiva-col-xs-6 no-padding">
                                                  <div className="selectdropdown">
                                                      <select value={props.scheduleRepeatEvery} onChange={(e) => props.onChange("scheduleRepeatEvery", e.target.value)}>
                                                          <option value="">Select</option>
                                                          <option>1</option>
                                                          <option>2</option>
                                                          <option>3</option>
                                                          <option>4</option>
                                                          <option>5</option>
                                                          <option>6</option>
                                                      </select>

                                                  </div>
                                              </div>
                                                      <div className="jiva-col-lg-5 jiva-col-md-5 jiva-col-sm-5 jiva-col-xs-4 no-padding ">
                                                          <p className="hor-margin-4 p-content  text-capitalize">weeks</p>
                                                      </div>
                                                  </div>
                                                  {props.showErrors == true && props.scheduleRepeatEvery == ''?<p className="errormsg">Select week</p> :""}


                                              </div>
                                          </div>: null
                                      }
                                  </div>: null
                              }



                              <div className="jiva-col-lg-4 jiva-col-md-4 jiva-col-sm-6 jiva-col-xs-12">
                                 <div className="jiva-col-lg-6 jiva-col-md-6  jiva-col-sm-6 jiva-col-xs-12 no-padding">
                                      <DatePickerRange
                                          label="Start On*"
                                            params={{
                                                placeholder: "Starts On"
                                            }}
                                          value={props.scheduleStartOn}
                                          minDate={new Date()}
                                          onChange={date => props.onChange("scheduleStartOn", date)}
                                      />
                                      {props.showErrors && !props.scheduleStartOn? <p className="errormsg">Select start date</p>: null}

                                      {/* <div className="jiva-cus-input">
                                   <label>Start On</label>
                                   <input type="date" placeholder="enter date" value={props.scheduleStartOn} onChange={(e) => props.onChange("scheduleStartOn", e.target.value)}/>
                                   </div>*/}
                                  </div>
                                  <div className="jiva-col-lg-6 jiva-col-md-6 jiva-col-sm-6 jiva-col-xs-12 hor-padding-4">
                                      {/*<div>*/}
                                      {/*<div className="jiva-cus-input">*/}
                                      {/*<label style={{opacity:'0'}}>Enter time</label>*/}
                                      {/*<input type="text" placeholder="time" value={props.scheduleStartOnTime} onChange={(e) => props.onChange("scheduleStartOnTime", e.target.value)}/>*/}
                                      {/*</div>*/}
                                      <InputTimeField
                                      label ="enter time" value={props.scheduleStartOnTime} onChange={(e) => props.onChange("scheduleStartOnTime", e.target.value)}/>

                                      {/*<TimePicker title="Enter time*"
                                                  value={props.scheduleStartOnTime}
                                                  onChange={time => props.onChange("scheduleStartOnTime", time)}/>*/}
                                      {props.showErrors && !props.scheduleStartOnTime? <p className="errormsg">Select start Time</p>: null}
                                  </div>
                              </div>
                              {
                                  props.scheduleMode === "oneTime"? <div className="jiva-col-lg-3 jiva-col-md-3 jiva-col-sm-6 jiva-col-xs-12 ">



                                                  {/*<div className="jiva-cus-input ">*/}
                                                      {/*<label style={{fontSize:'12px', color:'#6b6b6b', width:'100%'}}>Ends On*</label>*/}
                                                      <DatePickerRange
                                                          label="Ends On*"

                                                            params={{
                                                                placeholder: "Ends On"
                                                            }}
                                                          value={props.scheduleEndOnDate}
                                                          maxDate={'+100Y'}
                                                          minDate={"0"}

                                                          onChange={date => props.onChange("scheduleEndOnDate", date)}
                                                      />
{/*
                                                      <input type="text" placeholder="Ends On" disabled={true}   value={props.scheduleStartOn} style={{height:'40px'}}/>
*/}
                                                  {/*</div>*/}
                                      {props.showErrors && !props.scheduleEndOnDate? <p className="errormsg">Select end Time</p>: null}



                                  </div>: null
                              }
                              {
                                  props.scheduleMode === "recurring"? <div className="jiva-col-lg-3 jiva-col-md-3 jiva-col-sm-6 jiva-col-xs-12 ">

                                                  {/*<label style={{fontSize:'12px', color:'#6b6b6b', width:'100%'}}>Ends On</label>*/}
                                                  <DatePickerRange
                                                      label={"Ends On*"}
                                                      params={{
                                                          placeholder: "Ends On"
                                                      }}
                                                      minDate={new Date()}
                                                      value={props.scheduleEndOnDate}
                                                      onChange={date => props.onChange("scheduleEndOnDate", date)}
                                                  />
                                                  {props.showErrors && !props.scheduleEndOnDate? <p className="errormsg">Select End Date</p>: null}
                                                  {/*        <div className="jiva-cust-radio-btn-block" style={{margin:'0px auto'}}>
                                               <div className="jiva-cust-radio-btn" style={{margin:'6px 20px 8px 0px'}}>
                                               <label id="individual">
                                               <input type="radio" name="dates" checked={props.scheduleEndOn === "after"} onChange={(e) => props.onChange("scheduleEndOn", "after")} />
                                               <div className="dummyradio">
                                               <div className="pointer"/>
                                               </div>
                                               </label>
                                               <span className="text text-capitalize">After</span>
                                               </div>
                                               </div>*/}

                                              {/*       <div className="jiva-col-lg-7 jiva-col-md-5 jiva-col-sm-5 no-padding">
                                           <div className="jiva-cus-input">
                                           <label style={{opacity:'0'}}>Ends On</label>
                                           <input type="date" placeholder="enter date" value={props.scheduleEndOnDate} onChange={(e) => props.onChange("scheduleEndOnDate", e.target.value)}/>
                                           </div>
                                           </div>*/}

                                      {/*  <div className="jiva-col-lg-6 jiva-col-md-4 jiva-col-sm-5 jiva-col-xs-12">
                                   <div className="jiva-layout">
                                   <div className="jiva-col-lg-4 jiva-col-md-3 jiva-col-sm-4 no-padding">
                                   <div className="jiva-cust-radio-btn-block" style={{margin:'0px auto'}}>
                                   <label style={{opacity:'0'}}>Ends On</label>
                                   <div className="jiva-cust-radio-btn" style={{margin:'6px 20px 8px 0px'}}>
                                   <label id="individual">
                                   <input type="radio" name="dates" checked={props.scheduleEndOn === "on"} onChange={(e) => props.onChange("scheduleEndOn", "on")} />
                                   <div className="dummyradio">
                                   <div className="pointer"/>
                                   </div>
                                   </label>
                                   <span className="text text-capitalize">On</span>
                                   </div>
                                   </div>
                                   </div>
                                   <div className="jiva-col-lg-7 jiva-col-md-7 jiva-col-sm-8 no-padding">
                                   <div className="jiva-cus-input">
                                   <label style={{opacity:'0'}}>Ends On</label>
                                   <input type="date" placeholder="enter date" value={props.scheduleEndOnDate} onChange={(e) => props.onChange("scheduleEndOnDate", e.target.value)}/>
                                   </div>
                                   <DatePicker
                                   label={"Ends On"}
                                   params={{
                                   placeholder: "Ends On"
                                   }}
                                   value={props.scheduleEndOnDate}
                                   onChange={date => props.onChange("scheduleEndOnDate", date)}
                                   />
                                   </div>
                                   </div>
                                   </div>*/}
                                  </div>: null
                              }
                          </div>
                      </div>: null
                  }
              </div>







              <div className="jiva-cust-divider"/>
              <div className="ver-padding-20 text-center">
                <button onClick={() => { props.setOpenModal(false); }} > Save </button>
                  {/* <button className="ji-pr-bt md-bt" onClick={props.onSubmit}>save & submit</button> */}
              </div>
          </div>
      )
  // }
}

const InputTimeField = (props) => (
    <div className="jiva-cus-input">
        <label>{props.label}</label>
        <input type="time" className={props.disabled===true?"input-disabled":""} {...props}/>

        {props.showErrors ? <p className='errormsg'>{props.errormsg}</p> : null}
    </div>
);

const DatePickerRange = (props) => {
    const [errorMessage, setErrorMessage] = useState(false)
    const [currentDate, setCurrentDate] = useState(new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear())

//   constructor(props) {
//       super(props);
//       state = {
//           errorMessage: false
//       };
//       let date = new Date();
//       currentDate = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
//   }

//   componentDidMount() {
//     useEffect(() => {
//       let element = ReactDOM.findDOMNode(this);
//         element.find("input").datepicker({
//           changeMonth: true,
//           changeYear: true,
//           dateFormat: 'dd-mm-yy',
//           setDate: props.value,

//           minDate: 0,
//           maxDate: "+100Y",
//           yearRange: "c:c+50",
//           onSelect: (date) => {
//               handleChange(date);
//           }
//       });
//   }, [])
    // }
  
//   static contextTypes = {};

//   static propTypes = {
//       label: PropTypes.string,
//       value: PropTypes.oneOfType([
//           PropTypes.number,
//           PropTypes.string
//       ]),
//       validation: PropTypes.arrayOf(PropTypes.shape({
//           regExp: PropTypes.string.isRequired,
//           regExpOptions: PropTypes.string,
//           error: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.func.isRequired])
//       })),
//       params: PropTypes.shape({
//           required: PropTypes.bool,
//           placeholder: PropTypes.string,
//           disabled: PropTypes.bool,
//           tooltip: PropTypes.string
//       }),
//       showError: PropTypes.bool,
//       onChange: PropTypes.func
//   };

//   static defaultProps = {
//       label: '',
//       value: '',
//       validation: [],
//       params: {
//           required: false,
//           placeholder: null,
//           disabled: false,
//           tooltip: null
//       },
//       showError: true,
//       onChange: (e) => {
//       }
//   };

  const handleChange = (e) => {
      props.onChange(e.target ? e.target.value : e);
  };

//   componentWillReceiveProps(props) {
    useEffect(() => {

      let value = props.value;
      if(value!='')
      {
          if (/^[0123][0-9]-[01][0-9]-[0-9]{4}$/.test(value)) {
              let dateArray = value.split("-");
              let days = parseInt(dateArray[0]);
              let months = parseInt(dateArray[1]) + 1;
              let years = parseInt(dateArray[2]);
              let date = new Date(years, months, days);
                  if (!(date.getUTCFullYear() === years && date.getMonth() === months && date.getDate() === days)) {
                      /*  console.log(date.getUTCFullYear(), date.getMonth(), date.getDate());*/
                      setErrorMessage("Invalid date")
                    //   setState({errorMessage: "Invalid date"});
                      return false;
                  } else {
                    setErrorMessage(false)
                    //   setState({errorMessage: false});
                  }
          } else {
            setErrorMessage("Invalid date")
            //   setState({errorMessage: "Invalid date"});
              return false;
          }
      }
  }, [])
//   }

//   render() {
      return <div className={"jdf-component"}>
          <div className="jiva-cus-dob">
              <label>{props.label}</label>
              <div className="dob">
              <input
                  type="text" autoComplete="off"
                  id={props.id}
                  required={props.params.required}
                  placeholder={props.params.placeholder}
                  disabled={props.params.disabled}
                  value={props.value}
                  onChange={() => handleChange()}
                  data-tooltip={props.params.tooltip}
              />
              </div>
          </div>
          {(props.showError && errorMessage) ?
              <p className="errormsg">{errorMessage}</p> : null}
      </div>
//   }
}

export default Modal;
import React from 'react'
import './PageLoader.scss'

const PageLoader = () => {
    return (
        <div className="Ob-loader" >
            <div className="loader-flex">
                <p>Getting everything ready. Please wait...</p>
                <div className="loader"></div>
            </div>
        </div>
    )
}

export default PageLoader
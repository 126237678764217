import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import AllIcons from "../../Utility/IconLinks";
import Chapters from "./Chapters/Chapters";
import axios from "axios";
import { getPartnerByQuery, viewPartnerByQuery } from "../../components/onboarding/serviceApi";
import PageLoader from "../../components/UI/PageLoader/PageLoader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ExamStartModal from "../../components/Modals/ExamStartModal/ExamStartModal";
import "./Training.scss";

import RefreshIcon from '@mui/icons-material/Refresh';

const Training = () => {
  const [graphqlUrl] = useState("https://api.upsure.io/graphql-api/graphql?");

  const [courseTimes, setCourseTimes] = useState("");
  const [courseTimesTotal, setCourseTimesTotal] = useState("");
  const [agentUuid, setAgentUuid] = useState(null); // need to get it from session storage
  const [agentMobileNumber, setAgentMobileNumber] = useState(null); // need to get it from session stor
  const [coursesWithChapters, setCoursesWithChapters] = useState(null);
  const [learningStatus,setLearningStatus] = useState(false)
  const [examstatus,setExamstatus] = useState(false)
  const [openChapters,setOpenChapters] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const [pageLoader,setPageLoader] = useState(true)
  const [counter,setCounter] = useState(0)
  const navigate = useHistory()

  const [refreshSpinner,setRefreshSpinner] = useState(false)

  // model popup  

  const [modelOpen,setModelOpen] = useState(false)

  //  exam details  

  const [examDetails,setExamDetails] = useState()

  //  getting cookie

  const getCookie = (name) => {
    var nameEQ = name + "=";
    var cookiesArray = document.cookie.split(";"); 
    for (var i = 0; i < cookiesArray.length; i++) {
      var cookie = cookiesArray[i];
      while (cookie.charAt(0) == " ") {
        cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) == 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  };

  // first getting the time spent

  const getCourseTimeSpent = async (uuid) => {
    let configJson = {
      url: graphqlUrl,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: {
        query: `{
            getCourseScreeningDetailsByUUID(
              uuid: "${uuid}"
            ) {
              __typename
              payload {
                __typename
                lession_id
                uuid
                course_id
                training_id
                training_name
                total
              }
            }
        }`,
      },
    };
    return axios(configJson);
  }; 

  // creating function to open chapters    

  const isopenChapters = () => {
      localStorage.setItem("isChapter", true);
      setOpenChapters(true)
  }

  const getCourseWithChapters = () => {
    axios
      .get(
        "https://premiumapi.upsure.io/api/v2/premium/appContentByType/partner"
      )
      .then((res) => {
        let emb = res?.data?.value?._embedded.filter(each => each.pageName == "training_courses")[0]
        let trinaing = emb && emb?.trainingDetails[0]
        // console.log("response emb",trinaing && trinaing?.courseDetails.filter(ech=>ech.courseId == 201)); 
        let chapters = trinaing && trinaing?.courseDetails.filter(ech=>ech.courseId == 201)
        setCoursesWithChapters(
          () => chapters[0]
        );
      });
  };

  
// we are checking all leassons should complete 3600sec 
const checkTotals = (payload)  => {
  // Iterate through each object in the payload  
  let total = 0
  let allcount = true
  if(payload && payload.length > 0) {
    for (let item of payload) {
      // Check if the total is > 3600 and counting total Time Count
      total+= +item.total
      if (+item.total < 3600) {
          allcount = false;
      }
   }
  }
  // If all totals are > 3600, return [true,total]
  return [allcount,total];
}

  // get partner by id  
  
  const getPartnerDetails = (uuid) => {  
   
    if (uuid) {
        getPartnerByQuery(uuid).then((response) => {
            if (response?.data?.data?.getPartnerByQuery?.hits?.total > 0 && response?.data?.data?.getPartnerByQuery?.hits?.hits?.length && response.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source) {
                // let haveSource = response.data.data.getPartnerByQuery.hits.hits[0]
                // step 1 details 
                let partnerBasicDetails = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.basicDetails
                let partnerQualificationDetails = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.qualifications && response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.qualifications[0]
                // step 2 details 
                let identityProofs = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.identityProofs
                // step 3 details 
                let partnerAccountDetails = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.accountDetails && response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.accountDetails[0]
                // verification status 
                let verificationStaus = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.verificationStatus
                if (partnerBasicDetails === null || partnerBasicDetails === undefined || partnerQualificationDetails === null || partnerQualificationDetails === undefined || partnerBasicDetails.firstName === "" || partnerBasicDetails.gender === "" || partnerBasicDetails.dob === "" || partnerQualificationDetails.qualificationName === "" || partnerBasicDetails.location === "") {
                    // setStep(1)
                    // setLoader(false)
                    navigate.push("/onboarding")

                    return
                } else if (
                    identityProofs === null ||
                    identityProofs === undefined 
                    // checkDocumentsError(identityProofs)
                ) {

                    // setStep(2)
                    // setLoader(false)
                    navigate.push("/onboarding")
                    return
                } else if (
                    partnerAccountDetails === null ||
                    partnerAccountDetails === undefined ||
                    partnerAccountDetails.bankName === "" ||
                    partnerAccountDetails.branchName === "" ||
                    partnerAccountDetails.accHolderName === "" ||
                    partnerAccountDetails.bankAccNumber === "" ||
                    partnerAccountDetails.ifscCode === ""
                ) {
                    // setStep(3)
                    // setLoader(false)
                    navigate.push("/onboarding")
                    return
                } else if (verificationStaus === "VERIFICATION_PENDING") {
                    // setLoader(false)
                    navigate.push("/verification")
                    return
                }else if(verificationStaus === "VERIFICATION_DONE" && (response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus === null || response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "TRAINING_IN_PROGRESS")){ 
                    // setLoader(false)
                     
                    axios.get(`https://api.insuranceinbox.in/api/agent/training/${uuid}`).then((res)=>{ 
                      
                      let [isAllCount,totalCount] = checkTotals(res?.data?.payload) 
                      if(isAllCount && totalCount > 54000) {
                        updateAgentProfile()
                      }
                    }) 
                    navigate.push("/training")
                }
                else if(verificationStaus === "VERIFICATION_DONE" && (response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus === null || response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "TRAINING_COMPLETED")&&(response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails == null || response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "FAIL") ){
                  setLearningStatus(true)
                  setExamDetails(() => response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.examDetails?.filter(each => each.courseId == 201)[0])
                  return
              }
              else if(verificationStaus === "VERIFICATION_DONE" && (response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus === null || response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "TRAINING_COMPLETED")&&(response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails == null || response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "PASS")){
                setLearningStatus(true)
                // setExamDetails(() => response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.examDetails?.filter(each => each.courseId == 201)[0])
                navigate.push("/verification")
                return
            }   
            }          
            else {  
                // console.log("cmg here else");
                // setStep(1)
                // setLoader(false)
                navigate.push("/onboarding")
                return
            }
        }).catch((error)=>{
           console.log("error",error)
          //  setLoader(false)
          navigate.push("/onboarding")

        })
    }
    else if( counter >= 1 && (uuid === "" || uuid === null || uuid === undefined) || (uuid && uuid.length <=0 )){
        // console.log("uuid",uuid)
        window.location.href = "https://angelone.upsure.io/health-insurance"
    } 
 }

  // making seconds in HH:MM 

  const secondsToHHMM = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
  
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    return formattedTime;
  }

  const bankNavigation = () => {
    // console.log("clickkkkkked",localStorage.getItem("isChapter"),typeof localStorage.getItem("isChapter"));
    let res = localStorage.getItem("isChapter")
     if(res == false || res == "false"){
      console.log("false"); 
       navigate.push("/training")  
       
       localStorage.setItem("isChapter",false)
       window.location.reload();
     }else if(res == true || res == "true"){
        // console.log("inside");
        localStorage.setItem("isChapter",false)
        setOpenChapters(false)
        updateUserSpentTime()
     }else {
        localStorage.setItem("isChapter",false)
        setOpenChapters(false)
        // updateUserSpentTime()
     }
  }

  // if(viewPartner.examDetails.status == PASS){
  //   exam date- viewPartner.examDetails.completedDate
  //   percentage - viewPartner.examDetails.obtMarks
  //   requiredPercentage - viewPartner.examDetails.passMarks
  //   }
 
  const getTodayDate = ()=>{

    var currentDate = new Date();
    var day = currentDate.getDate().toString().padStart(2, '0');
    var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var month = monthNames[currentDate.getMonth()];
    var year = currentDate.getFullYear();

    return `${day}-${month}-${year}`;
  }


const updateAgentProfile =  () => {
  try {
    const apiUrl = 'https://api.upsure.io/api/agent/profile';

    const data = {
      uuid: agentUuid,
      mobile: agentMobileNumber,
      learningStatus: [
        {
          trainingName: 'AGENT',
          courseName: 'POS_GENERAL',
          courseId: '201',
          status: 'TRAINING_COMPLETED',
          startDate: getTodayDate(),
          trainingId: 'T7689980863666549',
        },
      ],
    }; 

   axios.patch(apiUrl, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response)=>{
        if(response.data.code == 0 && response.data.message == "success") {
            // window.location.reload()
            updateUserSpentTime()
        }
    })
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
  }
};

// const redirecting to exam 

const RedirectToExam = () => {
  navigate.push("/training/exam")
}

  useEffect(() => {
    setAgentUuid(() => getCookie("AgentUUID"));
    setAgentMobileNumber(() => getCookie("AgentMobileNumber"));
    setCounter(prev => prev+1)
  
    if(agentUuid){
      getCourseTimeSpent(agentUuid).then((res) => { 
        setCourseTimes(
          () => res?.data?.data?.getCourseScreeningDetailsByUUID?.payload?.filter(
            (arr) => arr.course_id == "201"
          )[0]
        );
        setCourseTimesTotal( () => res?.data?.data?.getCourseScreeningDetailsByUUID?.payload?.filter(
          (arr) => arr.course_id == "201"
        )[0]?.total)
        setPageLoader(false)
      });  
    }
   

    viewPartnerByQuery(agentUuid).then((response) => {   
      // console.log("resoponse viewpartner ===>>> ",response);
        if(response?.data?.data?.viewPartner?.learningStatus === null ) {
          setLearningStatus(false)
          setExamstatus(false)
        }else if(response?.data?.data.viewPartner?.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response?.data?.data?.viewPartner?.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response?.data?.data.viewPartner?.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "TRAINING_IN_PROGRESS"){
          setLearningStatus(false)
          setExamstatus(false) 
        }else if(response?.data?.data?.viewPartner?.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response?.data?.data?.viewPartner?.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response?.data?.data?.viewPartner?.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status.toLowerCase() == "issued"){
          setLearningStatus(true)
          setExamstatus(true)
        }else if(response?.data?.data?.viewPartner?.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response?.data?.data?.viewPartner?.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response?.data?.data?.viewPartner?.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status.toLowerCase() == "TRAINING_COMPLETED"){
          setLearningStatus(true)
        }

        // setExamDetails(response?.data?.data?.viewPartner?.examDetails.filter(each => each.courseId == 201)[0])

    })
    getCourseWithChapters();
    getPartnerDetails(agentUuid)
  }, [agentUuid,agentMobileNumber]);


  useEffect(()=>{ 
    setOpenChapters((localStorage.getItem("isChapter") == null || localStorage.getItem("isChapter") == "false" ) ? false : true)
  },[])
 
  // update user timing  

  const updateUserSpentTime = ()=>{
    setRefreshSpinner(true)
    // console.log("clicked update user time");
    if(agentUuid){
      getCourseTimeSpent(agentUuid).then((res) => {
        setCourseTimes(
          () => res?.data?.data?.getCourseScreeningDetailsByUUID?.payload?.filter(
            (arr) => arr.course_id == "201"
          )[0]
        );
        setCourseTimesTotal( () => res?.data?.data?.getCourseScreeningDetailsByUUID?.payload?.filter(
          (arr) => arr.course_id == "201"
        )[0]?.total)  
        setRefreshSpinner(false)
      }
    )}
  }

  return (
    <div className="TrainingPage">
      <Header />
      {/* {console.log("open model ----> ", openModal)} */}
      { openModal ? <ExamStartModal  RedirectToExam={RedirectToExam} setOpenModal={setOpenModal}/> : null}
      {
        pageLoader ? <PageLoader /> :

            <div className="training-con">
             <div className='pagehead-flx'>
            <div className='pagehead-lft-item'>
                <div className='pageheadinc-itm'>
                    <img src={AllIcons.TrainingPageIcon} alt='Icon' />
                </div>
                <div className='pageheadtxt-item'>
                    <h1 className='hdtxt'>Training</h1>
                    <p className='sub-hdtxt'>
                    "Use our interactive online tutorials to complete your training and become certified sales person."
                    </p>
                </div>
            </div>
            <div className="bkpbutton">
            <Button
              label={"Back"}
              status={false}
              isIcon={true}
              img={AllIcons.BackIcon}
              onClick={bankNavigation}
              buttonType="back-btn"
            />
          </div>
        </div>
            <div className="training-sec">
              <div className="tran-lft-item">
                <div className="lftitm-sec">
                  <div className="lftitm-img">
                    <img
                      src="https://cdn.upsure.io/POS/common/pos_general_img01.svg"
                      alt="pos"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div className="lftitm-cont">
                    <h2 className="lft-title">POS - General</h2>
                    {
                      true ? null :  learningStatus ? 
                      <div className="lftstatus-bdy">
                        <div className="learning-sec">Training</div>
                        <div className="learning-btn">
                          <div className="cmpbtn">
                            <div className="comptxt">Completed</div>
                            <div className="cmpinc">
                              <img
                                src="https://cdn.upsure.io/POS/common/wht_fillchk_mark.svg"
                                alt="check mark"
                                width="100%"
                                height="100%"
                              />
                            </div>
                          </div>
                        </div>
                      </div> 
                      :
                      <div className="inprogress-bdy">
                        <div className="tra-sec">Training</div>
                        <div className="inprogress-btn">
                          <div className="cmpbtn">
                            <div className="comptxt">In Progress</div>
                            <div className="cmpinc">
                              <img
                                src="https://cdn.upsure.io/POS/common/inprogress_rgtarrow_img.svg"
                                alt="arrow"
                                width="100%"
                                height="100%"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                    }
                  </div>
                </div>
              </div>
              {
                !openChapters ?
              <div className="tran-rgt-item">
                <div className="trasec">
                  <h1 className="tran-title">Training</h1>
                  <div className="tra-item">
                    <div className="tran-status-itm">
                      <div className="gy-item">Time Spent</div>
                      <div className="gy-status-item">{ courseTimes && courseTimes.total ? secondsToHHMM( courseTimes.total) : "00:00"} HH:MM  
                      <div className="refresh-inc">
                        <div className={refreshSpinner ? "refinc" : ""}>
                        <RefreshIcon onClick={updateUserSpentTime}/>
                       </div>
                       </div>
                      </div>
                    </div>
                    <div className="tran-status-itm">
                      <div className="gy-item">Learning Status</div>
                      {
                        learningStatus ? <div className="cmp-item">Completed</div> : courseTimes && courseTimes.total > 0 ? <div className="pnd-cmp-item">In Progress</div> : <div className="pnd-cmp-item">Not yet started</div>
                      }
                      
                    </div>
                    {true ? null : <div className="tran-status-itm">
                      <div className="gy-item">Learning Certificate</div>
                      <div className="gy-status-item">Download</div>
                    </div>}
                    <div className="tran-btn-itm">
                      <Button
                        label={ learningStatus ? "TRAINING COMPLETED" :"START TRAINING"}
                        status={true}
                        isIcon={false}
                        onClick={isopenChapters}
                        buttonType="start-traning-btn"
                      />
                    </div>
                  </div>
                </div>
                {
                  // examDetails ?
                  <div className="examination-sec">
                    {/* {console.log("examDetails ===> ", examDetails)} */}
                  <h1 className="exam-title">Examination</h1>
                  <div className="exm-item">
                    <div className="tran-status-itm">
                      <div className="gy-item">Exam Date</div>
                      <div className="gy-status-item"> {examDetails?.completedDate ? examDetails?.completedDate : "--" }</div>
                    </div>
                    <div className="tran-status-itm">
                      <div className="gy-item">Required Percentage</div>
                      <div className="gy-status-item"> {examDetails?.passMark ? examDetails?.passMark : "40"}%</div>
                    </div>
                    <div className="tran-status-itm">
                      <div className="gy-item">Achieved Percentage</div>
                      <div className="gy-status-item"> {examDetails?.obtMarks ? examDetails?.obtMarks+"%" : "--"}</div>
                    </div>
                    <div className="tran-btn-itm">
                      <Button
                        label={(examDetails?.status == "FAIL")  ? "RETRY EXAM" : "START EXAM"}
                        status={ examDetails?.status == "FAIL" ? false : true}
                        disabled={examDetails ? examDetails.status == "PASS" ? true : false : learningStatus ? false : true}
                        isIcon={false}
                        onClick={()=>setOpenModal(true)}
                        buttonType="retry-btn-txt"
                      >
                      </Button>
                    </div>
                  </div>
                  </div> 
                  // : null
                
                }
              </div>:
              <Chapters lessons={coursesWithChapters?.lessons} /> 
              }
              
            </div>
              
            
            </div>
      }
      
    </div>
  );
};

export default Training;

import React from 'react';
import "./PageHeading.scss";

const DashboardHeading = () => {
    return (
        <div className='dashboard-head-flx'>
            <p className='userinfo'>Hellopavan Kumar,</p>
            <h1 className='pagehd'>Welcome back to your virtual office!</h1>
        </div>
    );
}

export default DashboardHeading;

import React, { useEffect, useState } from 'react'
// import { Select } from 'semantic-ui-react'
import axios from 'axios'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getPartnerByQuery,updatePartnerData } from './serviceApi'
import Input from '../UI/Input/Input'
import SelectComp from '../UI/Select/Select'
import Button from '../UI/Button/Button'
// import AllIcons from "../../Utility/IconLinks";
import CheckBox from '../UI/CheckBox/CheckBox'
import ErrorMsg from '../UI/ErrorMsg/ErrorMsg'
import ButtonLoader from '../UI/buttonLoader/ButtonLoader';
// import PageLoader from '../UI/pageLoader/PageLoader';
import PageLoader from '../UI/PageLoader/PageLoader';
import PageHeader from '../UI/pageHader/PageHeader';
import AllIcons from '../../Utility/IconLinks';
import BasicSelect from '../UI/Select/SelectDrop';



const BankDetailsComponent = ({setStep,agentMobileNumber,agentUuid,whichStep,setWhichStep}) => {
    
    const navigate = useHistory();
    const [pageLoading ,setPageLoading]=useState(true)


    const [bankNameList,setBankNameList]=useState([])
    
    const [graphqlUrl,]=useState("https://api.upsure.io/graphql-api/graphql?")

    const [agentBankDetails,setAgentBankDetails] = useState({
        bankName:"",
        branchName:"",
        accountHolderName:"",
        accountNumber:"",
        isfcCode:"",
        agreeAndAccept:false
    })
    const [agentBankDetailsError,setAgentBankDetailsError] = useState({
        bankNameError:"",
        branchNameError:"",
        accountHolderNameError:"",
        accountNumberError:"",
        isfcCodeError:"",
        agreeAndAcceptError:""
    })

    const [loader,setLoader]=useState(false)

     useEffect(()=>{
        // console.log("third");
        getBankNames()
        if(whichStep < 3){
            setWhichStep(3)
        }
        getPartnerData(agentUuid)
     },[])


    const handleChange = (name,value) =>{
        // setting the erros epmty
        setError(`${name}Error`,"")
        setAgentBankDetails((prev)=>({
                ...prev,
                [name]:value
        }))
    }

    const setError = (name,errorMsg)=>{
        setAgentBankDetailsError((prev)=>({
            ...prev,
            [name]:errorMsg
          }))
    }

    const validation = ()=>{
       let result = false
       const branchNameRegex = /^[a-zA-Z\s]{2,60}$/
       const nameRegex = /^[a-zA-Z\s]{2,60}$/
       const isfcCodeRegex = /^[a-zA-Z]{4}[a-zA-Z0-9]{7}$/
       const accountNumberRegex = /^[0-9]{10,20}$/
       const emptyRex = /^\s+$/


       if(agentBankDetails.bankName === "" || agentBankDetails.bankName === null || agentBankDetails.bankName === undefined ){
        setError("bankNameError","Please select Bank Name")
        result = true

       }
       if(agentBankDetails.branchName === "" || agentBankDetails.branchName === null || agentBankDetails.branchName === undefined || emptyRex.test(agentBankDetails.branchName) ){
        setError("branchNameError","Please Enter Branch Name")
        result = true

       }else if(!branchNameRegex.test(agentBankDetails.branchName)){
        setError("branchNameError","Please Enter correct Branch Name,branch name should be greater than 2 and less than 60")
        result = true

       }

       if(agentBankDetails.accountHolderName === "" || agentBankDetails.accountHolderName === null || agentBankDetails.accountHolderName === undefined || emptyRex.test(agentBankDetails.accountHolderName) ){
        setError("accountHolderNameError","Please Enter Account Holder Name")
        result = true

       }else if(!nameRegex.test(agentBankDetails.accountHolderName)){
        setError("accountHolderNameError","Please Enter correct Account Holder Name,Account Holder Name should be greater than 2 and less than 60")
        result = true

       }

       if(agentBankDetails.accountNumber === "" || agentBankDetails.accountNumber === null || agentBankDetails.accountNumber === undefined ){
        setError("accountNumberError","Please Enter Account Number")
        result = true

       }else if(!accountNumberRegex.test(agentBankDetails.accountNumber)){
        setError("accountNumberError","Please Enter correct Account Number")
        result = true

       }

       if(agentBankDetails.isfcCode === "" || agentBankDetails.isfcCode === null || agentBankDetails.isfcCode === undefined ){
        setError("isfcCodeError","Please Enter ISFC Code")
        result = true

       }else if(!isfcCodeRegex.test(agentBankDetails.isfcCode)){
        setError("isfcCodeError","Please Enter correct ISFC Code")
        result = true
       }

       if(agentBankDetails.agreeAndAccept === "" || agentBankDetails.isfcCode === null || agentBankDetails.isfcCode === undefined || agentBankDetails.agreeAndAccept === false){
        setError("agreeAndAcceptError","Please Accept terms and conditions")
        result = true

       }
       
       return result

    }

    const getBankNames = () => {

        let configJson = {
            url:graphqlUrl,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: {
              query: `{
                              getChannelValueLists(
                                channel: "default"
                                listName: "BankNameMappingMaster"
                              )
                            }`
            }
          };
          axios
          .request(configJson)
          .then(function (response) {
                let bankNamesList = response.data.data.getChannelValueLists[0].listItems
               setBankNameList(bankNamesList.map(item => ({ text: item, value: item })))
          })
          .catch(function (error) {
			console.error(error);
		});
    }
    
    // getting partner Data from getPartnerByQuery api
    const getPartnerData = (uuid)=>{
        if(uuid){
                getPartnerByQuery(uuid).then((response)=>{
                    if(response?.data?.data?.getPartnerByQuery?.hits?.total > 0 && response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source){
                        let partnerAccountDetails = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.accountDetails[0]
        
                        setAgentBankDetails((prev)=>({
                            ...prev,
                            bankName:partnerAccountDetails.bankName,
                            branchName:partnerAccountDetails.branchName,
                            accountHolderName:partnerAccountDetails.accHolderName,
                            accountNumber:partnerAccountDetails.bankAccNumber,
                            isfcCode:partnerAccountDetails.ifscCode,
                        }))
                        setPageLoading(false)
                    }else{
                        setPageLoading(false)
                    }
                })
                .catch(function (error) {
                    console.error(error);
                    setPageLoading(false)
                });
        }
    }
    
    // const updatePartnerData = async (payload) => {
    //     const options = {
    //         url: graphqlUrl,
    //         method: "POST",
    //         data: {
    //           query: `
    //           mutation {

    //             updatePartner(
              
    //               payload: ${payload}
              
    //             ) {
              
    //               __typename
              
    //               code
              
    //               message
              
    //             }
              
    //           }
              
    //           `
    //         }
    //       }

    //     axios
	// 	.request(options)
	// 	.then(function (response) {
    //    if(response.data.data.updatePartner.code === 0 &&  response.data.data.updatePartner.message === 'success'){
    //       setLoader((prev)=>!prev)
    //       navigate.push("/verification")
    //    }
	// 	})
	// 	.catch(function (error) {
	// 		console.error(error);
    //         setLoader((prev)=>!prev)
	// 	});
    // }

    const updateAgentData = (payload) => {
        //  here we are calling the api updateAgent
         updatePartnerData(payload).then((response)=>{
                    if(response.data.data.updatePartner.code === 0 &&  response.data.data.updatePartner.message === 'success'){
                        setPageLoading(false)
                        setLoader(false)
                        // console.log("loading cmg here");
                        navigate.push("/verification")
                    }
              })
              .catch(function (error) {
                  console.error(error);
                  setPageLoading(false)
                  setLoader(false)
              });
     }

    const handleSubmit = ()=>{
        setLoader(true)
        if(!validation()){
            // need to call the validat and redirect to the page

            // creating a payload 

           const payloadData = {
                uuid: agentUuid,
                mobile: agentMobileNumber,
                basicDetails: { term: true },
                verificationStatus: "VERIFICATION_PENDING",
                metaData: "source",
                accountDetails: [
                  {
                    bankName: agentBankDetails.bankName,
                    branchName:agentBankDetails.branchName && agentBankDetails.branchName.trim(),
                    accHolderName: agentBankDetails.accountHolderName && agentBankDetails.accountHolderName.trim(),
                    bankAccNumber: agentBankDetails.accountNumber,
                    ifscCode: agentBankDetails.isfcCode.toLowerCase(),
                    // "sbin00024ti"
                  }
                ]
              }
 
              const source = {
                createdSource:"WEB",
                updatedBy:"Partner",
                updatedByUserId:agentUuid
  
              }

              let payload = JSON.stringify(payloadData).replace(/\"([^(\")"]+)\":/g, "$1:").replace("source", JSON.stringify(source).replace(new RegExp('"', "g"), '\\"'))
            // call the update partner api 
            updateAgentData(payload)
               
           
        }else {
            setLoader(false)
        }

    }

    return (
        <div className="onboard-pr-details">
            {
            pageLoading ? <PageLoader /> :null
          }
           <PageHeader step={3} whichStep={whichStep} setStep={setStep}/>
            <div className="onboard-card">
                <div className="psdt-sec">
                <h2 className="personal-details-title">Your Bank Details</h2>
                {/* <div className="psdt-item">
                        <SelectComp placeholder={'Please Select Bank Name'} options={bankNameList} onChange={(e, obj) => handleChange("bankName", obj.props.value)}
                                value={agentBankDetails.bankName}/>
                         {
                        agentBankDetailsError.bankNameError !== "" ?<ErrorMsg error={agentBankDetailsError.bankNameError} />: null
                        } 
                    </div> */}
                <div className="psdt-item">
                  
                  <BasicSelect placeholder={"Please Select Bank Name"} options={bankNameList} onChange={(e, obj) => handleChange("bankName", obj)} value={agentBankDetails.bankName}/>

                  {
                        agentBankDetailsError.bankNameError !== "" ?<ErrorMsg error={agentBankDetailsError.bankNameError} />: null
                        } 
                </div>
                    <div className="psdt-item">
                        <Input label={"Branch Name"}  value={agentBankDetails.branchName}
                                onChange={(e) => handleChange("branchName", e.target.value)}/>
                                 {
                        agentBankDetailsError.branchNameError !== "" ? <ErrorMsg error={agentBankDetailsError.branchNameError} /> : null
                    }
                        
                    </div>
                    <div className="psdt-item">
                        <Input label={"Account Holder Name"}  value={agentBankDetails.accountHolderName}
                                onChange={(e) => handleChange("accountHolderName", e.target.value)}/>
                                 {
                        agentBankDetailsError.accountHolderNameError !== "" ? <ErrorMsg error={agentBankDetailsError.accountHolderNameError} /> : null
                    }
                        
                    </div>
                    <div className="psdt-item">
                        <Input label={"Account Number"}  value={agentBankDetails.accountNumber}
                                onChange={(e) => handleChange("accountNumber", e.target.value)}/>
                    {
                        agentBankDetailsError.accountNumberError !== "" ?  <ErrorMsg error={agentBankDetailsError.accountNumberError} /> : null
                    }
                       
                    </div>
                    <div className="psdt-item">
                        <Input label={"IFSC Code"} value={agentBankDetails.isfcCode }
                                onChange={(e) => handleChange("isfcCode", e.target.value.toUpperCase())}/>
                                 {
                        agentBankDetailsError.isfcCodeError !== "" ?  <ErrorMsg error={agentBankDetailsError.isfcCodeError} /> : null
                    }
                       
                    </div>

                    <div className="psdt-chk-item">
                        <div className='chk-item'>
                            <CheckBox checked={agentBankDetails.agreeAndAccept} onChange={(e) => handleChange("agreeAndAccept",e.target.checked)} />
                        </div>
                        <div className='chk-trms-item'>I agree to accept and bound by all the <a href="javascript:void(0)">Terms & Conditions.</a></div>
                    </div>
                    {
                        agentBankDetailsError.agreeAndAcceptError ? <ErrorMsg error={agentBankDetailsError.agreeAndAcceptError} /> : null
                    }
                        
                    
                    <div className="psdt-btn-item">
                        {
                            loader ? <ButtonLoader /> :

                                <Button
                                label={"Continue"}
                                status={true}
                                isIcon={true}
                                img={AllIcons.tickIcon}
                                buttonType={"proceed-btn"}
                                onClick={handleSubmit}
                                />
                        }
                    </div>
            </div>
            </div>
        </div>
    )
}

export default BankDetailsComponent
import React from "react";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

import "./CustomDatePicker.scss"; // Import your CSS file for styling

const CustomDatePicker = ({onChange,value, label, placeholder}) => {

  return (
    
    <LocalizationProvider dateAdapter={AdapterDayjs} fullwidth>
      <DemoContainer components={['DateField']}>
        <DateField  onChange={onChange} value={value ? dayjs(value) : null} label={label} format="DD-MM-YYYY" />
      </DemoContainer>
    </LocalizationProvider>


  );
};

export default CustomDatePicker;

import React from 'react'
import "./ButtonLoader.scss"

const ButtonLoader = () => {
  return (
    <button className='continue-ld-btn'>
       <span className="btn-loader"></span>
    </button> 
  )
}

export default ButtonLoader
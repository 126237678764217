import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CustomDatePicker from "../UI/Date/DatePicker";
import RadioButton from "../UI/RadioButton/RadioButton";
import SelectComp from "../UI/Select/Select";
import Button from "../UI/Button/Button";
import AllIcons from "../../Utility/IconLinks";
import Input from "../UI/Input/Input";
import ErrorMsg from "../UI/ErrorMsg/ErrorMsg";
// import { Select } from 'semantic-ui-react'
import { getPartnerByQuery,updatePartnerData } from './serviceApi'
import ButtonLoader from '../UI/buttonLoader/ButtonLoader';
// import PageLoader from '../UI/pageLoader/PageLoader';
import PageLoader from '../UI/PageLoader/PageLoader';
import PageHeader from '../UI/pageHader/PageHeader';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import BasicSelect from '../UI/Select/SelectDrop';

const PersonalDetailsComponent = ({setStep,agentMobileNumber, agentUuid,whichStep,setWhichStep}) => {

    const [educationList,setEducationList]=useState([])
    const [addressDetails,setAddressDetails]=useState()
    const [partnerQueryData,setPartnerQueryData]=useState([])
    const [pageLoading ,setPageLoading]=useState(true)
    const [loader,setLoader]=useState(false)

    const [graphqlUrl,]=useState("https://api.upsure.io/graphql-api/graphql?")

    const [agentPersonalDetails,setAgentPersonalDetails] = useState({
        name:"",
        gender:"",
        dateOfBirth:"",
        education:"",
        pincode:"",
        lname:"",
        fathername:"",
        addressLine1:"",
        addressLine2:"",
        state:"",
        city:"",
        sbTag : "",
    })

    const [agentPersonalDetailsError,setAgentPersonalDetailsError] = useState({
        nameError:"",
        genderError:"",
        dateOfBirthError:"",
        educationError:"",
        pincodeError:"",
        lnameError:"",
        fathernameError:"",
        addressLine1Error:"",
        addressLine2Error:"",
        sbTagError:""
    })

    const isValidDate =(dateString) => {
      const dateRegex = /^\d{2}-\d{2}-\d{4}$/; // Format: DD-MM-YYYY
    //  console.log(formatDate(agentPersonalDetails.dateOfBirth));
      if (!dateRegex.test(dateString)) {
          return false; // Format is incorrect
      }
  
      const [day, month, year] = dateString.split('-');
      const dateObject = new Date(`${year}-${month}-${day}`);
  
      // Check if the date components match the input values
      return (
          dateObject.getFullYear() == year &&
          dateObject.getMonth() == month - 1 && // Months are zero-based
          dateObject.getDate() == day
      );
  }
  
    const handleChange = (name,value) =>{
        // setting the erros epmty
        // console.log("cnage value",name,value)
        setError(`${name}Error`,"")
        
        // entering pincode we need to call the api
        if(name == "pincode"){
            if(value.length <= 6){
              // console.log("in less6");
              setAgentPersonalDetails((prev)=>({
                ...prev,
                [name]:value
              }))
              //  disabling the values of the city and state  
                
              setAgentPersonalDetails((prev)=>({
                ...prev,
                state:"" ,
                city:""
               }))
              // if they are having the leangth we need to call the api and get data
              if(value.length == 6){
                // console.log("in call 6");
                getCodes(value).then((response)=>{
                     
                    const PincodeRegex = /^[0-9]{1,6}$/

                   if(response?.data?.data?.getCodes?.payload === null || response?.data?.data?.getCodes?.payload === "" || response?.data?.data?.getCodes?.payload === undefined || !PincodeRegex.test(value)){
                    setError("pincodeError","Please Enter Correct pin Code")
                    return
                  }else {
                     setAddressDetails(()=>response?.data?.data?.getCodes?.payload[0])
                     setAgentPersonalDetails((prev)=>({
                          ...prev,
                          state:response?.data?.data?.getCodes?.payload[0]?.state ,
                          city: response?.data?.data?.getCodes?.payload[0]?.city
                     }))

                  }
                   
                })
              }
            }else {
              // pincode after 6
            }
        }else {
          setAgentPersonalDetails((prev)=>({
                  ...prev,
                  [name]:value
          }))
        }
    }

    const setError = (name,errorMsg)=>{
        setAgentPersonalDetailsError((prev)=>({
            ...prev,
            [name]:errorMsg
          }))
    }

    const calculateAge = (birthdate)=>{
        const birthdateObject = new Date(birthdate);
        const currentDate = new Date();
        const age = currentDate.getFullYear() - birthdateObject.getFullYear();
        if (currentDate.getMonth() < birthdateObject.getMonth() || (currentDate.getMonth() === birthdateObject.getMonth() && currentDate.getDate() < birthdateObject.getDate())) {
            return age - 1;
        } else {
            return age;
        }
    }
    
    const validation = () => {
        let result = false
        const NameRegex = /^[a-zA-Z\s]{2,60}$/
        // /^[a-zA-Z\s]*$/
        const PincodeRegex = /^[0-9]{1,6}$/
        
        const emptyRex = /^\s+$/
        const sbCode  = /^[a-zA-Z0-9]{1,10}$/

        // address regex 

        const addressRegex = /^[A-Za-z0-9.,-_ ]*$/

       if(agentPersonalDetails.name === "" || agentPersonalDetails.name === undefined || agentPersonalDetails.name === null || (emptyRex.test(agentPersonalDetails.name)) ){
           setError("nameError","Please enter name")
           result = true
       }else if(!NameRegex.test(agentPersonalDetails.name)){
           setError("nameError","Please enter a valid name,and name contains greater than 2 and less than 60 characters")
           result = true
       }

      // last name validation 
      
      if(agentPersonalDetails.lname === "" || agentPersonalDetails.lname === undefined || agentPersonalDetails.lname === null || (emptyRex.test(agentPersonalDetails.lname)) ){
        setError("lnameError","Please enter last name")
        result = true
      }else if(!NameRegex.test(agentPersonalDetails.lname)){
          setError("lnameError","Please enter a valid last name,and last name contains greater than 2 and less than 60 characters")
          result = true
      }

      // father name validation
      
      if(agentPersonalDetails.fathername === "" || agentPersonalDetails.fathername === undefined || agentPersonalDetails.fathername === null || (emptyRex.test(agentPersonalDetails.fathername)) ){
        setError("fathernameError","Please enter last name")
        result = true
      }else if(!NameRegex.test(agentPersonalDetails.fathername)){
          setError("fathernameError","Please enter a valid father name,and father name contains greater than 3 and less than 60 characters")
          result = true
      }
      
        // Sp  Tag validation
        if(agentPersonalDetails.sbTag === "" || agentPersonalDetails.sbTag === undefined || agentPersonalDetails.sbTag === null || (emptyRex.test(agentPersonalDetails.sbTag)) ){
          result = false
        }else if(!sbCode.test(agentPersonalDetails.sbTag)){
            setError("sbTagError","Please enter a valid SB Tag. Can contain only alphanumeric characters upto 10 characters")
            result = true
        }
        
      // address line1 validation 

      if(agentPersonalDetails.addressLine1 === "" || agentPersonalDetails.addressLine1 === undefined || agentPersonalDetails.addressLine1 === null || (emptyRex.test(agentPersonalDetails.addressLine1)) ){
        setError("addressLine1Error","Please enter Address Line 1")
        result = true
      }else if(!addressRegex.test(agentPersonalDetails.addressLine1)){
          setError("addressLine1Error","Please enter a valid Address Line 1")
          result = true
      }

      // address line2 validation 
      
      if(agentPersonalDetails.addressLine2 === "" || agentPersonalDetails.addressLine2 === undefined || agentPersonalDetails.addressLine2 === null || (emptyRex.test(agentPersonalDetails.addressLine2)) ){
        setError("addressLine2Error","Please enter Address Line 2")
        result = true
      }else if(!addressRegex.test(agentPersonalDetails.addressLine2)){
          setError("addressLine2Error","Please enter a valid Address Line 2")
          result = true
      }
  
      // gender validation

       if(agentPersonalDetails.gender === "" || agentPersonalDetails.gender === null||agentPersonalDetails.gender === undefined){
           setError("genderError","Please select gender")
           result = true
       }

      //  date of birth validation
      
       if(agentPersonalDetails.dateOfBirth === "" || agentPersonalDetails.dateOfBirth === null||agentPersonalDetails.dateOfBirth === undefined ){
        setError("dateOfBirthError","Please select date of birth")
        result = true
       }

      //  age greater than 18 and less than 90
       if(calculateAge(agentPersonalDetails.dateOfBirth) < 18 ){
        setError("dateOfBirthError","Age should be greater than 18 years")
        result = true
       } 

       if(calculateAge(agentPersonalDetails.dateOfBirth) > 100){
        setError("dateOfBirthError","Age should be less than 100 years")
        result = true
       } 

      
       if(!isValidDate(formatDate(agentPersonalDetails.dateOfBirth))){
        setError("dateOfBirthError","Please select Correct date of birth")
        result = true
       }

      // education validation

       if(agentPersonalDetails.education === ""  || agentPersonalDetails.education === null||agentPersonalDetails.education === undefined){
        setError("educationError","Please Select education qualification")
        result = true
       }
       if(agentPersonalDetails.pincode === ""  || agentPersonalDetails.pincode === null||agentPersonalDetails.pincode === undefined || (emptyRex.test(agentPersonalDetails.pincode)) || agentPersonalDetails.pincode === "000000" ){
        setError("pincodeError","Please Enter pin Code")
        result = true
       }else if(!PincodeRegex.test(agentPersonalDetails.pincode)){
        setError("pincodeError","Please Enter a Correct pin Code")
        result = true
       }
    return result
    }

    const getEducationList = ()=>{
        // axios.get("https://premiumapi.upsure.io/api/v2/premium/appContentByType/partner").then((res)=>{
        //     setEducationList(()=>res?.data?.value?._embedded[7]?.qualifications)
        // })

        const educationListData = [

          {
          text	:"SSC",
          value	:"ssc"
          },
          {
            text:"Intermediate",
          value	:"intermediate"
          },
          {
            text	:"Bachelors Degree",
          value	:"bachelors_degree"
          },
          {
            text	:"Master Degree",
            value	:"master_degree"
          },
          {
            text	:"Doctorate or Higher",
          value	:"doctorate_or_higher"
          },
          {
            text	:"Diploma",
          value	:"diploma"
          },
          {
            text	:"Others",
          value	:"others"
          }
        ]

        setEducationList(()=> educationListData)

    }
 
    useEffect(()=>{
      console.log("first");
        if(whichStep < 1 || whichStep == 0){
          setWhichStep(1)
        }
        getEducationList()
        getPartnerData(agentUuid)
    },[])
    
    const getCodes = async (pincode)=>{

        let configJson = {
            url: graphqlUrl,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: {
              query: `mutation {
                    getCodes(payload: { pincode: "${pincode}"}) {
                      payload {
                        state
                        cityCode
                        city
                        stateCode
                      }
                    }
                  }`
            }
          };

      return axios.request(configJson)

    }
 
    const getPartnerData = (agentUuid)=>{
      if(agentUuid){
            getPartnerByQuery(agentUuid).then((response)=>{
              if(response?.data?.data?.getPartnerByQuery?.hits?.total > 0 && response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source){
                let partnerAllDetails = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source
                let partnerBasicDetails = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.basicDetails
                let partnerQualificationDetails = (response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.qualifications)
                let partnerAddressDetails =  response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.address
                let datofbirth = partnerBasicDetails?.dob?.split("-").reverse().join("-")
                let metadata = partnerAllDetails && partnerAllDetails.metaData && JSON.parse(partnerAllDetails.metaData)
                setAgentPersonalDetails((prev)=>({
                    ...prev,
                    name:partnerBasicDetails?.firstName,
                    gender:partnerBasicDetails?.gender,
                    dateOfBirth:datofbirth,
                    education: (partnerQualificationDetails ? partnerQualificationDetails[0].qualificationName : ""),
                    pincode:partnerBasicDetails?.location,
                    lname:partnerBasicDetails?.lastName,
                    addressLine1:partnerAddressDetails?.addressLine1,
                    addressLine2:partnerAddressDetails?.addressLine2,
                    fathername:(metadata ? metadata.fathername : ""),
                    sbTag:(metadata ? metadata.SBTag : ""),
                    state:partnerAddressDetails?.state,
                    city:partnerAddressDetails?.city
                }))
                setPageLoading(false)
              }else{
               setPageLoading(false)
              }
          })
          .catch(function (error) {
            console.error(error);
            setPageLoading(false)
          });
      }
      
    }
    
     
    const updateAgentData = (payload) => {
          //  here we are calling the api updateAgent
           updatePartnerData(payload).then((response)=>{
              if(response.data.data.updatePartner.code === 0 &&  response.data.data.updatePartner.message === 'success'){
                setLoader((prev)=>!prev)
                setStep(2)
                
             }else if(response.data.data.updatePartner.code === 1){
                console.log("invalid uuid")
                setLoader(false)
             }
            }).catch(function (error) {
              console.error(error);
              setLoader(false)
            });
    }
    

    const handleSubmit= async ()=>{ 
      setLoader(true)
      if(!validation()){
          //  here i need to get the cityDetails by pinCode | call graphql
         getCodes(agentPersonalDetails.pincode)
         .then(function (response) {
                  if(response.data.data.getCodes.payload === null || response.data.data.getCodes.payload === "" || response.data.data.getCodes.payload === undefined){
                    setError("pincodeError","Please Enter Correct pin Code")
                    setLoader(false)
                    return
                  }
                  let aDetails = response.data.data.getCodes.payload[0]

                    setAddressDetails(() => aDetails)

                    // filling payload 
                    const  payloadData = {
                      uuid: agentUuid,
                      mobile: agentMobileNumber,
                      basicDetails: {
                        firstName: agentPersonalDetails.name && agentPersonalDetails.name.trim(),
                        lastName: agentPersonalDetails.lname,
                      //   "09-01-1985"
                        // dob: agentPersonalDetails.dateOfBirth.split("-").reverse().join("-"),
                        dob:formatDate(agentPersonalDetails.dateOfBirth),
                        gender: agentPersonalDetails.gender,
                        location: agentPersonalDetails.pincode && agentPersonalDetails.pincode.trim() ,
                        profileImage: "",
                      },
                      address: {
                        addressType: "",
                        addressLine1: agentPersonalDetails.addressLine1,
                        addressLine2: agentPersonalDetails.addressLine2,
                        state:(aDetails && aDetails.state) ,
                        area: "",
                        city: (aDetails && aDetails.city),
                        cityCode: (aDetails && aDetails.cityCode),
                        stateCode:(aDetails && aDetails.stateCode),
                        pincode: (aDetails && agentPersonalDetails.pincode),
                      },
                      qualifications: [
                        { qualificationName: agentPersonalDetails.education, proofDocumentId: "" }
                      ],
                      metaData: "source"
                    }

                    const source = {
                      createdSource:"WEB",
                      updatedBy:"Partner",
                      updatedByUserId:agentUuid,
                      fathername:agentPersonalDetails.fathername,
                      SBTag: agentPersonalDetails.sbTag
                    }
                  
                  let payload = JSON.stringify(payloadData).replace(/\"([^(\")"]+)\":/g, "$1:").replace("source", JSON.stringify(source).replace(new RegExp('"', "g"), '\\"'))
                  // hitting the update partner api | call graphql 
                  updateAgentData(payload)

          })
          .catch(function (error) {
            console.error("error codes",error);
          });
  
          

        
           
             
           

            // after succeful message we need to set the tab
              
          
           
         }else {
          setLoader(false)
         }
      }

    const  formatDate = (inputDateString) => {
        var dateObject = new Date(inputDateString);
    
        if (isNaN(dateObject.getTime())) {
            // If the input is not a valid date string, return an error message or handle accordingly
            return "Invalid date";
        }
    
        var day = dateObject.getDate();
        var month = dateObject.getMonth() + 1;
        var year = dateObject.getFullYear();
    
        // Format the components to "dd/mm/yyyy" format
        var formattedDate = (day < 10 ? '0' : '') + day + '-' + (month < 10 ? '0' : '') + month + '-' + year;
    
        return formattedDate;
    }

    return ( 
      
        <div className="onboard-pr-details">
          {
            pageLoading ? <PageLoader /> :null
          }
            <PageHeader step={1} whichStep={whichStep} setStep={setStep}/>
            {/* {
              console.log("educatioin list",educationList)
            } */}
            <div className="onboard-card">
                  <div className="psdt-sec">
                <h1 className="personal-details-title">Personal Details</h1>
                <div className="psdt-item">
                  <Input label={"First Name"}  value={agentPersonalDetails.name} onChange={(e) => handleChange("name", e.target.value)}/>
                  {
                        agentPersonalDetailsError.nameError !== "" ? <ErrorMsg error={agentPersonalDetailsError.nameError} />
                        : null
                    }

                </div>
                <div className="psdt-item">
                  <Input label={"Last Name"} value={agentPersonalDetails.lname} onChange={(e) => handleChange("lname", e.target.value)}/> 
                  {
                       agentPersonalDetailsError.lnameError !== "" ? <ErrorMsg error={agentPersonalDetailsError.lnameError} />
                       : null
                  }
                </div>
                <div className="psdt-item">
                  <Input label={"Father Name"} value={agentPersonalDetails.fathername} onChange={(e) => handleChange("fathername", e.target.value)}/>
                  {
                       agentPersonalDetailsError.fathernameError !== "" ? <ErrorMsg error={agentPersonalDetailsError.fathernameError} />
                       : null
                  }
                </div>
                <div className="psdt-item">
                  <RadioButton onChange={handleChange} value={agentPersonalDetails.gender}/>
                  {
                        agentPersonalDetailsError.genderError !== "" ?  <ErrorMsg error={agentPersonalDetailsError.genderError} /> : null
                    }
                    
                </div>
                <div className="psdt-item">
                  <CustomDatePicker label={"Date of Birth"} onChange={(e)=>handleChange("dateOfBirth",e.$d)}
                                value={agentPersonalDetails.dateOfBirth}/>
                  {
                        agentPersonalDetailsError.dateOfBirthError !== "" ?  <ErrorMsg error={agentPersonalDetailsError.dateOfBirthError} /> : null
                    }
                </div>
                {/* <div className="psdt-item">
                  <SelectComp placeholder={"Education"} options={educationList} onChange={(e, obj) => handleChange("education", obj.props.value)} value={agentPersonalDetails.education}/> 
                </div> */}

                <div className="psdt-item">
                  
                  <BasicSelect placeholder={"Education"} options={educationList} onChange={(e, obj) => handleChange("education", obj)} value={agentPersonalDetails.education}/>

                  {
                        agentPersonalDetailsError.educationError !== "" ? <ErrorMsg error={agentPersonalDetailsError.educationError} /> : null
                    }
                </div>

                <div className="psdt-item">
                  <Input label={"SB Tag"} value={agentPersonalDetails.sbTag} onChange={(e) => handleChange("sbTag", e.target.value)}/>
                  {
                       agentPersonalDetailsError.sbTagError !== "" ? <ErrorMsg error={agentPersonalDetailsError.sbTagError} />
                       : null
                  }
                </div>


                {/* <div>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Age</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={agentPersonalDetails.education}
                    label="Age"
                    // onChange={handleChange}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
                </div> */}
                <h2 className="address-title">Address Details</h2>
                <div className="psdt-item">
                  <Input label={"Address Line 1"} onChange={(e, obj) => handleChange("addressLine1", e.target.value)} value={agentPersonalDetails.addressLine1}/> 
                  {
                    agentPersonalDetailsError.addressLine1Error !== "" ? <ErrorMsg error={agentPersonalDetailsError.addressLine1Error} /> : null
                  }
                </div>
                <div className="psdt-item">
                  <Input label={"Address Line 2"} onChange={(e, obj) => handleChange("addressLine2", e.target.value)} value={agentPersonalDetails.addressLine2}/>
                  {
                    agentPersonalDetailsError.addressLine2Error !== "" ? <ErrorMsg error={agentPersonalDetailsError.addressLine2Error} /> : null
                  }
                </div>
                <div className="psdt-item">
                  <Input label={"Pincode"} value={agentPersonalDetails.pincode} onChange={(e) => handleChange("pincode", e.target.value)}/>
                  {
                            agentPersonalDetailsError.pincodeError !== "" ? <ErrorMsg error={agentPersonalDetailsError.pincodeError} />  : null
                        }
                    
                </div>
                <div className="psdt-item">
                  <Input label={"City"} value={agentPersonalDetails.city} disabled={true}/>
                </div>
                <div className="psdt-item">
                  <Input label={"State"} value={agentPersonalDetails.state} disabled={true}/>
                </div>
                
                <div className="psdt-btn-item">{
                    loader ? <ButtonLoader /> :
                    <Button
                        label={"Continue"}
                        status={true}
                        isIcon={true}
                        onClick={()=>handleSubmit()}
                        img={AllIcons.tickIcon}
                        clickFunction={()=>console.log("agent details",agentPersonalDetails)}
                        buttonType={"proceed-btn"}
                        // onClick={()=>console.log("agent personal details details",agentPersonalDetails)}
                    />
                }
                </div>
                  </div>
            </div>
        </div>
    )

}

export default PersonalDetailsComponent
import React from 'react';
import Button from "../../../components/Button/Button";
import "./Chapters.scss";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const Chapters = ({lessons}) => {

  // console.log("lessions",lessons);

const navigate = useHistory()

const redirectToChapter = (chapterUrl)=>{
    navigate.push(`/training/${chapterUrl}`)
}

    return (
        <div className="chapters-sec">
          <div className="chapters-rgt-item">
            <div className="chaptsec">
              <h1 className="chapt-title">Chapters</h1>
              {
              lessons &&  lessons.map((each)=>(
                  <div className="chp-sec-item" key={each?.lessionName}>
                  <div className="chpsecitm-rw-itm">
                    <div className="chpsecitm-item">{each?.lessionName}</div>
                    <div className="chpsecitm-subinfo">{each?.subtitle}</div>
                  </div>
                  <div className='goto-chp-btn' onClick={() => redirectToChapter(each?.url.slice(1,-1))}>
                      <img src='https://cdn.upsure.io/POS/common/goto_chp_btn.svg' alt='button' width="100%" height="100%" />
                  </div>
                </div>
                ))
              }
            </div>
          </div>
        </div>
    );
}

export default Chapters;

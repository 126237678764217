import React from "react";
import { useState,useEffect } from 'react'
import { getPartnerByQuery } from '../../components/onboarding/serviceApi'
import PageLoader from '../../components/UI/PageLoader/PageLoader'
import VerificationPending from './VerificationPending'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./Verification.scss";



const Verification = () => {

  const [counter,setCounter]=useState(0)
    const [agentUuid,setAgentUuid]=useState(null)  // need to get it from session storage
    const [whichStep,setWhichStep]=useState(0)
    const [agentMobileNumber,setAgentMobileNumber]=useState(null)  
    const [loader,setLoader] = useState(true)
    const [Status,setStatus] = useState("")
    
    const navigate = useHistory()
    
const getCookie = (name) => {
  var nameEQ = name + "=";
  var cookiesArray = document.cookie.split(';');
  for (var i = 0; i < cookiesArray.length; i++) {
      var cookie = cookiesArray[i];
      while (cookie.charAt(0) == ' ') {
          cookie = cookie.substring(1, cookie.length);
      }
      if (cookie.indexOf(nameEQ) == 0) {
          return cookie.substring(nameEQ.length, cookie.length);
      }
  }
  return null;
}

  const getPartnerDetails = (uuid) => {
      if (uuid) {
          getPartnerByQuery(uuid).then((response) => {
               setLoader(false)
              if (response?.data?.data?.getPartnerByQuery?.hits?.total > 0 && response?.data?.data?.getPartnerByQuery?.hits?.hits?.length && response.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source) {

                  let partnerBasicDetails = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.basicDetails
                  let partnerQualificationDetails = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.qualifications && response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.qualifications[0]
                  // step 2 details 
                  let identityProofs = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.identityProofs
                  // step 3 details 
                  let partnerAccountDetails = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.accountDetails && response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.accountDetails[0]
                  // verification status 
                  let verificationStaus = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.verificationStatus
                  
                  let learningStatus =  response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.learningStatus?.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0]?.status
                  
                  let examDetailsStatus = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.examDetails
                  let examStatus = response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.examDetails?.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0]?.status
                  let licenceStatus =  response?.data?.data?.getPartnerByQuery?.hits?.hits[0]?._source?.licenseStatus?.filter(each => each.licenseType == "POSP_GENERAL")[0]?.licenseStatus
                  // ?.filter((each, idx) => each.licenseType == "POSP_GENERAL")[0]?.status

                  // console.log("status",learningStatus,examDetailsStatus,examStatus,licenceStatus);
                   if(verificationStaus === "LOGGED_IN"){
                      navigate.push("/training")
                   }
                   if (verificationStaus === "VERIFICATION_PENDING") {
                      setLoader(false)
                      setStatus("Verification")
                      return
                    } else if(verificationStaus === "VERIFICATION_DONE" && (response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus === null || response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus === "" || response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus === undefined) || (verificationStaus === "VERIFICATION_DONE" && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "TRAINING_IN_PROGRESS") ){
                      setLoader(false)
                      setStatus("Training")
                      return
                    }
                    else if(verificationStaus === "VERIFICATION_DONE" && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response.data.data.getPartnerByQuery.hits.hits[0]._source.learningStatus.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "TRAINING_COMPLETED" && (verificationStaus === "VERIFICATION_DONE" && (response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails == null || response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "FAIL") ) ) {
                        setLoader(false)
                        setStatus("Examination")
                        return
                    }
                  //   else if(verificationStaus === "VERIFICATION_DONE" && response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201") && response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201").length && response.data.data.getPartnerByQuery.hits.hits[0]._source.examDetails.filter((each, idx) => each.courseName == "POS_GENERAL" && each.courseId == "201")[0].status == "FAIL") {
                  //     setLoader(false)
                  //     setStatus("Examination")
                  //     return
                  // } 
                  else if(verificationStaus === "VERIFICATION_DONE" &&  learningStatus == "TRAINING_COMPLETED" && examStatus == "PASS" && verificationStaus === "VERIFICATION_DONE" && licenceStatus == "PENDING") {
                    setLoader(false)
                    // setStatus(true)
                    setStatus("License")
                    setLoader(false)
                    // console.log("need to rediect the user to course page")
                    return
                }
                else if(verificationStaus === "VERIFICATION_DONE"  &&  learningStatus == "TRAINING_COMPLETED" && examStatus == "PASS" && verificationStaus === "VERIFICATION_DONE" && licenceStatus.toLowerCase() == "issued") {
                      setLoader(false)
                      // setStatus(true)
                      setStatus("licience_issued")
                      // console.log("need to rediect the user to course page")
                      return
                  }
              }else {
                navigate.push("/onboarding")
              }
          }).catch((error)=>{
            console.log("error",error)
            setLoader(false)
          })
      }
      else if( counter >= 1 && (uuid === "" || uuid === null || uuid === undefined) || (uuid && uuid.length <=0 )){
          window.location.href = "https://angelone.upsure.io/health-insurance"
      } 
}  

useEffect(()=>{
    setAgentUuid(()=> getCookie("AgentUUID"))
    setAgentMobileNumber(()=> getCookie("AgentMobileNumber"))
    setCounter((prev)=>prev+1)
    getPartnerDetails(agentUuid)
   
 },[agentUuid,agentMobileNumber])

  return (
    <div>
      {
        loader ? <PageLoader /> : null
      }   

      <VerificationPending Status={Status} />
      
    </div>
  )
}
   


export default Verification;

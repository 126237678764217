import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "./RadioButton.scss";

const RadioButton = ({value,onChange}) => {
  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="male" checked={value === "M"} onChange={()=>onChange("gender","M")} control={<Radio />} label="Male" />
        <FormControlLabel value="female" checked={value === "F"} onChange={()=>onChange("gender", "F")}control={<Radio />} label="Female" />
      </RadioGroup>
    </FormControl>
  );
};
export default RadioButton;

import React, { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { RadioGroup, Radio } from "@mui/material";
import Button from "../../components/Button/Button";
import AllIcons from "../../Utility/IconLinks";
import axios from "axios";

import "./QuestionComponent.scss";

const QuestionComponent = ({
  examQuestions,
  onSubmit,
  uuid,
  examId,
  handleFinishExam,
  handleSubmitCallback,
  selectedOptions,
  handleRadioChange,
}) => {
  const [buttonloader, setButtonloader] = useState(false);

  return (
    <div>
      <div className="question-main">
        {examQuestions.map((questionData, index) => (
          <div key={questionData.questionId}>
            <div className="quest-item">{`${index + 1}. ${
              questionData.question
            }`}</div>
            <div className="options-item">
              <FormControl component="fieldset">
                <RadioGroup
                  value={selectedOptions[questionData.questionId] || ""}
                  onChange={(e) =>
                    handleRadioChange(questionData.questionId, e.target.value)
                  }
                >
                  <FormControlLabel
                    value={questionData.opt1}
                    control={<Radio />}
                    label={questionData.opt1}
                  />
                  <FormControlLabel
                    value={questionData.opt2}
                    control={<Radio />}
                    label={questionData.opt2}
                  />
                  <FormControlLabel
                    value={questionData.opt3}
                    control={<Radio />}
                    label={questionData.opt3}
                  />
                  <FormControlLabel
                    value={questionData.opt4}
                    control={<Radio />}
                    label={questionData.opt4}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        ))}
      </div>
      {!buttonloader ? (
        <div className="ques-btn-sec">
          <Button
            label={"Finish Exam"}
            status={true}
            isIcon={true}
            img={AllIcons.tickIcon}
            buttonType="proceed-btn"
            onClick={handleSubmitCallback}
          />
        </div>
      ) : (
        <div> please wait fetching results</div>
      )}
    </div>
  );
};

export default QuestionComponent;

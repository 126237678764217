import React from "react";
import Header from "../../components/Header/Header";
import LeftPanel from "../../components/LeftPanel/LeftPanel";
import DashboardHeading from "../../components/PageHeading/DashboardHeading";
import DashboardCard from "./DashboardCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import "./Dashboard.scss";
import { useEffect } from "react";

const Dashboard = () => {
  
  const navigate = useHistory() 

  useEffect(()=>{
    navigate.push('/onboarding')
  },[])
  return (
    <div className="dashboard-main">
      <Header />
      <LeftPanel />
      <div className="innerbody">
            <div className="body-con">
                <DashboardHeading />
                <div className="bodylayout">
                    <fieldset className="custom-dashboard-fieldset-height">
                        <legend>My Virtual Office</legend>
                        <DashboardCard />
                    </fieldset>
                </div>
            </div>
      </div>
    </div>
  );
};

export default Dashboard;

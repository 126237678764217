import React from 'react';
import Select from '../UI/Select/Select';
import CustomDatePicker from '../UI/Date/DatePicker';
// import Button from '../UI/Button/Button';
import Button from '../../components/Button/Button';
import "./Filter.scss";

const ChannelOptions = [
    { text: "Channel", value: "channel" },
    { text: "Channel-1", value: "channel1" },
    { text: "Channel-2", value: "channel2" },
  ];
const ProductCategoryOptions = [
    { text: "Product Category", value: "channel" },
    { text: "Product Category-1", value: "channel1" },
    { text: "Product Category-2", value: "channel2" },
  ];

const Filter = () => {
    return (
        <div className='filter'>
            <h2>Filter By</h2>
            <div className='filter-fields'>
                <div className='fields-items'>
                    <Select placeholder={"Channel"} options={ChannelOptions}  />
                </div>
                <div className='fields-items'>
                    <Select placeholder={"Product Category"} options={ProductCategoryOptions}  />
                </div>
                <div className='fields-items'>
                    <CustomDatePicker label={"From Date"} />
                </div>
                <div className='fields-items'>
                    <CustomDatePicker label={"To Date"} />
                </div>
                <div className='filter-btn-sec'>
                    <div className='cls-btn-item'>
                        <Button
                         label={'Clear Filter'}
                         status={false}
                         isIcon={false}
                         // clickFunction={backButtonHandler}
                         buttonType='clear-filter-btn' />
                    </div>
                    <div className='cls-btn-item'>
                        <Button
                         label={'Go to Training'}
                         status={true}
                         isIcon={false}
                         // clickFunction={backButtonHandler}
                         buttonType='search-filter-btn' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Filter;
